import { parse, isValid, format } from 'date-fns';

export function parseDate(dateStr: string | null | undefined): Date | null {
  if (!dateStr) return null;

  try {
    // If it's already a Date object (type assertion since we know it's safe here)
    if (Object.prototype.toString.call(dateStr) === '[object Date]') {
      return isValid(dateStr as unknown as Date) ? (dateStr as unknown as Date) : null;
    }

    // If it's an ISO string
    if (dateStr.match(/^\d{4}-\d{2}-\d{2}T/)) {
      const date = new Date(dateStr);
      return isValid(date) ? date : null;
    }

    // Try parsing common date formats
    const formats = [
      'yyyy-MM-dd',
      'MM/dd/yyyy',
      'M/d/yyyy',
      'MM-dd-yyyy',
      'MMMM d, yyyy',
      'MMM d, yyyy'
    ];

    for (const dateFormat of formats) {
      const parsedDate = parse(dateStr, dateFormat, new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }

    // Last resort: try native Date parsing
    const date = new Date(dateStr);
    return isValid(date) ? date : null;
  } catch {
    return null;
  }
}

export function formatDateForDisplay(date: Date | string | null): string {
  if (!date) return '';
  
  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return isValid(dateObj) ? format(dateObj, 'yyyy-MM-dd') : '';
  } catch {
    return '';
  }
}

export function formatDateForDB(date: Date | string | null): string | null {
  if (!date) return null;

  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!isValid(dateObj)) return null;
    return dateObj.toISOString();
  } catch {
    return null;
  }
}
