import { supabase } from '../../../supabase';
import { ProcessingError } from '../errors';
import type { LeaseAbstraction } from '../../../../types/lease';

/**
 * Retrieves the previous version of a lease abstraction
 * @param abstractionId The ID of the abstraction to get the previous version for
 * @returns The previous version or null if not found
 */
export async function getPreviousVersion(abstractionId: string): Promise<Partial<LeaseAbstraction> | null> {
  try {
    if (!abstractionId) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'Abstraction ID is required'
      }]);
    }

    // Use OR logic to get both the original and any amendments
    const { data: versions, error } = await supabase
      .from('lease_abstractions')
      .select('*')
      .or(`id.eq.${abstractionId},parent_id.eq.${abstractionId}`)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Database error:', error);
      throw ProcessingError.database('Failed to fetch versions', error);
    }
    
    if (!versions?.length) {
      console.error('No versions found for abstraction ID:', abstractionId);
      return null;
    }

    // Find the original lease first
    const originalLease = versions.find(v => v.type === 'original');
    if (!originalLease) {
      console.error('Original lease not found for abstraction ID:', abstractionId);
      return null;
    }

    // Sort versions by effective date, falling back to commencement date, then created_at
    const sortedVersions = versions.sort((a, b) => {
      const dateA = a.effective_date ? new Date(a.effective_date).getTime() :
                   a.commencement_date ? new Date(a.commencement_date).getTime() :
                   new Date(a.created_at).getTime();
      const dateB = b.effective_date ? new Date(b.effective_date).getTime() :
                   b.commencement_date ? new Date(b.commencement_date).getTime() :
                   new Date(b.created_at).getTime();
      return dateA - dateB; // Ascending order
    });

    // Find the current version's index
    const currentIndex = sortedVersions.findIndex(v => v.id === abstractionId);
    if (currentIndex === -1) {
      console.error('Current version not found in sorted versions');
      return null;
    }

    // If this is the first version (original lease), return it
    if (currentIndex === 0) {
      return sortedVersions[0];
    }

    // Return the version immediately before the current one
    return sortedVersions[currentIndex - 1];
  } catch (error) {
    if (error instanceof ProcessingError) {
      throw error;
    }
    console.error('Error getting previous version:', error);
    throw ProcessingError.database(
      `Failed to get previous version for abstraction ID ${abstractionId}`,
      error as Error
    );
  }
}
