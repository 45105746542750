import { ProcessingError } from '../../../lib/llm/processors/errors';

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/tiff'
];

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

export interface FileValidationError {
  file: File;
  reason: string;
}

/**
 * Validates file types and sizes against allowed configurations
 * @param files Array of files to validate
 * @returns Array of invalid files with reasons
 */
export function validateFileTypes(files: File[]): FileValidationError[] {
  const invalidFiles: FileValidationError[] = [];

  for (const file of files) {
    // Check file type
    if (!file.type) {
      invalidFiles.push({
        file,
        reason: 'File type could not be determined'
      });
      continue;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      invalidFiles.push({
        file,
        reason: `Unsupported file type: ${file.type}`
      });
      continue;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      invalidFiles.push({
        file,
        reason: `File size exceeds maximum allowed size of ${MAX_FILE_SIZE / (1024 * 1024)}MB`
      });
      continue;
    }
  }

  return invalidFiles;
}

/**
 * Detects if a file is likely an amendment based on its name
 * @param fileName Name of the file to check
 * @returns boolean indicating if the file is likely an amendment
 */
export function isAmendmentFile(fileName: string): boolean {
  const lowerName = fileName.toLowerCase();
  return (
    lowerName.includes('amendment') ||
    lowerName.includes('amend') ||
    lowerName.includes('modification') ||
    lowerName.includes('addendum')
  );
}

/**
 * Extracts a date from a filename if present
 * @param fileName Name of the file to check
 * @returns Date object if a valid date was found, null otherwise
 */
export function extractDateFromFileName(fileName: string): Date | null {
  // Common date formats in filenames
  const datePatterns = [
    /(\d{4}[-_]\d{2}[-_]\d{2})/, // YYYY-MM-DD or YYYY_MM_DD
    /(\d{2}[-_]\d{2}[-_]\d{4})/, // DD-MM-YYYY or DD_MM_YYYY
    /(\d{2}[-_]\d{2}[-_]\d{2})/, // DD-MM-YY or DD_MM_YY
  ];

  for (const pattern of datePatterns) {
    const match = fileName.match(pattern);
    if (match) {
      const dateStr = match[1].replace(/_/g, '-');
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
  }

  return null;
}

/**
 * Validates a single file
 * @param file File to validate
 * @throws ProcessingError if the file is invalid
 */
export function validateFile(file: File): void {
  // Check if file exists
  if (!file) {
    throw ProcessingError.validation([{
      field: 'file',
      message: 'No file provided'
    }]);
  }

  // Check file type
  if (!file.type) {
    throw ProcessingError.validation([{
      field: 'file',
      message: `File type could not be determined for ${file.name}`
    }]);
  }

  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    throw ProcessingError.validation([{
      field: 'file',
      message: `Unsupported file type: ${file.type}`
    }]);
  }

  // Check file size
  if (file.size > MAX_FILE_SIZE) {
    throw ProcessingError.validation([{
      field: 'file',
      message: `File size exceeds maximum allowed size of ${MAX_FILE_SIZE / (1024 * 1024)}MB`
    }]);
  }
}

/**
 * Gets the MIME type for a file extension
 * @param extension File extension to check (with or without leading dot)
 * @returns MIME type if known, null otherwise
 */
export function getMimeType(extension: string): string | null {
  const ext = extension.startsWith('.') ? extension.slice(1) : extension;
  const mimeTypes: Record<string, string> = {
    'pdf': 'application/pdf',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'tiff': 'image/tiff',
    'tif': 'image/tiff'
  };

  return mimeTypes[ext.toLowerCase()] || null;
}
