import { supabase } from '../../supabase';
import { convertToCamelCase } from '../utils/case-conversion';
import { ProcessingError } from '../../llm/processors/errors';
import type { LeaseAbstractionWithVersions } from '../../llm/types';

interface LoadVersionsResult {
  data: LeaseAbstractionWithVersions | null;
  error: string | null;
}

export async function getAbstractionWithVersions(id: string): Promise<LoadVersionsResult> {
  try {
    if (!id) {
      throw ProcessingError.validation([{
        field: 'id',
        message: 'Abstraction ID is required'
      }]);
    }

    // Get current session to ensure we're authenticated
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'You must be logged in to access versions'
      }]);
    }

    // First, get the target version to determine if it's an original or amendment
    const { data: targetVersions, error: targetError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .filter('id', 'eq', id);

    if (targetError) {
      console.error('Target version fetch error:', targetError);
      // Check if it's a permissions error
      if (targetError.code === 'PGRST301') {
        throw ProcessingError.database('You do not have permission to access this abstraction');
      }
      throw ProcessingError.database('Failed to fetch target version', targetError);
    }

    if (!targetVersions || targetVersions.length === 0) {
      throw ProcessingError.database(`Version not found: ${id}`);
    }

    const targetVersion = targetVersions[0];

    // Determine the original lease ID
    const originalId = targetVersion.type === 'original' ? id : targetVersion.parent_id;
    if (!originalId) {
      throw ProcessingError.database(`Parent ID not found for amendment: ${id}`);
    }

    // Get the original lease
    const { data: originalLease, error: originalError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .filter('id', 'eq', originalId)
      .single();

    if (originalError) {
      console.error('Original lease fetch error:', originalError);
      throw ProcessingError.database('Failed to fetch original lease', originalError);
    }

    if (!originalLease) {
      throw ProcessingError.database(`Original lease not found for ID: ${originalId}`);
    }

    // Get all amendments
    const { data: amendments, error: amendmentsError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .filter('parent_id', 'eq', originalId)
      .order('version', { ascending: true });

    if (amendmentsError) {
      console.error('Amendments fetch error:', amendmentsError);
      throw ProcessingError.database('Failed to fetch amendments', amendmentsError);
    }

    // Convert all versions to camelCase and sort by effective date and version
    const sortedVersions = [originalLease, ...(amendments || [])]
      .filter(Boolean)
      .map(convertToCamelCase)
      .sort((a, b) => {
        // Sort by effective date first
        const dateA = a.effectiveDate ? new Date(a.effectiveDate).getTime() : 0;
        const dateB = b.effectiveDate ? new Date(b.effectiveDate).getTime() : 0;
        if (dateA !== dateB) return dateA - dateB;
        
        // If dates are equal, sort by version number
        return a.version - b.version;
      });

    // Find the current version (the one marked as current or the latest)
    
    // Find the target version index
    const targetIndex = sortedVersions.findIndex(v => v.id === id);
    if (targetIndex === -1) {
      throw ProcessingError.database(`Selected version not found: ${id}`);
    }

    // Build cumulative state up to the target version
    const cumulativeState = sortedVersions
      .slice(0, targetIndex + 1)
      .reduce((acc, version) => {
        // Helper function to merge objects
        const mergeObjects = (base: any, update: any) => {
          if (!update) return base;
          return Object.entries(update).reduce((merged, [key, value]) => {
            // Only override if the value is not null/undefined and different from base
            if (value !== null && value !== undefined && value !== '') {
              merged[key] = value;
            }
            return merged;
          }, { ...base });
        };

        // Helper function to determine field status for date fields
        const determineDateFieldStatus = (value: any) => {
          // If the value exists in the database, mark it as not_missing regardless of what's in fieldStatus
          if (value !== null && value !== undefined) {
            return 'not_missing';
          }
          // If no value in database, mark as missing
          return 'missing';
        };

        // Get the current fieldStatus
        const currentFieldStatus = { ...acc.fieldStatus };

        // Update fieldStatus for date fields based on database values
        const dateFields = ['commencementDate', 'originalCommencementDate', 'expirationDate', 'rentStartDate'];
        dateFields.forEach(field => {
          currentFieldStatus[field] = determineDateFieldStatus(version[field] || acc[field]);
        });

        // For non-date fields, merge fieldStatus normally
        const nonDateFieldStatus = Object.entries(version.fieldStatus || {})
          .reduce((merged, [key, value]) => {
            if (!dateFields.includes(key)) {
              merged[key] = value;
            }
            return merged;
          }, {} as Record<string, any>);

        return {
          ...acc,
          // Version metadata (always from current version)
          id: version.id,
          type: version.type,
          version: version.version,
          status: version.status,
          isCurrentVersion: version.isCurrentVersion,
          parentId: version.parentId,
          userId: version.userId,
          documentPath: version.documentPath,
          documentChecksum: version.documentChecksum,
          documentContent: version.documentContent,
          changes: version.changes,
          createdAt: version.createdAt,
          updatedAt: version.updatedAt,
          effectiveDate: version.effectiveDate,

          // Handle dates based on version type
          commencementDate: version.type === 'amendment' 
            ? version.effectiveDate || acc.commencementDate 
            : version.commencementDate || version.rentStartDate || acc.commencementDate,
          
          originalCommencementDate: acc.originalCommencementDate, // Always inherit from original lease
          
          expirationDate: version.expirationDate || acc.expirationDate, // Use latest expiration date
          
          rentStartDate: version.type === 'amendment'
            ? version.effectiveDate || acc.rentStartDate
            : version.rentStartDate || version.commencementDate || acc.rentStartDate,

          // Always use the most recent available values for financial data
          baseRent: version.baseRent ?? acc.baseRent, // Use nullish coalescing to handle 0 values
          annualRent: version.annualRent ?? acc.annualRent,
          securityDeposit: version.securityDeposit ?? acc.securityDeposit,

          // Always use the most recent available values for area data
          rentableArea: version.rentableArea ?? acc.rentableArea,
          proRata: version.proRata ?? acc.proRata,
          buildingArea: version.buildingArea ?? acc.buildingArea,

          // Always use the most recent available values for clauses
          clauses: mergeObjects(acc.clauses, version.clauses),
          additionalClauses: mergeObjects(acc.additionalClauses, version.additionalClauses),

          // Merge other fields only if new values exist
          premises: version.premises || acc.premises,
          county: version.county || acc.county,
          tenantLegalName: version.tenantLegalName || acc.tenantLegalName,

          // Merge nested objects
          landlord: mergeObjects(acc.landlord, version.landlord),
          tenant: mergeObjects(acc.tenant, version.tenant),

          // Merge nested objects
          confidence: mergeObjects(acc.confidence, version.confidence),
          missingFields: version.missingFields || acc.missingFields,
          fieldStatus: { ...currentFieldStatus, ...nonDateFieldStatus }
        };
      }, convertToCamelCase(originalLease));

    const result: LeaseAbstractionWithVersions = {
      ...cumulativeState,
      versions: sortedVersions
    };

    return {
      data: result,
      error: null
    };
  } catch (error) {
    console.error('Load abstraction with versions error:', error);

    if (error instanceof ProcessingError) {
      if (error.details) {
        // Format validation errors
        const validationErrors = error.details
          .map(err => `${err.field}: ${err.message}`)
          .join('\n');
        return { 
          data: null, 
          error: `Validation failed:\n${validationErrors}`
        };
      }
      return {
        data: null,
        error: error.message
      };
    }

    // Handle unexpected errors
    return { 
      data: null, 
      error: ProcessingError.database(
        'Failed to load abstraction versions',
        error as Error
      ).message
    };
  }
}
