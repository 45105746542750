/**
 * Console logger utility to forward browser console messages to the terminal
 */

// Store original console methods
const originalConsole = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
  debug: console.debug
};

// Helper function to format messages
function formatMessage(type: string, ...args: any[]): string {
  const timestamp = new Date().toISOString();
  const messages = args.map(arg => {
    if (arg instanceof Error) {
      return `${arg.message}\nStack trace:\n${arg.stack}`;
    }
    if (typeof arg === 'object') {
      try {
        return JSON.stringify(arg, null, 2);
      } catch {
        return String(arg);
      }
    }
    return String(arg);
  });
  return `[${timestamp}] [${type.toUpperCase()}] ${messages.join(' ')}`;
}

// Override console methods to forward to terminal
export function setupConsoleLogger(): void {
  // Override console.log
  console.log = (...args: any[]) => {
    const message = formatMessage('log', ...args);
    originalConsole.log(message);
    // Forward to terminal via process logger
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  };

  // Override console.info
  console.info = (...args: any[]) => {
    const message = formatMessage('info', ...args);
    originalConsole.info(message);
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  };

  // Override console.warn
  console.warn = (...args: any[]) => {
    const message = formatMessage('warn', ...args);
    originalConsole.warn(message);
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  };

  // Override console.error
  console.error = (...args: any[]) => {
    const message = formatMessage('error', ...args);
    originalConsole.error(message);
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  };

  // Override console.debug
  console.debug = (...args: any[]) => {
    const message = formatMessage('debug', ...args);
    originalConsole.debug(message);
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  };

  // Handle unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    const message = formatMessage(
      'error',
      'Unhandled Promise Rejection:',
      event.reason
    );
    originalConsole.error(message);
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  });

  // Handle uncaught errors
  window.addEventListener('error', (event) => {
    const message = formatMessage(
      'error',
      'Uncaught Error:',
      event.error || event.message
    );
    originalConsole.error(message);
    if (window.electronAPI?.log) {
      window.electronAPI.log(message);
    }
  });
}

// Add type definitions for window.electronAPI
declare global {
  interface Window {
    electronAPI?: {
      log: (message: string) => void;
    };
  }
}
