import type { LeaseAbstraction } from '@/types/lease';

interface FieldComparison {
  original: any;
  reviewed: any;
  isModified: boolean;
  isMissing: boolean;
  isIncorrect: boolean;
  accuracy: number;
  confidence: number;
}

export function calculateAbstractionMetrics(
  original: Partial<LeaseAbstraction>,
  reviewed: Partial<LeaseAbstraction>,
  missingFields: string[] = []
) {
  const isEmpty = (value: any): boolean => {
    if (value === null || value === undefined) return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    return false;
  };

  const areValuesEqual = (a: any, b: any): boolean => {
    if (a === b) return true;
    if (isEmpty(a) && isEmpty(b)) return true;
    if (typeof a === 'object' && typeof b === 'object') {
      return JSON.stringify(a) === JSON.stringify(b);
    }
    return false;
  };

  const calculateFieldAccuracy = (original: any, reviewed: any): number => {
    if (isEmpty(original) && isEmpty(reviewed)) return 1;
    if (isEmpty(original) || isEmpty(reviewed)) return 0;
    if (typeof original !== typeof reviewed) return 0;
    
    if (typeof original === 'object') {
      const fields = new Set([...Object.keys(original), ...Object.keys(reviewed)]);
      let matchingFields = 0;
      fields.forEach(field => {
        if (areValuesEqual(original[field], reviewed[field])) {
          matchingFields++;
        }
      });
      return fields.size > 0 ? matchingFields / fields.size : 1;
    }
    
    return areValuesEqual(original, reviewed) ? 1 : 0;
  };

  const allFields = new Set([
    ...Object.keys(original || {}),
    ...Object.keys(reviewed || {})
  ]);

  const fieldComparisons = new Map<string, FieldComparison>();
  const modifiedFields: string[] = [];
  const incorrectFields: string[] = [];

  let totalAccuracy = 0;
  let evaluatedFields = 0;

  // Compare each field
  allFields.forEach(field => {
    // Skip internal fields
    if (['id', 'createdAt', 'updatedAt', 'version', 'status'].includes(field)) {
      return;
    }

    const originalValue = original[field as keyof LeaseAbstraction];
    const reviewedValue = reviewed[field as keyof LeaseAbstraction];
    const accuracy = calculateFieldAccuracy(originalValue, reviewedValue);

    const comparison: FieldComparison = {
      original: originalValue,
      reviewed: reviewedValue,
      isModified: !areValuesEqual(originalValue, reviewedValue),
      isMissing: missingFields.includes(field),
      isIncorrect: !areValuesEqual(originalValue, reviewedValue),
      accuracy,
      confidence: accuracy // Base confidence on accuracy for now
    };

    if (comparison.isModified) {
      modifiedFields.push(field);
    }

    if (comparison.isIncorrect) {
      incorrectFields.push(field);
    }

    fieldComparisons.set(field, comparison);
    totalAccuracy += accuracy;
    evaluatedFields++;
  });

  const fieldAccuracy = evaluatedFields > 0 ? totalAccuracy / evaluatedFields : 1;

  return {
    fieldAccuracy,
    modifiedFields,
    missingFields,
    incorrectFields,
    comparisons: Object.fromEntries(fieldComparisons),
    stats: {
      totalFields: evaluatedFields,
      correctFields: evaluatedFields - incorrectFields.length,
      modifiedCount: modifiedFields.length,
      missingCount: missingFields.length,
      incorrectCount: incorrectFields.length
    }
  };
}