import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TrainingDashboard } from '@/components/training/TrainingDashboard';
import { TrainingDataList } from '@/components/training/TrainingDataList';
import { ModelVersions } from '@/components/training/ModelVersions';
import { TrainingReview } from '@/components/training/TrainingReview';

export default function Training() {
  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-3xl font-bold tracking-tight">Model Training</h1>
        <p className="text-muted-foreground">
          Monitor model performance and review abstractions
        </p>
      </div>

      <Tabs defaultValue="dashboard">
        <TabsList>
          <TabsTrigger value="dashboard">Dashboard</TabsTrigger>
          <TabsTrigger value="data">Training Data</TabsTrigger>
          <TabsTrigger value="review">Review</TabsTrigger>
          <TabsTrigger value="models">Model Versions</TabsTrigger>
        </TabsList>
        
        <TabsContent value="dashboard">
          <TrainingDashboard />
        </TabsContent>
        
        <TabsContent value="data">
          <TrainingDataList />
        </TabsContent>

        <TabsContent value="review">
          <TrainingReview />
        </TabsContent>
        
        <TabsContent value="models">
          <ModelVersions />
        </TabsContent>
      </Tabs>
    </div>
  );
}