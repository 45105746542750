import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { useAuth } from '../../contexts/AuthContext';

export default function OAuthCallback() {
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  async function ensureUserProfile(userId: string, email: string | undefined) {
    try {
      // Check if profile exists
      const { data: existingProfile } = await supabase
        .from('user_profiles')
        .select('id')
        .eq('id', userId)
        .single();

      if (!existingProfile) {
        // Use existing RPC function to create profile
        const { error: rpcError } = await supabase.rpc('create_profile_with_role', {
          p_id: userId,
          p_email: email || '',
          p_role: 'user'
        });

        if (rpcError) {
          console.error('Error creating user profile:', rpcError);
        }
      }
    } catch (err) {
      console.error('Error ensuring user profile:', err);
    }
  }

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout;

    async function handleAuth() {
      if (isProcessing) return;
      setIsProcessing(true);

      try {
        console.log('Starting auth callback handling...');
        
        // Set a timeout to prevent endless loading
        timeoutId = setTimeout(() => {
          if (mounted) {
            console.error('Auth callback timed out');
            setError('Authentication is taking too long. Please try again.');
            setIsProcessing(false);
          }
        }, 10000);

        // Check for error parameters
        const params = new URLSearchParams(window.location.search);
        const errorCode = params.get('error');
        const errorDescription = params.get('error_description');
        
        if (errorCode) {
          console.error('OAuth error:', errorCode, errorDescription);
          throw new Error(errorDescription || 'Authentication failed');
        }

        // Get the code from URL parameters
        const code = params.get('code');
        if (!code) {
          throw new Error('No authorization code found');
        }

        // Let Supabase handle the code exchange
        // It will automatically use the code verifier from storage
        console.log('Exchanging code for session...');
        const { data, error: exchangeError } = await supabase.auth.exchangeCodeForSession(code);
        
        if (exchangeError) {
          console.error('Error exchanging code:', exchangeError);
          throw exchangeError;
        }

        if (!data.session) {
          throw new Error('No session received');
        }

        // Create or update user profile
        await ensureUserProfile(data.session.user.id, data.session.user.email);
        
        // Wait for auth state to update
        console.log('Waiting for auth state to update...');
        await new Promise<void>((resolve) => {
          const unsubscribe = supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_IN' && session) {
              console.log('Auth state updated:', event, session.user.email);
              unsubscribe.data.subscription.unsubscribe();
              resolve();
            }
          });

          // Add a timeout for the auth state change
          setTimeout(() => {
            unsubscribe.data.subscription.unsubscribe();
            resolve();
          }, 2000);
        });

        if (mounted) {
          navigate('/', { replace: true });
        }
      } catch (err) {
        console.error('Auth callback error:', err);
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to complete authentication');
        }
      } finally {
        if (mounted) {
          clearTimeout(timeoutId);
          setIsProcessing(false);
        }
      }
    }

    if (!user) {
      handleAuth();
    } else {
      navigate('/', { replace: true });
    }

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [navigate, isProcessing, user]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="max-w-md w-full space-y-4">
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          <div className="text-center">
            <button 
              onClick={() => navigate('/login')}
              className="text-primary hover:underline"
            >
              Return to login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center space-y-4">
        <Loader2 className="h-8 w-8 animate-spin mx-auto text-primary" />
        <p className="text-muted-foreground">
          Completing authentication...
        </p>
        <p className="text-sm text-muted-foreground">
          This may take a few moments
        </p>
      </div>
    </div>
  );
}
