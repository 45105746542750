import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { getTrainingDocuments, updateTrainingDocument } from '@/lib/training/db';
import { AbstractionReview } from './AbstractionReview';
import type { TrainingDocument } from '@/lib/training/types';
import type { LeaseAbstraction } from '@/types/lease';
import { toast } from 'sonner';
import { FileText, CheckCircle } from 'lucide-react';
import { calculateAbstractionMetrics } from '@/lib/training/metrics';

export function TrainingReview() {
  const [documents, setDocuments] = useState<TrainingDocument[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<TrainingDocument | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchDocuments();
  }, []);

  async function fetchDocuments() {
    try {
      const { data } = await getTrainingDocuments({ status: 'pending' });
      if (data) setDocuments(data);
    } catch (error) {
      console.error('Failed to fetch documents:', error);
      toast.error('Failed to load documents');
    } finally {
      setLoading(false);
    }
  }

  async function handleSaveReview(reviewedAbstraction: Partial<LeaseAbstraction>) {
    if (!selectedDoc) return;

    try {
      setSaving(true);
      
      // Calculate metrics
      const metrics = calculateAbstractionMetrics(
        selectedDoc.original_abstraction,
        reviewedAbstraction
      );

      // Update document with review
      const { error } = await updateTrainingDocument(selectedDoc.id, {
        status: 'reviewed',
        reviewed_abstraction: reviewedAbstraction,
        metrics
      });

      if (error) throw error;

      toast.success('Review saved successfully');
      setDocuments(docs => docs.filter(d => d.id !== selectedDoc.id));
      setSelectedDoc(null);
    } catch (error) {
      toast.error('Failed to save review');
    } finally {
      setSaving(false);
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <Card className="md:col-span-2">
        <CardHeader>
          <CardTitle>Pending Reviews</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[200px]">
            <div className="space-y-4">
              {documents.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-8 text-center text-muted-foreground">
                  <CheckCircle className="h-12 w-12 mb-2" />
                  <p>No documents pending review</p>
                </div>
              ) : (
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                  {documents.map((doc) => (
                    <Card
                      key={doc.id}
                      className={`cursor-pointer transition-colors hover:bg-muted/50 ${
                        selectedDoc?.id === doc.id ? 'bg-muted' : ''
                      }`}
                      onClick={() => setSelectedDoc(doc)}
                    >
                      <CardContent className="p-4">
                        <div className="flex items-center gap-2 mb-2">
                          <FileText className="h-4 w-4 text-muted-foreground" />
                          <span className="font-medium truncate">
                            {doc.document_path.split('/').pop()}
                          </span>
                        </div>
                        <div className="space-y-2">
                          <Badge variant="secondary">
                            {doc.status}
                          </Badge>
                          <div className="text-xs text-muted-foreground">
                            Added {new Date(doc.created_at).toLocaleDateString()}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}
            </div>
          </ScrollArea>
        </CardContent>
      </Card>

      {selectedDoc && (
        <div className="md:col-span-2">
          <AbstractionReview
            originalAbstraction={selectedDoc.original_abstraction}
            onSave={handleSaveReview}
          />
        </div>
      )}
    </div>
  );
}
