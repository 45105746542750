import { Link } from 'react-router-dom';

export function Logo() {
  return (
    <Link to="/" className="flex items-center">
      <svg width="117" height="35" viewBox="0 0 117 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4688 0H26.1098V12.8049H23.4688V0Z" fill="black"/>
<path d="M30.4098 8.73933C30.5378 9.25152 30.8099 9.66235 31.2261 9.9718C31.6529 10.2813 32.1864 10.436 32.8267 10.436C34.0005 10.436 34.9395 10.0252 35.6438 9.20351L37.3724 10.3399C36.8922 11.279 36.252 11.9672 35.4517 12.4047C34.6514 12.8316 33.7764 13.045 32.8267 13.045C31.9303 13.045 31.0874 12.8209 30.2977 12.3727C29.5188 11.9139 28.8892 11.3003 28.409 10.532C27.9395 9.76372 27.7047 8.92607 27.7047 8.01906C27.7047 7.12271 27.9395 6.2904 28.409 5.5221C28.8892 4.75381 29.5241 4.14558 30.3137 3.69741C31.1034 3.23857 31.941 3.00915 32.8267 3.00915C33.755 3.00915 34.582 3.2439 35.3076 3.71341C36.0332 4.17226 36.5988 4.83384 37.0043 5.69817C37.4098 6.5625 37.6072 7.57622 37.5965 8.73933H30.4098ZM32.8267 5.57012C32.2505 5.57012 31.7543 5.71951 31.3381 6.01829C30.9326 6.31707 30.6445 6.69588 30.4738 7.15473H35.0675C34.9075 6.64253 34.614 6.25305 34.1872 5.98628C33.7711 5.70884 33.3175 5.57012 32.8267 5.57012Z" fill="black"/>
<path d="M43.7712 3.00915C44.977 3.00915 45.9587 3.16921 46.7164 3.48933C47.474 3.79878 48.0395 4.31098 48.413 5.02591C48.7865 5.73018 48.9732 6.67454 48.9732 7.85899V12.8049H46.3642L46.7484 9.20351H46.5243C46.3749 10.516 45.9854 11.4817 45.3558 12.1006C44.7369 12.7195 43.8619 13.029 42.7308 13.029C41.5677 13.029 40.6874 12.7942 40.0898 12.3247C39.4923 11.8552 39.1935 11.1189 39.1935 10.1159C39.1935 9.56098 39.3429 9.05412 39.6417 8.59527C39.9404 8.12576 40.3939 7.74695 41.0022 7.45884C41.6104 7.17073 42.3574 7.02668 43.243 7.02668H46.2362C46.0975 6.56784 45.8414 6.23704 45.4679 6.0343C45.0944 5.83155 44.5289 5.73018 43.7712 5.73018C43.3017 5.73018 42.8962 5.81021 42.5548 5.97027C42.2133 6.13034 41.8718 6.40244 41.5304 6.78659L39.4016 5.39405C40.0205 4.56174 40.6874 3.95884 41.4023 3.58537C42.1173 3.20122 42.9069 3.00915 43.7712 3.00915ZM43.243 10.2919C43.9153 10.2919 44.4488 10.2439 44.8436 10.1479C45.2491 10.0518 45.5639 9.8811 45.788 9.63567C46.0121 9.39024 46.1615 9.03811 46.2362 8.57927H43.243C42.3787 8.57927 41.9465 8.85137 41.9465 9.39558C41.9465 9.65168 42.0479 9.86509 42.2507 10.0358C42.4641 10.2066 42.7949 10.2919 43.243 10.2919Z" fill="black"/>
<path d="M53.1818 8.96341C53.4379 9.41159 53.7634 9.76906 54.1582 10.0358C54.553 10.3026 55.0865 10.436 55.7588 10.436C56.2603 10.436 56.6391 10.42 56.8952 10.388C57.1513 10.3559 57.3274 10.3026 57.4234 10.2279C57.5301 10.1425 57.5835 10.0198 57.5835 9.85976C57.5835 9.71037 57.5301 9.59299 57.4234 9.50762C57.3274 9.41159 57.1407 9.33156 56.8632 9.26753C56.5858 9.19284 56.175 9.11814 55.6308 9.04345L55.0225 8.96341C53.6567 8.78201 52.6536 8.42454 52.0134 7.89101C51.3838 7.35747 51.069 6.64787 51.069 5.76219C51.069 5.26067 51.2131 4.80183 51.5012 4.38567C51.8 3.96951 52.2641 3.63872 52.8937 3.39329C53.5233 3.13719 54.3183 3.00915 55.2786 3.00915C56.5378 3.00915 57.5141 3.2439 58.2077 3.71341C58.912 4.17226 59.4936 4.89787 59.9524 5.89024L57.6955 6.80259C57.3647 6.34375 57.0233 6.02363 56.6712 5.84223C56.3297 5.66082 55.8655 5.57012 55.2786 5.57012C54.7237 5.57012 54.3129 5.62348 54.0461 5.73018C53.7901 5.82622 53.662 5.97561 53.662 6.17835C53.662 6.3811 53.7527 6.53049 53.9341 6.62652C54.1155 6.72256 54.4783 6.81326 55.0225 6.89863L55.6308 6.99467C56.8792 7.18674 57.8289 7.43217 58.4798 7.73095C59.1414 8.01906 59.5843 8.34985 59.8083 8.72332C60.0431 9.08613 60.1605 9.5343 60.1605 10.0678C60.1605 10.5694 60.0218 11.0442 59.7443 11.4924C59.4669 11.9299 58.9974 12.2927 58.3358 12.5808C57.6849 12.8582 56.8259 12.997 55.7588 12.997C54.9051 12.997 54.1689 12.9063 53.55 12.7248C52.9417 12.5328 52.3975 12.218 51.9173 11.7805C51.4478 11.343 50.9997 10.7401 50.5728 9.9718L53.1818 8.96341Z" fill="black"/>
<path d="M64.157 8.73933C64.285 9.25152 64.5572 9.66235 64.9733 9.9718C65.4001 10.2813 65.9337 10.436 66.5739 10.436C67.7477 10.436 68.6867 10.0252 69.391 9.20351L71.1197 10.3399C70.6395 11.279 69.9992 11.9672 69.1989 12.4047C68.3986 12.8316 67.5236 13.045 66.5739 13.045C65.6776 13.045 64.8346 12.8209 64.045 12.3727C63.266 11.9139 62.6364 11.3003 62.1562 10.532C61.6867 9.76372 61.452 8.92607 61.452 8.01906C61.452 7.12271 61.6867 6.2904 62.1562 5.5221C62.6364 4.75381 63.2713 4.14558 64.061 3.69741C64.8506 3.23857 65.6883 3.00915 66.5739 3.00915C67.5023 3.00915 68.3293 3.2439 69.0549 3.71341C69.7805 4.17226 70.346 4.83384 70.7515 5.69817C71.157 6.5625 71.3544 7.57622 71.3437 8.73933H64.157ZM66.5739 5.57012C65.9977 5.57012 65.5015 5.71951 65.0854 6.01829C64.6799 6.31707 64.3918 6.69588 64.221 7.15473H68.8148C68.6547 6.64253 68.3613 6.25305 67.9344 5.98628C67.5183 5.70884 67.0648 5.57012 66.5739 5.57012Z" fill="black"/>
<path d="M4.41349 24.436C4.79764 25.1082 5.28582 25.6444 5.87805 26.0446C6.47028 26.4447 7.27058 26.6448 8.27896 26.6448C9.03125 26.6448 9.59947 26.6208 9.98361 26.5728C10.3678 26.5248 10.6319 26.4447 10.7759 26.3327C10.936 26.2046 11.016 26.0206 11.016 25.7805C11.016 25.5564 10.936 25.3803 10.7759 25.2523C10.6319 25.1082 10.3518 24.9882 9.9356 24.8921C9.51944 24.7801 8.9032 24.6681 8.08689 24.556L7.17454 24.436C5.12576 24.1639 3.62119 23.6277 2.66082 22.8274C1.71646 22.0271 1.24428 20.9627 1.24428 19.6341C1.24428 18.8819 1.46037 18.1936 1.89253 17.5694C2.3407 16.9451 3.03697 16.4489 3.98133 16.0808C4.92569 15.6966 6.11814 15.5046 7.55869 15.5046C9.44741 15.5046 10.912 15.8567 11.9524 16.561C13.0088 17.2492 13.8811 18.3377 14.5694 19.8262L11.1841 21.1947C10.6879 20.5065 10.1757 20.0263 9.64749 19.7542C9.13529 19.4821 8.43902 19.346 7.55869 19.346C6.72637 19.346 6.11014 19.4261 5.70999 19.5861C5.32584 19.7302 5.13377 19.9543 5.13377 20.2584C5.13377 20.5625 5.26982 20.7866 5.54192 20.9306C5.81402 21.0747 6.35823 21.2107 7.17454 21.3388L8.08689 21.4829C9.9596 21.771 11.3841 22.1391 12.3605 22.5873C13.3529 23.0194 14.0172 23.5156 14.3533 24.0758C14.7054 24.62 14.8815 25.2923 14.8815 26.0926C14.8815 26.8449 14.6734 27.5572 14.2572 28.2294C13.8411 28.8857 13.1368 29.4299 12.1444 29.862C11.1681 30.2782 9.87957 30.4863 8.27896 30.4863C6.99848 30.4863 5.89406 30.3502 4.9657 30.0781C4.05335 29.79 3.23704 29.3178 2.51677 28.6616C1.8125 28.0053 1.14024 27.101 0.5 25.9486L4.41349 24.436Z" fill="#E82426"/>
<path d="M19.4005 15.1204C18.8082 15.1204 18.304 14.9203 17.8879 14.5202C17.4877 14.12 17.2876 13.6319 17.2876 13.0556C17.2876 12.4794 17.4877 11.9912 17.8879 11.5911C18.304 11.1909 18.8082 10.9909 19.4005 10.9909C19.9607 10.9909 20.4328 11.1909 20.817 11.5911C21.2171 11.9912 21.4172 12.4794 21.4172 13.0556C21.4172 13.6319 21.2171 14.12 20.817 14.5202C20.4328 14.9203 19.9607 15.1204 19.4005 15.1204ZM17.3837 15.5526H21.3452V30.1982H17.3837V15.5526Z" fill="#E82426"/>
<path d="M28.8721 15.5286L28.1518 20.6665H28.8C29.5683 17.0011 31.6251 15.1684 34.9704 15.1684C38.2997 15.1684 39.8763 17.0011 39.7002 20.6665H40.3484C41.1167 17.0011 43.1735 15.1684 46.5188 15.1684C47.7192 15.1684 48.7116 15.4325 49.4959 15.9607C50.2802 16.4729 50.8884 17.4093 51.3206 18.7698C51.7528 20.1143 51.9689 22.003 51.9689 24.436V30.1982H48.0314V24.436C48.0314 22.6433 47.7673 21.3308 47.2391 20.4985C46.7269 19.6662 45.8465 19.25 44.5981 19.25C43.2055 19.25 42.1571 19.7062 41.4529 20.6185C40.7646 21.5309 40.4205 22.8034 40.4205 24.436V30.1982H36.483V24.436C36.483 22.6433 36.2189 21.3308 35.6907 20.4985C35.1785 19.6662 34.2981 19.25 33.0496 19.25C31.6571 19.25 30.6087 19.7062 29.9045 20.6185C29.2162 21.5309 28.8721 22.8034 28.8721 24.436V30.1982H24.7905V15.5286H28.8721Z" fill="#E82426"/>
<path d="M64.9025 15.5046C66.0389 15.5046 67.0713 15.8487 67.9997 16.537C68.944 17.2252 69.6803 18.1776 70.2085 19.3941C70.7527 20.6105 71.0248 21.979 71.0248 23.4996C71.0248 24.7161 70.7527 25.8685 70.2085 26.9569C69.6643 28.0454 68.92 28.9177 67.9756 29.5739C67.0473 30.2302 66.0229 30.5583 64.9025 30.5583C61.6212 30.5583 59.7565 28.7096 59.3083 25.0122H58.9482L59.1883 35H55.1067V15.5526H59.1883L58.9482 20.6425H59.4044C59.7245 18.9779 60.3727 17.7054 61.3491 16.8251C62.3415 15.9447 63.5259 15.5046 64.9025 15.5046ZM63.4139 26.5008C64.1342 26.5008 64.7664 26.3567 65.3106 26.0686C65.8708 25.7805 66.303 25.3883 66.6071 24.8921C66.9112 24.396 67.0633 23.8518 67.0633 23.2595C67.0633 22.2511 66.7272 21.4268 66.0549 20.7866C65.3987 20.1303 64.5183 19.8022 63.4139 19.8022C62.6776 19.8022 62.0134 19.9463 61.4211 20.2344C60.8449 20.5225 60.3888 20.9146 60.0526 21.4108C59.7165 21.907 59.5484 22.4432 59.5484 23.0194C59.5484 23.5636 59.7165 24.1079 60.0526 24.6521C60.3888 25.1963 60.8529 25.6444 61.4452 25.9966C62.0374 26.3327 62.6936 26.5008 63.4139 26.5008Z" fill="#E82426"/>
<path d="M73.4184 10.9909H77.3799V30.1982H73.4184V10.9909Z" fill="#E82426"/>
<path d="M82.612 15.1204C82.0198 15.1204 81.5156 14.9203 81.0995 14.5202C80.6993 14.12 80.4992 13.6319 80.4992 13.0556C80.4992 12.4794 80.6993 11.9912 81.0995 11.5911C81.5156 11.1909 82.0198 10.9909 82.612 10.9909C83.1722 10.9909 83.6444 11.1909 84.0286 11.5911C84.4287 11.9912 84.6288 12.4794 84.6288 13.0556C84.6288 13.6319 84.4287 14.12 84.0286 14.5202C83.6444 14.9203 83.1722 15.1204 82.612 15.1204ZM80.5953 15.5526H84.5568V30.1982H80.5953V15.5526Z" fill="#E82426"/>
<path d="M97.3407 14.3521C96.4604 14.3521 95.7961 14.3841 95.3479 14.4482C94.8998 14.4962 94.5556 14.6082 94.3156 14.7843C94.0755 14.9444 93.9154 15.2005 93.8354 15.5526H98.4451V19.6341H93.6673V30.1982H89.7058V19.6341H87.0408V15.5526H89.7538C89.8338 14.4002 90.0979 13.5038 90.5461 12.8636C91.0103 12.2233 91.8506 11.7511 93.0671 11.447C94.2996 11.1429 96.0922 10.9909 98.4451 10.9909V14.3521H97.3407Z" fill="#E82426"/>
<path d="M116.049 15.5526L113.263 27.8453C112.847 29.67 112.183 31.1025 111.271 32.1429C110.374 33.1833 109.238 33.9196 107.861 34.3518C106.485 34.7839 104.82 35 102.868 35V31.2786C104.388 31.2786 105.46 31.2626 106.085 31.2306C106.709 31.1986 107.213 31.1105 107.597 30.9665C107.981 30.8224 108.278 30.5663 108.486 30.1982H104.476L100.851 15.5526H104.908L107.957 27.8693H109.206V27.8453L111.991 15.5526H116.049Z" fill="#E82426"/>
</svg>

  

    </Link>
  );
}