import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, File } from 'lucide-react';
import { cn } from '../../lib/utils';

interface FileUploadZoneProps {
  onFileSelect: (files: File[]) => void;
  multiple?: boolean;
  accept?: Record<string, string[]>;
  maxSize?: number;
  className?: string;
  isAmendment?: boolean;
}

export function FileUploadZone({
  onFileSelect,
  multiple = true,
  accept = {
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/tiff': ['.tif', '.tiff']
  },
  maxSize = 20971520, // 20MB
  className,
  isAmendment = false,
}: FileUploadZoneProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileSelect(acceptedFiles);
  }, [onFileSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple
  });

  return (
    <div
      {...getRootProps()}
      className={cn(
        'border-2 border-dashed rounded-lg p-8 transition-colors cursor-pointer',
        isDragActive ? 'border-primary bg-primary/5' : 'border-muted-foreground/25',
        className
      )}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        {isDragActive ? (
          <Upload className="h-12 w-12 text-primary animate-bounce" />
        ) : (
          <File className="h-12 w-12 text-muted-foreground" />
        )}
        <div className="space-y-2">
          <p className="text-lg font-medium">
            {isDragActive ? 'Drop files here' : isAmendment ? 
              'Drag an amendment document here' : 
              'Upload Lease Documents'
            }
          </p>
          <p className="text-sm text-muted-foreground">
            {multiple ? 
              'Include one main lease and any related amendments' :
              'or click to select a file'
            }
          </p>
        </div>
        <div className="text-xs text-muted-foreground">
          Supported formats: PDF, DOCX, JPG, PNG, TIFF
        </div>
      </div>
    </div>
  );
}
