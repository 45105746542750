import { ProcessingError } from './errors';

interface RetryOptions {
  model: string;
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void;
  maxRetries?: number;
  retryDelay?: number;
  backoffFactor?: number;
}

/**
 * Helper function to format error details
 */
function formatError(error: unknown): string {
  if (error instanceof Error) {
    let message = error.message;
    if (error.stack) {
      message += `\nStack trace:\n${error.stack}`;
    }
    if ('cause' in error && error.cause) {
      message += `\nCaused by: ${formatError(error.cause)}`;
    }
    return message;
  }
  return String(error);
}

/**
 * Helper function to delay execution
 */
function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Helper function to check if an error is retryable
 */
function isRetryableError(error: unknown): boolean {
  // Check if it's our ProcessingError with isRetryable flag
  if (error instanceof ProcessingError) {
    return error.isRetryable;
  }

  // Check if it's an API error with specific status codes
  if (error instanceof Error && 'status' in error) {
    const status = (error as any).status;
    // 429 = rate limit, 408 = timeout, 500-599 = server errors
    return status === 429 || status === 408 || (status >= 500 && status < 600);
  }

  // Check for timeout errors
  if (error instanceof Error && error.message.includes('timeout')) {
    return true;
  }

  return false;
}

/**
 * Retry a function with exponential backoff
 */
export async function withRetry<T>(
  fn: () => Promise<T>,
  options: RetryOptions
): Promise<T> {
  const {
    model,
    addLog,
    maxRetries = 5,
    retryDelay = 2000,
    backoffFactor = 2
  } = options;

  let attempt = 1;
  let lastError: Error | null = null;

  while (attempt <= maxRetries) {
    try {
      return await fn();
    } catch (error) {
      lastError = error as Error;
      const errorDetails = formatError(error);
      
      // Log the error details
      console.error(`${model} attempt ${attempt}/${maxRetries} failed:`, errorDetails);
      
      // Check if error is retryable
      const shouldRetry = isRetryableError(error);
      if (!shouldRetry) {
        addLog(`${model} error: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
        throw error;
      }

      // Check if it's an API error
      if (error instanceof Error && 'status' in error) {
        const status = (error as any).status;
        if (status === 400) {
          console.error(`${model} API Bad Request:`, errorDetails);
          addLog(`${model} API Bad Request. Retrying with reduced content...`, 'error');
          // Let the caller handle content reduction
          throw error;
        }
        if (status === 429) {
          console.error(`${model} Rate limit exceeded:`, errorDetails);
          addLog(`${model} rate limit exceeded. Implementing exponential backoff...`, 'error');
        }
      }

      // Check if we should retry
      if (attempt === maxRetries) {
        console.error(`${model} failed after ${maxRetries} attempts:`, errorDetails);
        addLog(`${model} failed after ${maxRetries} attempts: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
        throw error;
      }

      // Calculate delay for this attempt
      const currentDelay = retryDelay * Math.pow(backoffFactor, attempt - 1);
      
      // Log retry attempt
      addLog(`${model} attempt ${attempt}/${maxRetries} failed: ${error instanceof Error ? error.message : 'Unknown error'}. Retrying in ${currentDelay/1000}s...`, 'error');
      
      // Wait before retrying
      await delay(currentDelay);
      attempt++;
    }
  }

  // This should never happen due to the throw in the loop
  throw lastError || new Error('Retry failed');
}
