import { useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  useAbstractionState,
  useAbstractionLoad,
  useAbstractionSave,
  useVersionManagement
} from './abstraction';

export function useAbstraction(id: string) {
  const { user } = useAuth();
  
  const {
    abstraction,
    setAbstraction,
    originalAbstraction,
    setOriginalAbstraction,
    versions,
    setVersions,
    currentVersionId,
    setCurrentVersionId,
    loading,
    setLoading,
    saving,
    setSaving,
  } = useAbstractionState();

  const { loadAbstraction } = useAbstractionLoad({
    id,
    setAbstraction,
    setOriginalAbstraction,
    setVersions,
    setCurrentVersionId,
    setLoading
  });

  const { save, updateStatus } = useAbstractionSave({
    setAbstraction,
    setVersions,
    setSaving,
    userId: user?.id || ''
  });

  const { switchVersion } = useVersionManagement({
    setAbstraction,
    setCurrentVersionId,
    setSaving
  });

  // Load abstraction data when component mounts or ID changes
  useEffect(() => {
    if (id) {
      setLoading(true); // Ensure loading is true before starting
      loadAbstraction();
    }
  }, [id, loadAbstraction, setLoading]);

  // Find current version from versions array
  const findCurrentVersion = useCallback(() => {
    if (!currentVersionId || !versions.length) return null;
    return versions.find(v => v.id === currentVersionId) || null;
  }, [currentVersionId, versions]);

  // Refresh versions
  const refreshVersions = useCallback(() => {
    if (id) {
      setLoading(true);
      loadAbstraction();
    }
  }, [id, setLoading, loadAbstraction]);

  return {
    abstraction,
    setAbstraction,
    versions,
    currentVersionId,
    currentVersion: findCurrentVersion(),
    loading,
    saving,
    save,
    switchVersion,
    updateStatus: abstraction ? 
      (status: 'draft' | 'completed') => updateStatus(abstraction, originalAbstraction, status) : 
      undefined,
    refreshVersions
  };
}
