import { ValidationError } from '../types';

export class ProcessingError extends Error {
  constructor(
    message: string,
    public readonly details?: ValidationError[],
    public readonly originalError?: Error,
    public readonly isRetryable: boolean = false
  ) {
    super(message);
    this.name = 'ProcessingError';

    // Preserve stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ProcessingError);
    }

    // If we have an original error, append its stack to ours
    if (originalError?.stack) {
      this.stack = `${this.stack}\nCaused by: ${originalError.stack}`;
    }
  }

  /**
   * Creates a ProcessingError specifically for validation failures
   */
  static validation(errors: ValidationError[]): ProcessingError {
    const messages = errors.map(err => `${err.field}: ${err.message}`);
    return new ProcessingError(
      `Validation failed:\n${messages.join('\n')}`,
      errors,
      undefined,
      false
    );
  }

  /**
   * Creates a ProcessingError for retryable operations
   */
  static retryable(message: string, originalError?: Error): ProcessingError {
    return new ProcessingError(
      message,
      undefined,
      originalError,
      true
    );
  }

  /**
   * Creates a ProcessingError for LLM-specific processing errors
   */
  static llm(message: string, originalError?: Error): ProcessingError {
    return new ProcessingError(
      `LLM processing failed: ${message}`,
      undefined,
      originalError,
      false
    );
  }

  /**
   * Creates a ProcessingError for database operations
   */
  static database(message: string, originalError?: Error): ProcessingError {
    return new ProcessingError(
      `Database operation failed: ${message}`,
      undefined,
      originalError,
      false
    );
  }

  /**
   * Creates a ProcessingError for document processing errors
   */
  static document(message: string, originalError?: Error): ProcessingError {
    return new ProcessingError(
      `Document processing failed: ${message}`,
      undefined,
      originalError,
      false
    );
  }
}

// For backward compatibility, alias ProcessingError as LLMProcessingError
export const LLMProcessingError = ProcessingError;
