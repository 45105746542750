import { convertToCamelCase } from '../utils/case-conversion';
import type { LeaseAbstractionTable } from '../../../types/database';
import type { LeaseAbstraction } from '../../../types/lease';

export function mapToLeaseAbstraction(data: LeaseAbstractionTable): LeaseAbstraction {
  const camelCased = convertToCamelCase(data);
  
  return {
    id: camelCased.id,
    userId: camelCased.userId,
    parentId: camelCased.parentId,
    version: camelCased.version || 1,
    type: camelCased.type || 'original',
    status: camelCased.status || 'draft',
    isCurrentVersion: camelCased.isCurrentVersion || false,
    effectiveDate: camelCased.effectiveDate ? new Date(camelCased.effectiveDate) : null,
    documentPath: camelCased.documentPath || '',
    documentChecksum: camelCased.documentChecksum || '',

    // Core fields
    premises: camelCased.premises || '',
    county: camelCased.county || '',
    tenantLegalName: camelCased.tenantLegalName || '',

    // Dates
    commencementDate: camelCased.commencementDate ? new Date(camelCased.commencementDate) : null,
    originalCommencementDate: camelCased.originalCommencementDate ? new Date(camelCased.originalCommencementDate) : null,
    expirationDate: camelCased.expirationDate ? new Date(camelCased.expirationDate) : null,
    rentStartDate: camelCased.rentStartDate ? new Date(camelCased.rentStartDate) : null,

    // Numbers
    rentableArea: camelCased.rentableArea || null,
    proRata: camelCased.proRata || null,
    buildingArea: camelCased.buildingArea || null,
    baseRent: camelCased.baseRent || null,
    annualRent: camelCased.annualRent || null,
    securityDeposit: camelCased.securityDeposit || null,

    // Contact Information
    landlord: {
      name: camelCased.landlord?.name || '',
      address: camelCased.landlord?.address || '',
      phone: camelCased.landlord?.phone || '',
      email: camelCased.landlord?.email || ''
    },
    tenant: {
      name: camelCased.tenant?.name || '',
      address: camelCased.tenant?.address || '',
      phone: camelCased.tenant?.phone || '',
      email: camelCased.tenant?.email || ''
    },

    // Clauses
    clauses: {
      alterations: camelCased.clauses?.alterations || '',
      assignmentSublease: camelCased.clauses?.assignmentSublease || '',
      auditRights: camelCased.clauses?.auditRights || '',
      baseRentDetails: camelCased.clauses?.baseRentDetails || '',
      camOpex: camelCased.clauses?.camOpex || '',
      default: camelCased.clauses?.default || ''
    },

    // Additional Clauses
    additionalClauses: {
      estoppelSnda: camelCased.additionalClauses?.estoppelSnda || '',
      financialReporting: camelCased.additionalClauses?.financialReporting || '',
      forceMajeure: camelCased.additionalClauses?.forceMajeure || '',
      goDark: camelCased.additionalClauses?.goDark || '',
      holdover: camelCased.additionalClauses?.holdover || '',
      insurance: camelCased.additionalClauses?.insurance || '',
      lateFee: camelCased.additionalClauses?.lateFee || '',
      legalNotices: camelCased.additionalClauses?.legalNotices || '',
      parking: camelCased.additionalClauses?.parking || '',
      realEstateTaxes: camelCased.additionalClauses?.realEstateTaxes || '',
      relocationOption: camelCased.additionalClauses?.relocationOption || '',
      renewalOption: camelCased.additionalClauses?.renewalOption || '',
      repairsMaintenance: camelCased.additionalClauses?.repairsMaintenance || '',
      rightOfFirstOffer: camelCased.additionalClauses?.rightOfFirstOffer || '',
      rightOfFirstRefusal: camelCased.additionalClauses?.rightOfFirstRefusal || '',
      securityDepositConditions: camelCased.additionalClauses?.securityDepositConditions || '',
      signage: camelCased.additionalClauses?.signage || '',
      surrender: camelCased.additionalClauses?.surrender || '',
      terminationOption: camelCased.additionalClauses?.terminationOption || '',
      tiAllowance: camelCased.additionalClauses?.tiAllowance || '',
      use: camelCased.additionalClauses?.use || '',
      utilities: camelCased.additionalClauses?.utilities || ''
    },

    // Field Status
    fieldStatus: camelCased.fieldStatus || {},

    // Metadata
    missingFields: camelCased.missingFields || [],
    confidence: camelCased.confidence || {},
    changes: camelCased.changes,
    createdAt: camelCased.createdAt ? new Date(camelCased.createdAt) : new Date(),
    updatedAt: camelCased.updatedAt ? new Date(camelCased.updatedAt) : new Date()
  };
}
