import { supabase } from '../supabase';
import type { TrainingMetrics } from './types';

export async function getTrainingMetrics(): Promise<TrainingMetrics> {
  try {
    const { data, error } = await supabase
      .rpc('get_training_metrics');

    if (error) {
      console.error('Training metrics error:', error);
      throw error;
    }

    return data || {
      totalDocuments: 0,
      approvedDocuments: 0,
      averageQualityScore: 0,
      confidenceMetrics: {
        average: 0,
        byField: {}
      },
      processingMetrics: {
        averageTime: 0,
        errorRate: 0
      },
      missingFieldsCount: 0,
      incorrectFieldsCount: 0
    };
  } catch (error) {
    console.error('Get training metrics error:', error);
    return {
      totalDocuments: 0,
      approvedDocuments: 0,
      averageQualityScore: 0,
      confidenceMetrics: {
        average: 0,
        byField: {}
      },
      processingMetrics: {
        averageTime: 0,
        errorRate: 0
      },
      missingFieldsCount: 0,
      incorrectFieldsCount: 0
    };
  }
}

export async function getTrainingDocuments(options: { status?: string } = {}) {
  try {
    let query = supabase
      .from('training_documents')
      .select('*')
      .order('created_at', { ascending: false });

    if (options.status) {
      query = query.eq('status', options.status);
    }

    const { data, error } = await query;

    if (error) throw error;

    return { data, error: null };
  } catch (error) {
    console.error('Get training documents error:', error);
    return { 
      data: null, 
      error: error instanceof Error ? error.message : 'Failed to get training documents'
    };
  }
}

export async function createTrainingDocument(data: {
  document_path: string;
  user_id: string;
  original_abstraction?: any;
  metadata?: Record<string, any>;
}) {
  try {
    const { error } = await supabase
      .from('training_documents')
      .insert({
        document_path: data.document_path,
        user_id: data.user_id,
        original_abstraction: data.original_abstraction || null,
        metadata: data.metadata || {},
        status: 'pending',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });

    if (error) throw error;

    return { error: null };
  } catch (error) {
    console.error('Create training document error:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to create training document'
    };
  }
}

export async function updateTrainingDocument(
  id: string,
  data: {
    status?: string;
    reviewed_abstraction?: any;
    metrics?: Record<string, any>;
    metadata?: Record<string, any>;
    quality_score?: number;
    notes?: string;
  }
) {
  try {
    const { error } = await supabase
      .from('training_documents')
      .update({
        ...data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;

    return { error: null };
  } catch (error) {
    console.error('Update training document error:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to update training document'
    };
  }
}
