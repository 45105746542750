import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Progress } from '../ui/progress';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Button } from '../ui/button';
import { getTrainingMetrics } from '../../lib/training/db';
import { exportEnhancedTrainingData, downloadEnhancedTrainingData } from '../../lib/training/enhanced-export';
import { useAuth } from '../../contexts/AuthContext';
import type { TrainingMetrics } from '../../lib/training/types';
import { BarChart, FileCheck, FileX, Clock, Lock, Download } from 'lucide-react';

export function TrainingDashboard() {
  const [metrics, setMetrics] = useState<TrainingMetrics | null>(null);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const { isAdmin } = useAuth();

  useEffect(() => {
    fetchMetrics();
  }, []);

  async function fetchMetrics() {
    try {
      const data = await getTrainingMetrics();
      setMetrics(data);
    } catch (error) {
      console.error('Failed to fetch metrics:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleExportTrainingData() {
    try {
      setExporting(true);
      
      // Fetch all approved abstractions
      const response = await fetch('/api/abstractions/approved');
      const abstractions = await response.json();
      
      // Generate enhanced training data
      const { training, validation, metadata } = exportEnhancedTrainingData(abstractions);
      
      // Download the files
      downloadEnhancedTrainingData(training, validation, metadata);
    } catch (error) {
      console.error('Failed to export training data:', error);
    } finally {
      setExporting(false);
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold tracking-tight">Training Dashboard</h2>
        {isAdmin && (
          <Button
            onClick={handleExportTrainingData}
            disabled={exporting}
            className="flex items-center gap-2"
          >
            <Download className="h-4 w-4" />
            {exporting ? 'Exporting...' : 'Export Training Data'}
          </Button>
        )}
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Reviewed Abstractions</CardTitle>
            <FileCheck className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{metrics?.totalDocuments || 0}</div>
            <p className="text-xs text-muted-foreground">
              {metrics?.approvedDocuments || 0} approved
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Model Accuracy</CardTitle>
            <BarChart className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {((metrics?.confidenceMetrics.average || 0) * 100).toFixed(1)}%
            </div>
            <Progress 
              value={(metrics?.confidenceMetrics.average || 0) * 100} 
              className="mt-2"
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Daily Error Rate</CardTitle>
            <FileX className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {((metrics?.processingMetrics.errorRate || 0) * 100).toFixed(1)}%
            </div>
            <p className="text-xs text-muted-foreground">
              30-day average
            </p>
            <Progress 
              value={100 - ((metrics?.processingMetrics.errorRate || 0) * 100)}
              className="mt-2"
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Processing Time</CardTitle>
            <Clock className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {(metrics?.processingMetrics.averageTime || 0).toFixed(1)}s
            </div>
            <p className="text-xs text-muted-foreground">
              Average processing time
            </p>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Field Analysis</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="confidence">
            <TabsList>
              <TabsTrigger value="confidence">Field Confidence</TabsTrigger>
              <TabsTrigger value="errors">Error Analysis</TabsTrigger>
              <TabsTrigger value="quality">Quality Metrics</TabsTrigger>
            </TabsList>
            <TabsContent value="confidence" className="space-y-4">
              <div className="space-y-2">
                {metrics?.confidenceMetrics.byField && 
                  Object.entries(metrics.confidenceMetrics.byField)
                    .sort(([, a], [, b]) => b - a)
                    .map(([field, score]) => (
                      <div key={field} className="space-y-1">
                        <div className="flex items-center justify-between text-sm">
                          <span>{field}</span>
                          <span className="font-medium">
                            {(score * 100).toFixed(1)}%
                          </span>
                        </div>
                        <Progress value={score * 100} />
                      </div>
                    ))
                }
              </div>
            </TabsContent>
            <TabsContent value="errors" className="space-y-4">
              <div className="grid gap-4 grid-cols-2">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm">Missing Fields</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {metrics?.missingFieldsCount || 0}
                    </div>
                    <p className="text-xs text-muted-foreground mt-1">
                      Fields not found in documents (informational only)
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm">Inaccurate Fields</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {metrics?.incorrectFieldsCount || 0}
                    </div>
                    <p className="text-xs text-muted-foreground mt-1">
                      Fields marked as not accurate
                    </p>
                  </CardContent>
                </Card>
              </div>
            </TabsContent>
            <TabsContent value="quality">
              {isAdmin ? (
                <div className="space-y-4">
                  <div className="grid gap-4 grid-cols-2">
                    <Card>
                      <CardHeader>
                        <CardTitle className="text-sm">Overall Quality Score</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="text-2xl font-bold">
                          {((metrics?.averageQualityScore || 0) * 100).toFixed(1)}%
                        </div>
                        <p className="text-xs text-muted-foreground mt-1">
                          Based on not accurate fields only
                        </p>
                        <Progress 
                          value={(metrics?.averageQualityScore || 0) * 100}
                          className="mt-2"
                        />
                      </CardContent>
                    </Card>
                    <Card>
                      <CardHeader>
                        <CardTitle className="text-sm">Training Data Count</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="text-2xl font-bold">
                          {metrics?.approvedDocuments || 0}
                        </div>
                        <p className="text-xs text-muted-foreground mt-1">
                          Documents ready for training
                        </p>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-8 text-center text-muted-foreground">
                  <Lock className="h-8 w-8 mb-2" />
                  <p>Detailed quality metrics are only available to administrators</p>
                </div>
              )}
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}
