import { jsPDF } from 'jspdf';
import type { LeaseAbstraction } from '../../../src/types/lease';
import bwipjs from 'bwip-js';

// Define color tuples
type RGBColor = [number, number, number];

const colors = {
  title: [0.2, 0.2, 0.2] as RGBColor,
  heading: [0.3, 0.3, 0.3] as RGBColor,
  subheading: [0.4, 0.4, 0.4] as RGBColor,
  body: [0.2, 0.2, 0.2] as RGBColor,
  label: [0.4, 0.4, 0.4] as RGBColor,
};

// Simple toTitleCase implementation
function toTitleCase(str: string): string {
  return str.replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

export function generatePDF(abstraction: LeaseAbstraction, fileName: string) {
  try {
    // Initialize PDF in landscape mode
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4'
    });

    // Page dimensions
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 40;
    const columnWidth = (pageWidth - (margin * 3)) / 2;
    const footerHeight = 60;

    // Helper functions
    const formatDate = (date: Date | null) => date?.toLocaleDateString() ?? 'N/A';
    const formatCurrency = (amount: number | null) => amount ? `$${amount.toLocaleString()}` : 'N/A';
    const formatArea = (area: number | null) => area ? `${area.toLocaleString()} sq ft` : 'N/A';

    // Text styles
    const styles = {
      title: { size: 24, style: 'normal', color: colors.title },
      heading: { size: 14, style: 'bold', color: colors.heading },
      subheading: { size: 11, style: 'bold', color: colors.subheading },
      body: { size: 9, style: 'normal', color: colors.body },
      label: { size: 9, style: 'bold', color: colors.label }
    };

    // Function to add wrapped text
    const addWrappedText = (text: string, x: number, y: number, width: number) => {
      const lines = doc.splitTextToSize(text, width);
      doc.text(lines, x, y);
      return y + (lines.length * (doc.getFontSize() * 1.2));
    };

    // Function to add a section
    const addSection = (title: string, content: Record<string, any>, x: number, y: number) => {
      let currentX = x;
      let currentY = y;

      // Section title
      doc.setFontSize(styles.heading.size);
      doc.setFont('helvetica', styles.heading.style);
      doc.setTextColor(...styles.heading.color);
      currentY = addWrappedText(title, currentX, currentY, columnWidth);
      currentY += 10;

      // Section content
      doc.setFontSize(styles.body.size);
      doc.setFont('helvetica', styles.body.style);

      Object.entries(content).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          // Subheading
          doc.setFontSize(styles.subheading.size);
          doc.setFont('helvetica', styles.subheading.style);
          doc.setTextColor(...styles.subheading.color);
          currentY = addWrappedText(toTitleCase(key), currentX, currentY, columnWidth);
          currentY += 5;

          // Object properties
          doc.setFontSize(styles.body.size);
          doc.setFont('helvetica', styles.body.style);
          Object.entries(value).forEach(([subKey, subValue]) => {
            if (subValue) {
              // Check if we need to move to next column or page
              if (currentY > pageHeight - footerHeight - 40) {
                if (currentX === margin) {
                  // Move to right column
                  currentX = margin * 2 + columnWidth;
                  currentY = margin + 40;
                } else {
                  // Move to new page
                  doc.addPage();
                  currentX = margin;
                  currentY = margin + 40;
                }
              }

              doc.setTextColor(...styles.label.color);
              doc.setFont('helvetica', styles.label.style);
              const label = `${toTitleCase(subKey)}: `;
              const labelWidth = doc.getTextWidth(label);
              doc.text(label, currentX, currentY);

              doc.setTextColor(...styles.body.color);
              doc.setFont('helvetica', styles.body.style);
              currentY = addWrappedText(
                String(subValue),
                currentX + labelWidth,
                currentY,
                columnWidth - labelWidth
              );
              currentY += 5;
            }
          });
        } else if (value) {
          // Check if we need to move to next column or page
          if (currentY > pageHeight - footerHeight - 40) {
            if (currentX === margin) {
              // Move to right column
              currentX = margin * 2 + columnWidth;
              currentY = margin + 40;
            } else {
              // Move to new page
              doc.addPage();
              currentX = margin;
              currentY = margin + 40;
            }
          }

          doc.setTextColor(...styles.label.color);
          doc.setFont('helvetica', styles.label.style);
          const label = `${toTitleCase(key)}: `;
          const labelWidth = doc.getTextWidth(label);
          doc.text(label, currentX, currentY);

          doc.setTextColor(...styles.body.color);
          doc.setFont('helvetica', styles.body.style);
          currentY = addWrappedText(
            String(value),
            currentX + labelWidth,
            currentY,
            columnWidth - labelWidth
          );
          currentY += 5;
        }
      });

      return { x: currentX, y: currentY + 15 };
    };

    let currentPosition = { x: margin, y: margin + 40 };

    // Add title
    doc.setFontSize(styles.title.size);
    doc.setFont('helvetica', styles.title.style);
    doc.setTextColor(...styles.title.color);
    doc.text('Lease Abstract', margin, margin);
    doc.setLineWidth(0.5);
    doc.setDrawColor(0.8, 0.8, 0.8);
    doc.line(margin, margin + 10, pageWidth - margin, margin + 10);

    // Add sections in order
    const sections = [
      {
        title: 'Property Information',
        content: {
          premises: abstraction.premises,
          county: abstraction.county,
          tenantLegalName: abstraction.tenantLegalName,
        }
      },
      {
        title: 'Key Information',
        content: {
          commencementDate: formatDate(abstraction.commencementDate),
          originalCommencementDate: formatDate(abstraction.originalCommencementDate),
          expirationDate: formatDate(abstraction.expirationDate),
          rentStartDate: formatDate(abstraction.rentStartDate),
          rentableArea: formatArea(abstraction.rentableArea),
          proRata: abstraction.proRata ? `${abstraction.proRata}%` : 'N/A',
          buildingArea: formatArea(abstraction.buildingArea),
        }
      },
      {
        title: 'Financial Information',
        content: {
          baseRent: formatCurrency(abstraction.baseRent),
          annualRent: formatCurrency(abstraction.annualRent),
          securityDeposit: formatCurrency(abstraction.securityDeposit),
        }
      },
      {
        title: 'Contact Information',
        content: {
          landlord: abstraction.landlord,
          tenant: abstraction.tenant,
        }
      },
      {
        title: 'Lease Clauses',
        content: abstraction.clauses
      },
      {
        title: 'Additional Clauses',
        content: abstraction.additionalClauses
      }
    ];

    sections.forEach(section => {
      const newPosition = addSection(
        section.title,
        section.content,
        currentPosition.x,
        currentPosition.y
      );
      currentPosition = newPosition;

      // Move to next column or page if needed
      if (currentPosition.y > pageHeight - footerHeight - 40) {
        if (currentPosition.x === margin) {
          currentPosition = { x: margin * 2 + columnWidth, y: margin + 40 };
        } else {
          doc.addPage();
          currentPosition = { x: margin, y: margin + 40 };
        }
      }
    });

    // Add footer with timestamp and barcode
    const pageCount = doc.internal.pages.length - 1;
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      // Add timestamp
      const timestamp = new Date().toLocaleString();
      doc.setFontSize(8);
      doc.setTextColor(0.5, 0.5, 0.5);
      doc.text(`Generated: ${timestamp}`, margin, pageHeight - 20);

      // Add page number
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - margin - 60, pageHeight - 20);

      // Generate barcode for the first page only
      if (i === 1) {
        try {
          const barcodeData = `LA${abstraction.id}`;
          const canvas = document.createElement('canvas');
          bwipjs.toCanvas(canvas, {
            bcid: 'code128',
            text: barcodeData,
            scale: 2,
            height: 10,
            includetext: true,
            textxalign: 'center',
          });

          // Add barcode image centered at the bottom
          const imgData = canvas.toDataURL('image/png');
          const barcodeWidth = 100;
          const barcodeX = (pageWidth - barcodeWidth) / 2;
          doc.addImage(imgData, 'PNG', barcodeX, pageHeight - 40, barcodeWidth, 20);
        } catch (error) {
          console.warn('Barcode generation failed:', error);
        }
      }
    }

    // Save the PDF
    doc.save(fileName);
  } catch (error) {
    console.error('PDF generation error:', error);
    throw new Error('Failed to generate PDF');
  }
}
