import { isAmendmentFile, extractDateFromFileName } from './file-detection';
import { ProcessingError } from '../../../lib/llm/processors/errors';

interface FileGroup {
  mainLease: File;
  amendments: File[];
}

interface MatchResult {
  groupIndex: number;
  score: number;
}

/**
 * Groups related files together (main lease with its amendments)
 * @param files Array of files to group
 * @returns Array of file groups, each containing a main lease and its amendments
 * @throws ProcessingError if files cannot be properly grouped
 */
export function groupRelatedFiles(files: File[]): File[][] {
  try {
    if (!files.length) {
      throw ProcessingError.validation([{
        field: 'files',
        message: 'No files provided for grouping'
      }]);
    }

    // First, separate amendments from main leases
    const amendments: File[] = [];
    const mainLeases: File[] = [];

    files.forEach(file => {
      if (isAmendmentFile(file.name)) {
        amendments.push(file);
      } else {
        mainLeases.push(file);
      }
    });

    if (mainLeases.length === 0) {
      throw ProcessingError.validation([{
        field: 'files',
        message: 'No main lease documents found'
      }]);
    }

    // If we only have one main lease, group all amendments with it
    if (mainLeases.length === 1) {
      return [[mainLeases[0], ...amendments]];
    }

    // Multiple main leases - try to match amendments based on naming patterns
    const groups: FileGroup[] = mainLeases.map(lease => ({
      mainLease: lease,
      amendments: []
    }));

    // Try to match amendments to main leases
    amendments.forEach(amendment => {
      // Initialize bestMatch with first group
      const initialMatch: MatchResult = {
        groupIndex: 0,
        score: calculateMatchScore(groups[0].mainLease, amendment)
      };

      // Find the best matching group
      const bestMatch = groups.reduce((best: MatchResult, group, index) => {
        const score = calculateMatchScore(group.mainLease, amendment);
        if (score > best.score) {
          return { groupIndex: index, score };
        }
        return best;
      }, initialMatch);

      // Add amendment to the best matching group
      groups[bestMatch.groupIndex].amendments.push(amendment);
    });

    // Convert groups to arrays
    return groups.map(group => [group.mainLease, ...group.amendments]);
  } catch (error) {
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.document(
      'Failed to group related files',
      error as Error
    );
  }
}

/**
 * Calculates a match score between a main lease and an amendment
 * Higher score indicates better match
 * @param mainLease Main lease file
 * @param amendment Amendment file
 * @returns Match score (0-100)
 */
function calculateMatchScore(mainLease: File, amendment: File): number {
  let score = 0;
  const mainName = mainLease.name.toLowerCase();
  const amendName = amendment.name.toLowerCase();

  // Check for common substrings (e.g., property name, location)
  const words = mainName.split(/[-_\s]/).filter(w => w.length > 3);
  words.forEach(word => {
    if (amendName.includes(word)) {
      score += 20;
    }
  });

  // Check for dates
  const mainDate = extractDateFromFileName(mainName);
  const amendDate = extractDateFromFileName(amendName);
  
  if (mainDate && amendDate) {
    // Higher score if dates are close together
    const monthsDiff = Math.abs(
      (amendDate.getFullYear() - mainDate.getFullYear()) * 12 +
      (amendDate.getMonth() - mainDate.getMonth())
    );
    if (monthsDiff <= 60) { // Within 5 years
      score += Math.max(0, 40 - monthsDiff);
    }
  }

  return Math.min(100, score);
}
