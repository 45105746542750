import { useParams } from 'react-router-dom';
import { GeneralInformation } from '../components/abstraction/GeneralInformation';
import { KeyInformation } from '../components/abstraction/KeyInformation';
import { FinancialSummary } from '../components/abstraction/FinancialSummary';
import { ContactInformation } from '../components/abstraction/ContactInformation';
import { ClausesSection } from '../components/abstraction/ClausesSection';
import { ExportOptions } from '../components/abstraction/ExportOptions';
import { StatusToggle } from '../components/abstraction/StatusToggle';
import { VersionHistory } from '../components/abstraction/VersionHistory';
import { AmendmentUpload } from '../components/abstraction/AmendmentUpload';
import { Skeleton } from '../components/ui/skeleton';
import { useAbstraction } from '../hooks/useAbstraction';
import type { LeaseAbstraction, FieldStatus } from '../types/lease';

const defaultAbstraction: Omit<LeaseAbstraction, 'id'> = {
  version: 1,
  type: 'original',
  status: 'draft',
  premises: '',
  county: '',
  tenantLegalName: '',
  commencementDate: null,
  originalCommencementDate: null,
  expirationDate: null,
  rentStartDate: null,
  rentableArea: null,
  proRata: null,
  buildingArea: null,
  baseRent: null,
  annualRent: null,
  securityDeposit: null,
  landlord: {
    name: '',
    address: '',
    phone: '',
    email: '',
  },
  tenant: {
    name: '',
    address: '',
    phone: '',
    email: '',
  },
  clauses: {
    alterations: '',
    assignmentSublease: '',
    auditRights: '',
    baseRentDetails: '',
    camOpex: '',
    default: '',
  },
  additionalClauses: {
    estoppelSnda: '',
    financialReporting: '',
    forceMajeure: '',
    goDark: '',
    holdover: '',
    insurance: '',
    lateFee: '',
    legalNotices: '',
    parking: '',
    realEstateTaxes: '',
    relocationOption: '',
    renewalOption: '',
    repairsMaintenance: '',
    rightOfFirstOffer: '',
    rightOfFirstRefusal: '',
    securityDepositConditions: '',
    signage: '',
    surrender: '',
    terminationOption: '',
    tiAllowance: '',
    use: '',
    utilities: '',
  },
  missingFields: [],
  confidence: {},
  fieldStatus: {},
  createdAt: new Date(),
  updatedAt: new Date()
};

export default function LeaseAbstraction() {
  const { id = '' } = useParams();
  const {
    abstraction,
    setAbstraction,
    versions,
    currentVersionId,
    loading,
    switchVersion,
    updateStatus,
    refreshVersions
  } = useAbstraction(id);

  const handleFieldStatusChange = (field: string, status: FieldStatus) => {
    if (!abstraction) return;
    
    setAbstraction(prev => {
      if (!prev) return prev;

      // Create a new fieldStatus object with the updated status
      const newFieldStatus = {
        ...prev.fieldStatus,
        [field]: status
      };

      // For clauses and additional clauses, we need to preserve the existing values
      if (field.startsWith('clauses.') || field.startsWith('additionalClauses.')) {
        const [section, key] = field.split('.');
        const updatedSection = section === 'clauses' ? prev.clauses : prev.additionalClauses;
        return {
          ...prev,
          fieldStatus: newFieldStatus,
          [section]: {
            ...updatedSection,
            [key]: updatedSection[key] || ''
          }
        } as LeaseAbstraction;
      }

      return {
        ...prev,
        fieldStatus: newFieldStatus
      } as LeaseAbstraction;
    });
  };

  if (loading) {
    return (
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <div>
            <Skeleton className="h-8 w-[300px]" />
            <Skeleton className="h-4 w-[200px] mt-2" />
          </div>
          <div className="flex items-center gap-4">
            <Skeleton className="h-10 w-[120px]" />
            <Skeleton className="h-10 w-[120px]" />
            <Skeleton className="h-10 w-[120px]" />
          </div>
        </div>

        <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="space-y-4">
              <Skeleton className="h-8 w-[200px]" />
              <Skeleton className="h-[300px] w-full" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const data = abstraction || { ...defaultAbstraction, id };
  
  // Find the original lease ID (either current ID if it's original, or parent_id if it's an amendment)
  const originalLeaseId = data.type === 'original' ? data.id : data.parentId || data.id;
  
  return (
    <div className="space-y-8 pb-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Lease Abstraction</h1>
          <p className="text-muted-foreground">
            Abstract key information from the lease document
          </p>
        </div>
        <div className="flex items-center gap-4">
          <StatusToggle 
            status={data.status} 
            onChange={updateStatus!}
          />
          <AmendmentUpload 
            abstraction={data}
            onUploadComplete={refreshVersions}
          />
          <ExportOptions abstraction={data} />
          <VersionHistory
            versions={versions}
            currentVersionId={currentVersionId}
            onVersionSelect={(versionId) => switchVersion(originalLeaseId, versionId)}
          />
        </div>
      </div>

      <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
        <GeneralInformation
          {...data}
          onChange={(field, value) =>
            setAbstraction(prev => prev ? ({ ...prev, [field]: value } as LeaseAbstraction) : prev)
          }
          onFieldStatusChange={handleFieldStatusChange}
        />

        <KeyInformation
          {...data}
          type={data.type}
          onChange={(field, value) =>
            setAbstraction(prev => prev ? ({ ...prev, [field]: value } as LeaseAbstraction) : prev)
          }
          onFieldStatusChange={handleFieldStatusChange}
        />

        <FinancialSummary
          {...data}
          onChange={(field, value) =>
            setAbstraction(prev => prev ? ({ ...prev, [field]: value } as LeaseAbstraction) : prev)
          }
          onFieldStatusChange={handleFieldStatusChange}
        />

        <ContactInformation
          {...data}
          onChange={(type, field, value) => {
            setAbstraction(prev => {
              if (!prev) return prev;
              return {
                ...prev,
                [type]: {
                  ...prev[type],
                  [field]: value
                }
              } as LeaseAbstraction;
            });
          }}
          onFieldStatusChange={handleFieldStatusChange}
        />

        <ClausesSection
          title="Abstract Clauses"
          clauses={data.clauses}
          fieldStatus={data.fieldStatus}
          onChange={(field, value) =>
            setAbstraction(prev => prev ? ({
              ...prev,
              clauses: { ...prev.clauses, [field]: value },
            } as LeaseAbstraction) : prev)
          }
          onFieldStatusChange={handleFieldStatusChange}
        />
        
        <ClausesSection
          title="Additional Clauses"
          clauses={data.additionalClauses}
          fieldStatus={data.fieldStatus}
          onChange={(field, value) =>
            setAbstraction(prev => prev ? ({
              ...prev,
              additionalClauses: { ...prev.additionalClauses, [field]: value },
            } as LeaseAbstraction) : prev)
          }
          onFieldStatusChange={handleFieldStatusChange}
        />
      </div>
    </div>
  );
}
