import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileUploadZone } from './FileUploadZone';
import { UploadTerminal } from './UploadTerminal';
import { Switch } from '../ui/switch';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import { AlertCircle } from 'lucide-react';
import { useFileProcessing } from './hooks/useFileProcessing';

export function DocumentUpload() {
  const navigate = useNavigate();
  const [showLogs, setShowLogs] = useState(false);
  const {
    isProcessing,
    logs: processingLogs,
    error,
    processFiles
  } = useFileProcessing();

  // Transform logs to include required timestamp
  const logs = processingLogs.map(log => ({
    ...log,
    timestamp: new Date(),
    type: log.type || 'info'
  }));

  const handleFileSelect = async (files: File[]) => {
    try {
      const abstractionId = await processFiles(files);
      if (abstractionId) {
        // Navigate to the abstraction page after successful processing
        navigate(`/abstraction/${abstractionId}`);
      }
    } catch (error) {
      // Error is already handled by useFileProcessing
      console.error('File processing error:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Switch
            id="show-logs"
            checked={showLogs}
            onCheckedChange={setShowLogs}
          />
          <Label htmlFor="show-logs">Show Process Log</Label>
        </div>
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <FileUploadZone 
        onFileSelect={handleFileSelect}
        accept={{
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/tiff': ['.tif', '.tiff']
        }}
      />

      {showLogs && logs.length > 0 && (
        <div className="mt-4">
          <UploadTerminal logs={logs} />
        </div>
      )}
    </div>
  );
}
