import { ProcessingError } from '../errors';
import { parseDate } from './date-utils';
import { getPreviousVersion } from './version-utils';
import { deepMergeObjects } from './merge-utils';
import { detectChanges } from '../changes';
import type { LeaseAbstraction, FieldStatus } from '../../../../types/lease';

const DATE_FIELDS = [
  'commencementDate',
  'originalCommencementDate',
  'expirationDate',
  'rentStartDate'
] as const;
type DateField = typeof DATE_FIELDS[number];

/**
 * Processes amendment data, merging with previous version
 */
export async function processAmendment(
  mergedResponse: any,
  extractedDates: any,
  abstractionId: string,
  confidenceThreshold: number,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<any> {
  try {
    addLog('Fetching previous version for amendment processing...');
    const previousVersion = await getPreviousVersion(abstractionId) as LeaseAbstraction;
    
    if (!previousVersion) {
      throw ProcessingError.document(`Previous version not found for abstraction ID: ${abstractionId}`);
    }

    addLog('Detecting changes from previous version...');
    // Ensure we're passing the correct data structure to detectChanges
    const amendmentData = mergedResponse.data || mergedResponse;
    const changes = detectChanges(amendmentData, previousVersion);
    addLog(`Detected ${Object.keys(changes).length} changes`, 'info');

    // Parse dates from extracted dates
    const parsedDates = {
      commencementDate: parseDate(extractedDates.commencementDate),
      expirationDate: parseDate(extractedDates.expirationDate),
      rentStartDate: parseDate(extractedDates.rentStartDate)
    };

    // Preserve original lease data
    const preservedData: Partial<LeaseAbstraction> = {
      // Always preserve these from original lease
      originalCommencementDate: previousVersion.originalCommencementDate,
      confidence: {
        ...(previousVersion.confidence || {}),
        originalCommencementDate: previousVersion.confidence?.originalCommencementDate || 0
      },
      // Preserve other fields unless explicitly changed
      landlord: previousVersion.landlord,
      tenant: previousVersion.tenant,
      premises: previousVersion.premises,
      rentableArea: previousVersion.rentableArea,
      buildingArea: previousVersion.buildingArea,
      proRata: previousVersion.proRata,
      clauses: previousVersion.clauses,
      additionalClauses: previousVersion.additionalClauses
    };

    // If this is the first amendment and original lease had high confidence,
    // maintain that confidence through the amendment
    if (previousVersion.type === 'original' && 
        (previousVersion.confidence?.commencementDate || 0) >= confidenceThreshold) {
      preservedData.confidence = {
        ...preservedData.confidence,
        commencementDate: previousVersion.confidence.commencementDate
      };
    }

    // Initialize field status from previous version
    const fieldStatus: Record<string, FieldStatus> = { ...previousVersion.fieldStatus };
    
    // Update field status for changed fields
    Object.keys(changes).forEach(field => {
      const value = amendmentData[field];
      addLog(`Processing change in field: ${field}`, 'info');
      
      // For dates, check if we have a valid value
      if (DATE_FIELDS.includes(field as DateField)) {
        if (value === null || (value instanceof Date && isNaN(value.getTime()))) {
          fieldStatus[field] = 'missing';
          addLog(`Date field ${field} is missing or invalid`, 'info');
        } else {
          fieldStatus[field] = 'not_missing';
          addLog(`Date field ${field} updated successfully`, 'info');
        }
      }
      // For other fields, check if we have a value
      else if (value === null || value === undefined || value === '') {
        fieldStatus[field] = 'missing';
        addLog(`Field ${field} is missing`, 'info');
      } else {
        fieldStatus[field] = 'not_missing';
        addLog(`Field ${field} updated successfully`, 'info');
      }
    });

    // Prepare amendment data
    const amendmentDataToMerge = {
      ...amendmentData,
      ...parsedDates,
      fieldStatus
    };

    addLog('Merging amendment data with previous version...', 'info');
    
    // First merge preserved data with previous version
    const baseData = deepMergeObjects(previousVersion, preservedData, '', false);
    
    // Then merge amendment data, with isAmendment flag set to true
    const mergedData = deepMergeObjects(baseData, amendmentDataToMerge, '', true);

    // Update missing fields list
    mergedData.missingFields = Array.from(new Set([
      ...(previousVersion.missingFields || []),
      ...Object.entries(fieldStatus)
        .filter(([_, status]) => status === 'missing')
        .map(([field]) => field)
    ]));

    // Remove fields from missingFields if they're now present
    mergedData.missingFields = mergedData.missingFields.filter((field: string) => {
      const value = field.split('.').reduce((obj: any, key: string) => obj?.[key], mergedData);
      return value === null || value === undefined || value === '';
    });

    // Ensure dates are properly formatted for database
    const effectiveDate = parsedDates.commencementDate || null;

    addLog('Amendment processing completed successfully', 'success');
    return { 
      mergedData, 
      changes,
      effectiveDate 
    };
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog(`Amendment processing failed: ${errorMessage}`, 'error');
    throw ProcessingError.document('Failed to merge amendment data', error as Error);
  }
}
