import { Label } from './label';
import { Input } from './input';
import { Textarea } from './textarea';
import { MissingToggle } from './MissingToggle';
import type { FieldStatus } from '../../types/lease';

interface FormFieldProps {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: 'text' | 'number' | 'email' | 'tel' | 'date' | 'textarea';
  placeholder?: string;
  prefix?: string;
  suffix?: React.ReactNode;
  fieldStatus: FieldStatus;
  onFieldStatusChange: (status: FieldStatus) => void;
}

export function FormField({
  id,
  label,
  value,
  onChange,
  type = 'text',
  placeholder,
  prefix,
  suffix,
  fieldStatus,
  onFieldStatusChange,
}: FormFieldProps) {
  // Ensure value is never null
  const safeValue = value ?? '';
  
  // Field is editable if it's missing or not accurate
  const isEditable = fieldStatus === 'missing' || fieldStatus === 'not_accurate';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Only allow changes if field is editable
    if (isEditable) {
      const newValue = e.target.value;
      onChange(newValue);
    }
  };

  // Handle status change from toggle
  const handleStatusChange = (newStatus: FieldStatus) => {
    onFieldStatusChange(newStatus);
    
    // If changing to not_missing but field is empty, set a default value
    if (newStatus === 'not_missing' && (!safeValue || safeValue.trim() === '')) {
      if (type === 'date') {
        onChange(new Date().toISOString().split('T')[0]);
      } else if (type === 'number') {
        onChange('0');
      } else {
        onChange('N/A');
      }
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <Label htmlFor={id}>{label}</Label>
        <MissingToggle 
          status={fieldStatus}
          onChange={handleStatusChange}
        />
      </div>
      <div className="relative">
        {prefix && (
          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">
            {prefix}
          </span>
        )}
        {type === 'textarea' ? (
          <Textarea
            id={id}
            value={safeValue}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={!isEditable}
            className={prefix ? 'pl-8' : ''}
          />
        ) : (
          <Input
            id={id}
            type={type}
            value={safeValue}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={!isEditable}
            className={prefix ? 'pl-8' : ''}
          />
        )}
        {suffix && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            {suffix}
          </div>
        )}
      </div>
    </div>
  );
}
