import { processDocumentImages } from '../image-extractor';
import type { LLMResponse } from '../../types';

/**
 * Processes images for area measurements when text extraction is insufficient
 * @param file The file to process
 * @param mergedResponse The current merged response from text processing
 * @param confidenceThreshold The confidence threshold for measurements
 * @param addLog Logger function
 * @returns Updated merged response with image processing results
 */
export async function processImagesForAreas(
  file: File,
  mergedResponse: LLMResponse,
  confidenceThreshold: number,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<LLMResponse> {
  try {
    // Only process images if area measurements are missing or have low confidence
    if (
      !mergedResponse.data.rentableArea || 
      !mergedResponse.data.buildingArea ||
      (mergedResponse.confidence?.rentableArea || 0) < confidenceThreshold ||
      (mergedResponse.confidence?.buildingArea || 0) < confidenceThreshold
    ) {
      addLog('Area measurements not found in text or low confidence, checking floor plans...');
      const imageResult = await processDocumentImages(file, addLog);
      
      if (imageResult) {
        const updatedResponse = { ...mergedResponse };

        // Update rentable area if image result has higher confidence
        if (imageResult.rentableArea && 
            imageResult.confidence.rentableArea > (mergedResponse.confidence?.rentableArea || 0)) {
          updatedResponse.data.rentableArea = imageResult.rentableArea;
          updatedResponse.confidence.rentableArea = imageResult.confidence.rentableArea;
          addLog(`Updated rentable area from floor plan: ${imageResult.rentableArea} sq ft`, 'success');
        }

        // Update building area if image result has higher confidence
        if (imageResult.buildingArea && 
            imageResult.confidence.buildingArea > (mergedResponse.confidence?.buildingArea || 0)) {
          updatedResponse.data.buildingArea = imageResult.buildingArea;
          updatedResponse.confidence.buildingArea = imageResult.confidence.buildingArea;
          addLog(`Updated building area from floor plan: ${imageResult.buildingArea} sq ft`, 'success');
        }

        return updatedResponse;
      }
    }

    return mergedResponse;
  } catch (error) {
    console.error('Error processing images:', error);
    // Return original response if image processing fails
    return mergedResponse;
  }
}
