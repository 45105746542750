import OpenAI from 'openai';
import { parseDate } from '../dates';
import { withTimeout } from '../utils/timeout';
import { ProcessingError } from './processors/errors';

const openai = new OpenAI({
  baseURL: import.meta.env.VITE_OPENAI_URL,
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  // Removed organization ID since OpenRouter doesn't support this header
  dangerouslyAllowBrowser: true
});

export interface ExtractedDates {
  commencementDate: string | null;
  originalCommencementDate: string | null;
  expirationDate: string | null;
  rentStartDate: string | null;
  confidence: {
    commencementDate: number;
    originalCommencementDate: number;
    expirationDate: number;
    rentStartDate: number;
  };
}

const DATE_EXTRACTION_PROMPT = `
You are a specialized date extraction system. Your task is to find and extract specific dates from lease documents.
Focus ONLY on finding these dates:
- Commencement Date
- Original Commencement Date (if different from commencement date)
- Expiration Date
- Rent Start Date (if different from commencement date)

Guidelines:
1. Look for explicit date mentions and contextual clues
2. Pay attention to terms like "commencing", "effective date", "term begins/ends"
3. For each date, provide a confidence score (0-1) based on how certain you are
4. Return dates in YYYY-MM-DD format
5. Return null if a date is not found or unclear
6. Look for both written dates ("January 1st, 2024") and numeric formats
7. Consider lease amendments that might modify dates

Return the results in this exact JSON format:
{
  "commencementDate": "YYYY-MM-DD" | null,
  "originalCommencementDate": "YYYY-MM-DD" | null,
  "expirationDate": "YYYY-MM-DD" | null,
  "rentStartDate": "YYYY-MM-DD" | null,
  "confidence": {
    "commencementDate": number,
    "originalCommencementDate": number,
    "expirationDate": number,
    "rentStartDate": number
  }
}`;

function validateAndFormatDate(dateStr: string | null): string | null {
  if (!dateStr) return null;
  
  const parsedDate = parseDate(dateStr);
  if (!parsedDate) return null;
  
  return parsedDate.toISOString().split('T')[0]; // Return YYYY-MM-DD format
}

function processExtractedDates(rawDates: any): ExtractedDates {
  return {
    commencementDate: validateAndFormatDate(rawDates.commencementDate),
    originalCommencementDate: validateAndFormatDate(rawDates.originalCommencementDate),
    expirationDate: validateAndFormatDate(rawDates.expirationDate),
    rentStartDate: validateAndFormatDate(rawDates.rentStartDate),
    confidence: {
      commencementDate: Number(rawDates.confidence?.commencementDate) || 0,
      originalCommencementDate: Number(rawDates.confidence?.originalCommencementDate) || 0,
      expirationDate: Number(rawDates.confidence?.expirationDate) || 0,
      rentStartDate: Number(rawDates.confidence?.rentStartDate) || 0
    }
  };
}

export async function extractDates(
  text: string,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ExtractedDates> {
  try {
    addLog('Starting specialized date extraction...');

    // Increased timeout to 120 seconds and added retry logic
    const completion = await withTimeout(
      (async () => {
        const maxRetries = 3;
        let lastError;
        
        for (let attempt = 1; attempt <= maxRetries; attempt++) {
          try {
            return await openai.chat.completions.create({
              model: "openai/gpt-4o-2024-11-20",  // Using standard model name for OpenRouter
              messages: [
                {
                  role: "system",
                  content: DATE_EXTRACTION_PROMPT
                },
                {
                  role: "user",
                  content: text
                }
              ],
              response_format: { type: "json_object" }
            });
          } catch (error) {
            lastError = error;
            if (attempt < maxRetries) {
              addLog(`Attempt ${attempt} failed, retrying...`, 'info');
              await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
            }
          }
        }
        throw lastError;
      })(),
      120000, // 120 second timeout
      'Date extraction timeout'
    );

    if (!completion.choices[0]?.message?.content) {
      throw new Error('No response from date extraction');
    }

    const result = JSON.parse(completion.choices[0].message.content);
    const dates = processExtractedDates(result);

    // Log found dates
    Object.entries(dates).forEach(([key, value]) => {
      if (key !== 'confidence' && value) {
        addLog(`Found ${key}: ${value} (Confidence: ${dates.confidence[key as keyof typeof dates.confidence].toFixed(2)})`, 'success');
      }
    });

    return dates;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog('Error during date extraction: ' + errorMessage, 'error');
    throw ProcessingError.retryable('Date extraction failed: ' + errorMessage);
  }
}
