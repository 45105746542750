import type { LeaseAbstraction } from '../../types/lease';
import { formatDateForDB } from '../dates';

interface EnhancedTrainingExample {
  messages: {
    role: 'system' | 'user' | 'assistant';
    content: string | object;
  }[];
  metadata: {
    documentInfo: {
      type: string;
      path: string;
      checksum?: string;
    };
    metrics: {
      errorRate: number;
      missingFields: string[];
      inaccurateFields: string[];
      confidence: Record<string, number>;
    };
  };
}

export function calculateErrorRate(abstraction: LeaseAbstraction): number {
  const totalFields = Object.keys(abstraction.fieldStatus).length;
  if (totalFields === 0) return 0;

  const errorFields = Object.entries(abstraction.fieldStatus).filter(
    ([_, status]) => status === 'missing' || status === 'not_accurate'
  ).length;

  return errorFields / totalFields;
}

function formatDateSafely(date: Date | null | undefined): string | null {
  if (!date) return null;
  return formatDateForDB(date);
}

export function generateEnhancedTrainingJSONL(abstractions: LeaseAbstraction[]): string {
  return abstractions
    .map(abstraction => {
      // Calculate error rate and metrics
      const errorRate = calculateErrorRate(abstraction);
      const missingFields = Object.entries(abstraction.fieldStatus)
        .filter(([_, status]) => status === 'missing')
        .map(([field]) => field);
      const inaccurateFields = Object.entries(abstraction.fieldStatus)
        .filter(([_, status]) => status === 'not_accurate')
        .map(([field]) => field);

      const example: EnhancedTrainingExample = {
        messages: [
          {
            role: 'system',
            content: 'You are a lease abstraction expert. Analyze the lease document and extract key information in a structured format.'
          },
          {
            role: 'user',
            content: {
              documentContent: abstraction.documentContent || 'Document content not available',
              documentType: abstraction.type,
              context: {
                isAmendment: abstraction.type === 'amendment',
                parentId: abstraction.parentId
              }
            }
          },
          {
            role: 'assistant',
            content: {
              premises: abstraction.premises,
              county: abstraction.county,
              tenantLegalName: abstraction.tenantLegalName,
              commencementDate: formatDateSafely(abstraction.commencementDate),
              originalCommencementDate: formatDateSafely(abstraction.originalCommencementDate),
              expirationDate: formatDateSafely(abstraction.expirationDate),
              rentStartDate: formatDateSafely(abstraction.rentStartDate),
              rentableArea: abstraction.rentableArea,
              proRata: abstraction.proRata,
              buildingArea: abstraction.buildingArea,
              baseRent: abstraction.baseRent,
              annualRent: abstraction.annualRent,
              securityDeposit: abstraction.securityDeposit,
              landlord: abstraction.landlord,
              tenant: abstraction.tenant,
              clauses: abstraction.clauses,
              additionalClauses: abstraction.additionalClauses
            }
          }
        ],
        metadata: {
          documentInfo: {
            type: abstraction.type,
            path: abstraction.documentPath || '',
            checksum: abstraction.documentChecksum
          },
          metrics: {
            errorRate,
            missingFields,
            inaccurateFields,
            confidence: abstraction.confidence
          }
        }
      };

      return JSON.stringify(example);
    })
    .join('\n');
}

export function exportEnhancedTrainingData(abstractions: LeaseAbstraction[]) {
  // Calculate overall error rate
  const overallErrorRate = abstractions.reduce(
    (sum, abs) => sum + calculateErrorRate(abs),
    0
  ) / abstractions.length;

  // Split into training (80%) and validation (20%) sets
  const splitIndex = Math.floor(abstractions.length * 0.8);
  const trainingDocs = abstractions.slice(0, splitIndex);
  const validationDocs = abstractions.slice(splitIndex);

  // Generate enhanced JSONL files
  const trainingJSONL = generateEnhancedTrainingJSONL(trainingDocs);
  const validationJSONL = generateEnhancedTrainingJSONL(validationDocs);

  // Create metadata file
  const metadata = {
    totalDocuments: abstractions.length,
    trainingCount: trainingDocs.length,
    validationCount: validationDocs.length,
    overallErrorRate,
    timestamp: new Date().toISOString(),
    modelVersion: process.env.MODEL_VERSION || 'unknown'
  };

  return {
    training: trainingJSONL,
    validation: validationJSONL,
    metadata
  };
}

export function downloadEnhancedTrainingData(
  trainingJSONL: string,
  validationJSONL: string,
  metadata: object
) {
  // Download training file
  const trainingBlob = new Blob([trainingJSONL], { type: 'application/jsonl' });
  const trainingUrl = URL.createObjectURL(trainingBlob);
  const trainingLink = document.createElement('a');
  trainingLink.href = trainingUrl;
  trainingLink.download = `training_${new Date().toISOString()}.jsonl`;
  document.body.appendChild(trainingLink);
  trainingLink.click();
  document.body.removeChild(trainingLink);
  URL.revokeObjectURL(trainingUrl);

  // Download validation file
  const validationBlob = new Blob([validationJSONL], { type: 'application/jsonl' });
  const validationUrl = URL.createObjectURL(validationBlob);
  const validationLink = document.createElement('a');
  validationLink.href = validationUrl;
  validationLink.download = `validation_${new Date().toISOString()}.jsonl`;
  document.body.appendChild(validationLink);
  validationLink.click();
  document.body.removeChild(validationLink);
  URL.revokeObjectURL(validationUrl);

  // Download metadata file
  const metadataBlob = new Blob([JSON.stringify(metadata, null, 2)], { type: 'application/json' });
  const metadataUrl = URL.createObjectURL(metadataBlob);
  const metadataLink = document.createElement('a');
  metadataLink.href = metadataUrl;
  metadataLink.download = `metadata_${new Date().toISOString()}.json`;
  document.body.appendChild(metadataLink);
  metadataLink.click();
  document.body.removeChild(metadataLink);
  URL.revokeObjectURL(metadataUrl);
}
