import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { FileText, ArrowRight } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export default function Home() {
  const navigate = useNavigate();
  const { user } = useAuth();

  console.log('Home component rendered, user:', user);

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-5rem)]">
      <div className="text-center space-y-4 mb-8">
        <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
          Lease Abstraction Made Simple
        </h1>
        <p className="text-xl text-muted-foreground">
          Upload your lease documents and let AI help you extract key information
        </p>
      </div>
      
      <Button
        size="lg"
        className="h-16 px-8 text-lg shadow-lg hover:shadow-xl transition-shadow"
        onClick={() => navigate('/upload')}
      >
        <FileText className="mr-2 h-6 w-6" />
        Create Abstract
        <ArrowRight className="ml-2 h-5 w-5" />
      </Button>
      
      <div className="mt-8 text-center">
        <p className="text-muted-foreground">
          Or view your{' '}
          <Button
            variant="link"
            className="text-primary"
            onClick={() => navigate('/abstractions')}
          >
            existing abstractions
          </Button>
        </p>
      </div>
    </div>
  );
}
