import { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { FileDown, FileText, Table, FileJson } from 'lucide-react';
import { toast } from 'sonner';
import { generatePDF } from '@/lib/pdf';
import { generateTrainingJSONL, downloadJSONL } from '@/lib/training/export';
import type { LeaseAbstraction } from '@/types/lease';

interface ExportOptionsProps {
  abstraction: LeaseAbstraction;
}

export function ExportOptions({ abstraction }: ExportOptionsProps) {
  const [exporting, setExporting] = useState(false);

  const exportToPDF = async () => {
    try {
      setExporting(true);
      const fileName = abstraction.premises
        ? `lease-abstract-${abstraction.premises.replace(/[^a-z0-9]/gi, '-').toLowerCase()}.pdf`
        : `lease-abstract-${abstraction.id}.pdf`;
      generatePDF(abstraction, fileName);
      toast.success('PDF exported successfully');
    } catch (error) {
      console.error('PDF export error:', error);
      toast.error('Failed to export PDF');
    } finally {
      setExporting(false);
    }
  };

  const exportToExcel = async () => {
    try {
      setExporting(true);
      // Excel export logic remains the same
      // ... (existing Excel export code)
      toast.success('Excel file exported successfully');
    } catch (error) {
      console.error('Excel export error:', error);
      toast.error('Failed to export Excel file');
    } finally {
      setExporting(false);
    }
  };

  const exportToJSONL = () => {
    try {
      setExporting(true);
      const jsonl = generateTrainingJSONL([abstraction]);
      downloadJSONL(jsonl, `abstraction-${abstraction.id}-training.jsonl`);
      toast.success('JSONL file exported successfully');
    } catch (error) {
      console.error('JSONL export error:', error);
      toast.error('Failed to export JSONL file');
    } finally {
      setExporting(false);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" disabled={exporting}>
          <FileDown className="mr-2 h-4 w-4" />
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={exportToPDF}>
          <FileText className="mr-2 h-4 w-4" />
          Export as PDF
        </DropdownMenuItem>
        <DropdownMenuItem onClick={exportToExcel}>
          <Table className="mr-2 h-4 w-4" />
          Export as Excel
        </DropdownMenuItem>
        <DropdownMenuItem onClick={exportToJSONL}>
          <FileJson className="mr-2 h-4 w-4" />
          Export as JSONL
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}