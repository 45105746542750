import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { DocumentUpload } from '@/components/upload/DocumentUpload';

export default function LeaseUpload() {
  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-3xl font-bold tracking-tight">Upload Lease Document</h1>
        <p className="text-muted-foreground">
          Upload your lease documents for abstraction
        </p>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Upload Lease Document</CardTitle>
        </CardHeader>
        <CardContent>
          <DocumentUpload />
        </CardContent>
      </Card>
    </div>
  );
}