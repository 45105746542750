import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from './components/ui/sonner';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import Login from './pages/Login';
import OAuth from './pages/auth/oauth';
import Home from './pages/Home';
import Abstractions from './pages/Abstractions';
import LeaseAbstraction from './pages/LeaseAbstraction';
import LeaseUpload from './components/LeaseUpload';
import Settings from './pages/Settings';
import Admin from './pages/Admin';
import UserDetails from './pages/UserDetails';
import Training from './pages/Training';

export default function App() {
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/auth/oauth" element={<OAuth />} />
        
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="abstractions" element={<Abstractions />} />
            <Route path="upload" element={<LeaseUpload />} />
            <Route path="abstraction/:id" element={<LeaseAbstraction />} />
            <Route path="settings" element={<Settings />} />
            <Route path="training" element={<Training />} />
            
            {/* Admin-only routes */}
            <Route element={<AdminRoute />}>
              <Route path="admin" element={<Admin />} />
              <Route path="admin/users/:id" element={<UserDetails />} />
            </Route>
          </Route>
        </Route>

        {/* Fallback route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Toaster />
    </>
  );
}
