import { parseDate } from './date-utils';
import type { RawLeaseData, ProcessedLeaseData } from '../../types';
import type { LeaseAbstraction, ContactInfo, FieldStatus } from '../../../../types/lease';

const FINANCIAL_FIELDS = ['baseRent', 'annualRent', 'securityDeposit'] as const;
type FinancialField = typeof FINANCIAL_FIELDS[number];

const DATE_FIELDS = [
  'commencementDate',
  'originalCommencementDate',
  'expirationDate',
  'rentStartDate'
] as const;
type DateField = typeof DATE_FIELDS[number];

/**
 * Transforms raw LLM output data into processed lease data
 */
export function transformRawData(rawData: RawLeaseData): ProcessedLeaseData {
  // Get base rent value
  const baseRent = rawData.baseRent?.value ?? null;
  
  // Calculate annual rent if not provided
  let annualRent = rawData.annualRent?.value ?? null;
  let annualRentConfidence = rawData.annualRent?.confidence ?? 0;
  
  // If annual rent is missing but base rent exists, calculate it
  if (annualRent === null && baseRent !== null) {
    annualRent = baseRent * 12;
    // Set confidence based on base rent confidence
    annualRentConfidence = rawData.baseRent?.confidence ?? 0;
  }

  // Parse dates
  const dates = {
    commencementDate: rawData.commencementDate ? parseDate(rawData.commencementDate) : null,
    originalCommencementDate: rawData.originalCommencementDate ? parseDate(rawData.originalCommencementDate) : null,
    expirationDate: rawData.expirationDate ? parseDate(rawData.expirationDate) : null,
    rentStartDate: rawData.rentStartDate ? parseDate(rawData.rentStartDate) : null
  };

  return {
    ...rawData,
    ...dates,
    baseRent,
    annualRent,
    securityDeposit: rawData.securityDeposit?.value ?? null,
    landlord: rawData.landlord as ContactInfo,
    tenant: rawData.tenant as ContactInfo,
    clauses: rawData.clauses,
    additionalClauses: rawData.additionalClauses,
    missingFields: rawData.missingFields
  };
}

/**
 * Gets confidence scores from raw data including financial field confidence
 */
export function getConfidenceScores(rawData: RawLeaseData): Record<string, number> {
  const confidence: Record<string, number> = {};

  // Handle financial fields
  FINANCIAL_FIELDS.forEach(field => {
    const financialData = rawData[field];
    if (financialData && typeof financialData === 'object' && 'confidence' in financialData) {
      confidence[field] = financialData.confidence || 0;
    } else {
      confidence[field] = 0;
    }
  });

  // If annual rent is missing but base rent exists with good confidence,
  // use base rent confidence for annual rent
  if (confidence.annualRent === 0 && confidence.baseRent > 0) {
    confidence.annualRent = confidence.baseRent;
  }

  // Handle date fields
  DATE_FIELDS.forEach(field => {
    const value = rawData[field];
    if (value) {
      confidence[field] = 1;
    } else {
      confidence[field] = 0;
    }
  });

  // Handle other fields
  Object.entries(rawData).forEach(([key, value]) => {
    if (key === 'missingFields') return;
    if (FINANCIAL_FIELDS.includes(key as FinancialField)) return;
    if (DATE_FIELDS.includes(key as DateField)) return;

    if (value === null || value === undefined || value === '') {
      confidence[key] = 0;
    } else if (typeof value === 'object') {
      const subFields = Object.values(value as Record<string, any>);
      const validFields = subFields.filter(v => v !== null && v !== undefined && v !== '');
      confidence[key] = validFields.length / subFields.length;
    } else {
      confidence[key] = 1;
    }
  });

  return confidence;
}

/**
 * Determines field status based on value and confidence
 */
function getFieldStatus(
  field: string,
  value: any,
  confidence: number,
  confidenceThreshold: number
): FieldStatus {
  // For date fields, check both value and confidence
  if (DATE_FIELDS.includes(field as DateField)) {
    if (value === null || value === undefined || value === '') {
      return 'missing';
    }
    if (value instanceof Date && isNaN(value.getTime())) {
      return 'missing';
    }
    return 'not_missing';
  }

  // For financial fields, check both value and confidence
  if (FINANCIAL_FIELDS.includes(field as FinancialField)) {
    if (value === null || value === undefined || value === '') {
      return 'missing';
    }
    return confidence >= confidenceThreshold ? 'not_missing' : 'not_accurate';
  }

  // For other fields
  if (value === null || value === undefined || value === '') {
    return 'missing';
  }
  return confidence >= confidenceThreshold ? 'not_missing' : 'not_accurate';
}

/**
 * Creates a partial lease abstraction from processed data
 */
export function createPartialAbstraction(
  processedData: ProcessedLeaseData,
  confidence: Record<string, number>,
  confidenceThreshold = 0.7
): Partial<LeaseAbstraction> {
  // Calculate field status for all fields
  const fieldStatus: Record<string, FieldStatus> = {};
  
  // Handle date fields
  DATE_FIELDS.forEach(field => {
    const value = processedData[field];
    fieldStatus[field] = getFieldStatus(
      field,
      value,
      confidence[field] || 0,
      confidenceThreshold
    );
  });

  // Handle financial fields
  FINANCIAL_FIELDS.forEach(field => {
    fieldStatus[field] = getFieldStatus(
      field,
      processedData[field],
      confidence[field] || 0,
      confidenceThreshold
    );
  });

  // Handle other fields
  Object.entries(processedData).forEach(([field, value]) => {
    if (field === 'missingFields') return;
    if (DATE_FIELDS.includes(field as DateField)) return;
    if (FINANCIAL_FIELDS.includes(field as FinancialField)) return;

    fieldStatus[field] = getFieldStatus(
      field,
      value,
      confidence[field] || 0,
      confidenceThreshold
    );
  });

  // If annual rent was calculated from base rent, update its field status
  if (processedData.annualRent === null && processedData.baseRent !== null) {
    fieldStatus.annualRent = fieldStatus.baseRent;
  }

  return {
    ...processedData,
    confidence,
    fieldStatus,
    missingFields: Array.from(new Set([
      ...(processedData.missingFields || []),
      ...Object.entries(fieldStatus)
        .filter(([_, status]) => status === 'missing')
        .map(([field]) => field)
    ]))
  };
}
