import { useState } from 'react';
import type { LeaseAbstraction } from '../../types/lease';

export function useAbstractionState() {
  const [abstraction, setAbstraction] = useState<LeaseAbstraction | null>(null);
  const [originalAbstraction, setOriginalAbstraction] = useState<Partial<LeaseAbstraction> | null>(null);
  const [versions, setVersions] = useState<LeaseAbstraction[]>([]);
  const [currentVersionId, setCurrentVersionId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  return {
    abstraction,
    setAbstraction,
    originalAbstraction,
    setOriginalAbstraction,
    versions,
    setVersions,
    currentVersionId,
    setCurrentVersionId,
    loading,
    setLoading,
    saving,
    setSaving,
  };
}
