import { supabase } from '../../supabase';
import { ProcessingError } from '../../llm/processors/errors';
import { mapToLeaseAbstraction } from './mappers';

export async function reorderVersions(abstractionId: string): Promise<void> {
  try {
    // Get current session to ensure we're authenticated
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'You must be logged in to reorder versions'
      }]);
    }

    // Get all versions ordered by effective date
    const { data: versions, error } = await supabase
      .from('lease_abstractions')
      .select('*')
      .or(`id.eq.${abstractionId},parent_id.eq.${abstractionId}`);

    if (error) {
      console.error('Failed to fetch versions:', error);
      throw ProcessingError.database('Failed to fetch versions for reordering', error);
    }

    if (!versions?.length) {
      throw ProcessingError.database(`No versions found for reordering, ID: ${abstractionId}`);
    }

    // Sort versions by effective date, falling back to commencement date
    const sortedVersions = versions.map(mapToLeaseAbstraction).sort((a, b) => {
      const dateA = a.effectiveDate ? new Date(a.effectiveDate).getTime() :
                   a.commencementDate ? new Date(a.commencementDate).getTime() : 0;
      const dateB = b.effectiveDate ? new Date(b.effectiveDate).getTime() :
                   b.commencementDate ? new Date(b.commencementDate).getTime() : 0;
      return dateA - dateB;
    });

    // Use a high temporary version number to avoid conflicts
    const tempVersion = 999999;

    // Update version numbers one at a time, using temporary high numbers first
    for (let i = 0; i < sortedVersions.length; i++) {
      const version = sortedVersions[i];
      const tempNum = tempVersion + i;
      
      const { error: updateError } = await supabase
        .from('lease_abstractions')
        .update({
          version: tempNum,
          is_current_version: false
        })
        .eq('id', version.id);

      if (updateError) {
        console.error('Failed to update to temp version:', {
          versionId: version.id,
          tempVersion: tempNum,
          error: updateError
        });
        throw ProcessingError.database(`Failed to update version ${version.id}`, updateError);
      }
    }

    // Now update to final version numbers
    for (let i = 0; i < sortedVersions.length; i++) {
      const version = sortedVersions[i];
      const isLatest = i === sortedVersions.length - 1;
      
      const { error: updateError } = await supabase
        .from('lease_abstractions')
        .update({
          version: i + 1,
          is_current_version: isLatest
        })
        .eq('id', version.id);

      if (updateError) {
        console.error('Failed to update to final version:', {
          versionId: version.id,
          finalVersion: i + 1,
          error: updateError
        });
        throw ProcessingError.database(`Failed to update version ${version.id}`, updateError);
      }
    }

  } catch (error) {
    console.error('Reorder versions error:', error);
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.database('Failed to reorder versions', error as Error);
  }
}
