import { useState, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { processFiles } from '../services/file-processing-service';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { ProcessLogger } from '../../../lib/process-logger';
import type { LoggerFunction } from '../types';

interface ProcessingState {
  isProcessing: boolean;
  progress: number;
  logs: Array<{
    message: string;
    type?: 'info' | 'error' | 'success';
    timestamp?: number;
  }>;
  error?: string;
}

const PROCESSING_STEPS = {
  START: 5,
  VALIDATION: 15,
  TEXT_EXTRACTION: 25,
  LLM_PROCESSING: 50,
  DATABASE: 75,
  COMPLETE: 100
};

export function useFileProcessing() {
  const { user } = useAuth();
  const [state, setState] = useState<ProcessingState>({
    isProcessing: false,
    progress: 0,
    logs: []
  });

  const updateProgress = useCallback((step: keyof typeof PROCESSING_STEPS) => {
    setState(prev => ({
      ...prev,
      progress: PROCESSING_STEPS[step]
    }));
  }, []);

  const addLog: LoggerFunction = useCallback((message: string, type?: 'info' | 'error' | 'success') => {
    setState(prev => ({
      ...prev,
      logs: [...prev.logs, { 
        message, 
        type,
        timestamp: Date.now()
      }]
    }));

    // Update progress based on log messages
    if (message.includes('validation')) {
      updateProgress('VALIDATION');
    } else if (message.includes('extraction')) {
      updateProgress('TEXT_EXTRACTION');
    } else if (message.includes('LLM processing')) {
      updateProgress('LLM_PROCESSING');
    } else if (message.includes('database')) {
      updateProgress('DATABASE');
    } else if (message.includes('completed successfully')) {
      updateProgress('COMPLETE');
    }
  }, [updateProgress]);

  const processSelectedFiles = useCallback(async (files: File[]) => {
    let processLogger: ProcessLogger | undefined;
    
    try {
      // Validate user authentication
      if (!user?.id) {
        throw ProcessingError.validation([{
          field: 'auth',
          message: 'Please log in to upload documents'
        }]);
      }

      // Reset state
      setState({
        isProcessing: true,
        progress: PROCESSING_STEPS.START,
        logs: []
      });

      // Create process logger instance
      processLogger = new ProcessLogger(user.id);

      // Process files with progress updates
      const { abstractionId, error: processError } = await processFiles(
        files,
        user.id,
        addLog,
        processLogger
      );

      if (processError) {
        throw ProcessingError.document(processError);
      }

      // Update state with success
      setState(prev => ({
        ...prev,
        isProcessing: false,
        progress: PROCESSING_STEPS.COMPLETE
      }));

      return abstractionId;
    } catch (error) {
      // Handle errors
      const errorMessage = error instanceof ProcessingError 
        ? error.message 
        : error instanceof Error 
          ? error.message 
          : 'Unknown error occurred';

      // Update state with error
      setState(prev => ({
        ...prev,
        isProcessing: false,
        error: errorMessage
      }));

      // Re-throw for UI handling
      if (error instanceof ProcessingError) {
        throw error;
      }
      throw ProcessingError.document(
        'File processing failed',
        error as Error
      );
    } finally {
      // Ensure all logs are flushed to database
      if (processLogger) {
        await processLogger.flush();
      }
    }
  }, [user, addLog, updateProgress]);

  const clearLogs = useCallback(() => {
    setState(prev => ({
      ...prev,
      logs: [],
      error: undefined
    }));
  }, []);

  return {
    isProcessing: state.isProcessing,
    progress: state.progress,
    logs: state.logs,
    error: state.error,
    processFiles: processSelectedFiles,
    clearLogs
  };
}
