import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { Progress } from '../ui/progress';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { GeneralInformation } from '../abstraction/GeneralInformation';
import { KeyInformation } from '../abstraction/KeyInformation';
import { FinancialSummary } from '../abstraction/FinancialSummary';
import { ContactInformation } from '../abstraction/ContactInformation';
import { ClausesSection } from '../abstraction/ClausesSection';
import type { LeaseAbstraction, FieldStatus, Clauses, AdditionalClauses } from '../../types/lease';
import { calculateAbstractionMetrics } from '../../lib/training/metrics';
import { CheckCircle, XCircle, AlertTriangle } from 'lucide-react';

interface AbstractionReviewProps {
  originalAbstraction?: Partial<LeaseAbstraction>;
  onSave: (reviewedAbstraction: Partial<LeaseAbstraction>) => Promise<void>;
}

const defaultClauses: Clauses = {
  alterations: '',
  assignmentSublease: '',
  auditRights: '',
  baseRentDetails: '',
  camOpex: '',
  default: ''
};

const defaultAdditionalClauses: AdditionalClauses = {
  estoppelSnda: '',
  financialReporting: '',
  forceMajeure: '',
  goDark: '',
  holdover: '',
  insurance: '',
  lateFee: '',
  legalNotices: '',
  parking: '',
  realEstateTaxes: '',
  relocationOption: '',
  renewalOption: '',
  repairsMaintenance: '',
  rightOfFirstOffer: '',
  rightOfFirstRefusal: '',
  securityDepositConditions: '',
  signage: '',
  surrender: '',
  terminationOption: '',
  tiAllowance: '',
  use: '',
  utilities: ''
};

const defaultAbstraction: Partial<LeaseAbstraction> = {
  premises: '',
  county: '',
  tenantLegalName: '',
  commencementDate: null,
  originalCommencementDate: null,
  expirationDate: null,
  rentStartDate: null,
  rentableArea: null,
  proRata: null,
  buildingArea: null,
  baseRent: null,
  annualRent: null,
  securityDeposit: null,
  landlord: {
    name: '',
    address: '',
    phone: '',
    email: ''
  },
  tenant: {
    name: '',
    address: '',
    phone: '',
    email: ''
  },
  clauses: defaultClauses,
  additionalClauses: defaultAdditionalClauses,
  missingFields: []
};

export function AbstractionReview({ originalAbstraction = {}, onSave }: AbstractionReviewProps) {
  // Initialize with default values and merge with original abstraction
  const initialAbstraction: Partial<LeaseAbstraction> = {
    ...defaultAbstraction,
    ...originalAbstraction,
    landlord: {
      ...defaultAbstraction.landlord,
      ...(originalAbstraction?.landlord || {})
    },
    tenant: {
      ...defaultAbstraction.tenant,
      ...(originalAbstraction?.tenant || {})
    },
    clauses: {
      ...defaultClauses,
      ...(originalAbstraction?.clauses || {})
    },
    additionalClauses: {
      ...defaultAdditionalClauses,
      ...(originalAbstraction?.additionalClauses || {})
    }
  };

  const [reviewedAbstraction, setReviewedAbstraction] = useState<Partial<LeaseAbstraction>>(
    initialAbstraction
  );
  const [fieldStatus, setFieldStatus] = useState<Record<string, FieldStatus>>({});
  const [saving, setSaving] = useState(false);

  const metrics = calculateAbstractionMetrics(originalAbstraction || {}, reviewedAbstraction);

  const handleSave = async () => {
    try {
      setSaving(true);
      await onSave(reviewedAbstraction);
    } finally {
      setSaving(false);
    }
  };

  const handleFieldStatusChange = (field: string, status: FieldStatus) => {
    setFieldStatus(prev => ({
      ...prev,
      [field]: status
    }));
  };

  const getAccuracyColor = (score: number) => {
    if (score >= 0.8) return 'text-green-500';
    if (score >= 0.6) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="space-y-6">
      <div className="grid gap-4 md:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CheckCircle className="h-4 w-4 text-green-500" />
              Accuracy
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-2">
              <Progress value={metrics.fieldAccuracy * 100} />
              <span className={getAccuracyColor(metrics.fieldAccuracy)}>
                {Math.round(metrics.fieldAccuracy * 100)}%
              </span>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <AlertTriangle className="h-4 w-4 text-yellow-500" />
              Missing Fields
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-2">
              <span className="text-2xl font-bold">
                {metrics.missingFields.length}
              </span>
              <span className="text-muted-foreground">fields</span>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <XCircle className="h-4 w-4 text-red-500" />
              Incorrect Fields
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-2">
              <span className="text-2xl font-bold">
                {metrics.incorrectFields.length}
              </span>
              <span className="text-muted-foreground">fields</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Abstraction Review</CardTitle>
          <Button onClick={handleSave} disabled={saving}>
            {saving ? 'Saving...' : 'Save Review'}
          </Button>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="general">
            <TabsList className="grid w-full grid-cols-5">
              <TabsTrigger value="general">General</TabsTrigger>
              <TabsTrigger value="key">Key Info</TabsTrigger>
              <TabsTrigger value="financial">Financial</TabsTrigger>
              <TabsTrigger value="contact">Contact</TabsTrigger>
              <TabsTrigger value="clauses">Clauses</TabsTrigger>
            </TabsList>

            <TabsContent value="general">
              <GeneralInformation
                {...reviewedAbstraction}
                fieldStatus={fieldStatus}
                onChange={(field, value) =>
                  setReviewedAbstraction(prev => ({ ...prev, [field]: value }))
                }
                onFieldStatusChange={handleFieldStatusChange}
              />
            </TabsContent>

            <TabsContent value="key">
              <KeyInformation
                {...reviewedAbstraction}
                fieldStatus={fieldStatus}
                onChange={(field, value) =>
                  setReviewedAbstraction(prev => ({ ...prev, [field]: value }))
                }
                onFieldStatusChange={handleFieldStatusChange}
              />
            </TabsContent>

            <TabsContent value="financial">
              <FinancialSummary
                {...reviewedAbstraction}
                fieldStatus={fieldStatus}
                onChange={(field, value) =>
                  setReviewedAbstraction(prev => ({ ...prev, [field]: value }))
                }
                onFieldStatusChange={handleFieldStatusChange}
              />
            </TabsContent>

            <TabsContent value="contact">
              <ContactInformation
                landlord={reviewedAbstraction.landlord || defaultAbstraction.landlord}
                tenant={reviewedAbstraction.tenant || defaultAbstraction.tenant}
                fieldStatus={fieldStatus}
                onChange={(type, field, value) =>
                  setReviewedAbstraction(prev => ({
                    ...prev,
                    [type]: { ...(prev[type] || {}), [field]: value }
                  }))
                }
                onFieldStatusChange={handleFieldStatusChange}
              />
            </TabsContent>

            <TabsContent value="clauses">
              <div className="space-y-6">
                <ClausesSection
                  title="Abstract Clauses"
                  clauses={reviewedAbstraction.clauses || defaultClauses}
                  fieldStatus={fieldStatus}
                  onChange={(field, value) =>
                    setReviewedAbstraction(prev => ({
                      ...prev,
                      clauses: {
                        ...defaultClauses,
                        ...(prev.clauses || {}),
                        [field]: value
                      }
                    }))
                  }
                  onFieldStatusChange={handleFieldStatusChange}
                />
                <ClausesSection
                  title="Additional Clauses"
                  clauses={reviewedAbstraction.additionalClauses || defaultAdditionalClauses}
                  fieldStatus={fieldStatus}
                  onChange={(field, value) =>
                    setReviewedAbstraction(prev => ({
                      ...prev,
                      additionalClauses: {
                        ...defaultAdditionalClauses,
                        ...(prev.additionalClauses || {}),
                        [field]: value
                      }
                    }))
                  }
                  onFieldStatusChange={handleFieldStatusChange}
                />
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      {metrics.modifiedFields.length > 0 && (
        <Card>
          <CardHeader>
            <CardTitle>Modified Fields</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-2">
              {metrics.modifiedFields.map(field => (
                <Badge key={field} variant="secondary">
                  {field}
                </Badge>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
