/**
 * Parses a date string into a Date object
 * @param dateStr The date string to parse
 * @returns Date object or null if invalid
 */
export function parseDate(dateStr: string | null): Date | null {
  if (!dateStr) return null;
  const date = new Date(dateStr);
  return isNaN(date.getTime()) ? null : date;
}
