import { ProcessingError } from './errors';
import { withRetry } from './retry';
import { processWithLlama } from './llama';
import { DOCUMENT_VALIDATION_PROMPT, GROUP_CONTEXT_PROMPT } from './prompts';
import { RawLeaseData } from '../types';

export interface ValidationResult {
  badData: boolean;
  leaseOnly: boolean;
  amendmentOnly: boolean;
  leaseAndAmendment: boolean;
  leaseAndAmendments: boolean;
  reason: string;
  // Key identifiers for document relationship validation
  landlord?: string;
  tenant?: string;
  premises?: string;
  // Additional metadata
  effectiveDate?: string;
  expirationDate?: string;
  documentType?: 'lease' | 'amendment';
  relationshipDetails?: {
    relationship?: 'amendment' | 'renewal' | 'extension';
    modifiedFields?: string[];
  };
}

interface ValidatedDocument {
  name: string;
  type: 'lease' | 'amendment';
  validationResult: ValidationResult;
}

// Keep track of validated documents per group
const validatedGroups = new Map<string, ValidatedDocument[]>();

export class ValidationError extends Error {
  constructor(
    public readonly type: 'BAD_DATA' | 'AMENDMENT_ONLY' | 'RELATIONSHIP',
    message: string
  ) {
    super(message);
    this.name = 'ValidationError';
  }

  static badData(message: string): ValidationError {
    return new ValidationError('BAD_DATA', message);
  }

  static amendmentOnly(message: string): ValidationError {
    return new ValidationError('AMENDMENT_ONLY', message);
  }

  static relationship(message: string): ValidationError {
    return new ValidationError('RELATIONSHIP', message);
  }
}

function formatError(error: unknown): string {
  if (error instanceof Error) {
    let message = error.message;
    if (error.stack) {
      message += `\nStack trace:\n${error.stack}`;
    }
    return message;
  }
  return String(error);
}

function normalizeText(text: string): string {
  if (!text) return '';
  return text
    .toLowerCase()
    .replace(/\b(inc|incorporated|llc|corporation|corp|company|co|ltd|limited)\b\.?/g, '') // Remove common business suffixes
    .replace(/\b(suite|ste|unit|apt|building|bldg|floor|fl)\b\.?/g, '') // Normalize address components
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters but keep spaces
    .replace(/\s+/g, ' ') // Normalize spaces
    .trim();
}

function extractAddress(text: string): { street?: string; number?: string; city?: string; state?: string } {
  if (!text) return {};
  
  const parts = text.toLowerCase().split(/,|\n/).map(p => p.trim());
  const streetPart = parts[0] || '';
  const numberMatch = streetPart.match(/^\d+/);
  
  // Try to extract state from the last part
  const stateMatch = parts[parts.length - 1]?.match(/\b[a-z]{2}\b/);
  
  return {
    street: streetPart.replace(/^\d+\s*/, ''),
    number: numberMatch ? numberMatch[0] : undefined,
    city: parts[1],
    state: stateMatch ? stateMatch[0].toUpperCase() : undefined
  };
}

function addressMatches(addr1: string | undefined, addr2: string | undefined): boolean {
  if (!addr1 || !addr2) return false;
  
  const parsed1 = extractAddress(addr1);
  const parsed2 = extractAddress(addr2);
  
  // Street number must match if both have it
  if (parsed1.number && parsed2.number && parsed1.number !== parsed2.number) {
    return false;
  }
  
  // Street name similarity check
  if (parsed1.street && parsed2.street) {
    const norm1 = normalizeText(parsed1.street);
    const norm2 = normalizeText(parsed2.street);
    
    // Check for substring match or significant word overlap
    const words1 = new Set(norm1.split(/\s+/));
    const words2 = new Set(norm2.split(/\s+/));
    const commonWords = [...words1].filter(word => words2.has(word));
    
    if (commonWords.length === 0 && !norm1.includes(norm2) && !norm2.includes(norm1)) {
      return false;
    }
  }
  
  // State must match if both have it
  if (parsed1.state && parsed2.state && parsed1.state !== parsed2.state) {
    return false;
  }
  
  // City similarity check if both have it
  if (parsed1.city && parsed2.city) {
    const normCity1 = normalizeText(parsed1.city);
    const normCity2 = normalizeText(parsed2.city);
    if (!normCity1.includes(normCity2) && !normCity2.includes(normCity1)) {
      return false;
    }
  }
  
  return true;
}

function nameMatches(name1: string | undefined, name2: string | undefined): boolean {
  if (!name1 || !name2) return false;
  
  const norm1 = normalizeText(name1);
  const norm2 = normalizeText(name2);
  
  // Check for exact match after normalization
  if (norm1 === norm2) return true;
  
  // Check if one contains the other
  if (norm1.includes(norm2) || norm2.includes(norm1)) return true;
  
  // Split into words and check for significant word overlap
  const words1 = new Set(norm1.split(/\s+/).filter(w => w.length > 2)); // Filter out short words
  const words2 = new Set(norm2.split(/\s+/).filter(w => w.length > 2));
  
  if (words1.size === 0 || words2.size === 0) return false;
  
  const commonWords = [...words1].filter(word => words2.has(word));
  
  // If we have significant word overlap (more than 40% of the shorter name)
  const minWords = Math.min(words1.size, words2.size);
  return commonWords.length >= minWords * 0.4;
}

function transformToValidationResult(rawData: any): ValidationResult {
  // Create a default validation result with required fields
  const validationResult: ValidationResult = {
    badData: false,
    leaseOnly: rawData.documentType === 'lease',
    amendmentOnly: rawData.documentType === 'amendment',
    leaseAndAmendment: false,
    leaseAndAmendments: false,
    reason: rawData.reason || 'Unknown reason',
    documentType: rawData.documentType
  };

  // Copy optional fields if they exist
  if (rawData.landlord) {
    validationResult.landlord = typeof rawData.landlord === 'string' 
      ? rawData.landlord 
      : rawData.landlord.name;
  }
  if (rawData.tenant) {
    validationResult.tenant = typeof rawData.tenant === 'string'
      ? rawData.tenant
      : rawData.tenant.name;
  }
  if (rawData.premises) {
    validationResult.premises = rawData.premises;
  }
  if (rawData.effectiveDate) {
    validationResult.effectiveDate = rawData.effectiveDate;
  }
  if (rawData.expirationDate) {
    validationResult.expirationDate = rawData.expirationDate;
  }
  if (rawData.relationshipDetails) {
    validationResult.relationshipDetails = rawData.relationshipDetails;
  }

  return validationResult;
}

export async function validateDocument(
  text: string,
  fileName: string,
  groupId: string,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ValidationResult> {
  if (!text?.trim()) {
    const error = ProcessingError.document('No text content provided for validation');
    console.error('Document validation error:', formatError(error));
    throw error;
  }

  try {
    addLog('Validating document content...');

    const groupDocs = validatedGroups.get(groupId) || [];
    const previousContext = groupDocs.map(doc => 
      `${doc.name} (${doc.type}): ${doc.validationResult.reason}`
    ).join('\n');

    const contextPrompt = GROUP_CONTEXT_PROMPT
      .replace('{{previousDocuments}}', previousContext || 'None')
      .replace('{{currentDocument}}', fileName);

    const fullPrompt = `${DOCUMENT_VALIDATION_PROMPT}\n\n${contextPrompt}`;

    const result = await withRetry(
      async () => {
        const response = await processWithLlama(
          text,
          false,
          addLog,
          fullPrompt,
          false
        );

        if (!response?.data) {
          throw ProcessingError.llm('Empty validation response');
        }

        // Transform the raw data into a ValidationResult
        const validation = transformToValidationResult(response.data);

        const requiredFields = [
          'badData',
          'leaseOnly',
          'amendmentOnly',
          'leaseAndAmendment',
          'leaseAndAmendments',
          'reason',
          'documentType'
        ];

        const missingFields = requiredFields.filter(
          field => !(field in validation)
        );

        if (missingFields.length > 0) {
          throw ProcessingError.llm(
            `Invalid validation response. Missing fields: ${missingFields.join(', ')}`
          );
        }

        addLog(`Document validation completed: ${validation.reason}`, 'success');

        // Store validated document
        groupDocs.push({
          name: fileName,
          type: validation.documentType || 'amendment',
          validationResult: validation
        });
        validatedGroups.set(groupId, groupDocs);

        // Check if we have at least one main lease in the group
        const hasMainLease = groupDocs.some(doc => doc.validationResult.documentType === 'lease');
        
        if (!hasMainLease && groupDocs.length === groupDocs.filter(doc => 
          doc.validationResult.documentType === 'amendment'
        ).length) {
          throw ValidationError.amendmentOnly(
            'No main lease document found in the uploaded files. Please include a main lease document.'
          );
        }

        if (validation.badData) {
          throw ValidationError.badData(
            'This upload involves data that is unrelated to a commercial lease agreement. Please check your files and try again.'
          );
        }

        // Validate amendment relationships
        if (validation.documentType === 'amendment' && hasMainLease) {
          const mainLease = groupDocs.find(doc => 
            doc.validationResult.documentType === 'lease'
          )?.validationResult;

          if (mainLease) {
            let matches = 0;
            let totalChecks = 0;

            if (validation.landlord && mainLease.landlord) {
              totalChecks++;
              if (nameMatches(validation.landlord, mainLease.landlord)) {
                matches++;
                addLog('Landlord matches main lease', 'success');
              } else {
                addLog('Warning: Landlord does not match main lease', 'info');
              }
            }

            if (validation.tenant && mainLease.tenant) {
              totalChecks++;
              if (nameMatches(validation.tenant, mainLease.tenant)) {
                matches++;
                addLog('Tenant matches main lease', 'success');
              } else {
                addLog('Warning: Tenant does not match main lease', 'info');
              }
            }

            if (validation.premises && mainLease.premises) {
              totalChecks++;
              if (addressMatches(validation.premises, mainLease.premises)) {
                matches++;
                addLog('Premises matches main lease', 'success');
              } else {
                addLog('Warning: Premises does not match main lease', 'info');
              }
            }

            // Check for explicit references to original lease
            const originalDate = mainLease.effectiveDate;
            if (originalDate) {
              totalChecks++;
              const hasReference = text.toLowerCase().includes(originalDate.toLowerCase());
              if (hasReference) {
                matches++;
                addLog('Document references original lease date', 'success');
              }
            }

            // Only require 1 match if we have at least 2 things to check
            if (totalChecks >= 2 && matches === 0) {
              throw ValidationError.relationship(
                'Amendment does not appear to be related to the main lease. ' +
                'Please verify that the landlord, tenant, and premises information matches.'
              );
            }
          }
        }

        return validation;
      },
      {
        model: 'Llama',
        addLog,
        maxRetries: 2,
        retryDelay: 1000,
        backoffFactor: 2
      }
    );

    return result;
  } catch (error) {
    const errorDetails = formatError(error);
    console.error('Document validation error:', errorDetails);
    
    if (error instanceof ValidationError) {
      throw error;
    }

    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog(`Validation failed: ${errorMessage}`, 'error');
    throw ProcessingError.llm(errorMessage, error as Error);
  }
}

export function clearValidationGroup(groupId: string): void {
  validatedGroups.delete(groupId);
}
