import { createContext, useContext, useEffect, useState } from 'react';
import { User as SupabaseUser, Session } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';

interface User extends SupabaseUser {}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  initialized: boolean;
  requiresTOTP: boolean;
  isAdmin: boolean;
  signInWithAzure: () => Promise<void>;
  signOut: () => Promise<void>;
  verifyTOTP: (token: string, factorId: string) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [requiresTOTP, setRequiresTOTP] = useState(false);
  const [lastCheckedUserId, setLastCheckedUserId] = useState<string | null>(null);

  async function checkUserRole(userId: string) {
    if (lastCheckedUserId === userId) {
      console.log('Skipping duplicate role check for:', userId);
      return;
    }
    
    console.log('Checking user role for:', userId);
    try {
      console.log('Making Supabase query for role...');
      const { data: profileData, error: fetchError } = await supabase
        .from('user_profiles')
        .select('role')
        .eq('id', userId)
        .single();

      console.log('Query result:', { profileData, fetchError });

      if (fetchError) {
        if (fetchError.code === 'PGRST116') {
          console.log('No profile found, using default role');
          setIsAdmin(false);
        } else {
          console.error('Error fetching user profile:', fetchError);
          setIsAdmin(false);
        }
        return;
      }

      console.log('Got user role:', profileData?.role);
      setIsAdmin(profileData?.role === 'admin');
      setLastCheckedUserId(userId);
    } catch (error) {
      console.error('Error in checkUserRole:', error);
      setIsAdmin(false);
    } finally {
      console.log('checkUserRole complete');
    }
  }

  const handleSession = async (session: Session | null) => {
    console.log('handleSession called with session:', session?.user?.id);
    try {
      if (session?.user) {
        console.log('Setting user and initializing...');
        setUser(session.user);
        setInitialized(true);
        setLoading(false);
        
        console.log('Starting background role check...');
        checkUserRole(session.user.id).catch(error => {
          console.error('Background role check failed:', error);
        });
      } else {
        console.log('No session, clearing user...');
        setUser(null);
        setIsAdmin(false);
        setInitialized(true);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error handling session:', error);
      setUser(null);
      setIsAdmin(false);
      setInitialized(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    console.log('Auth effect running...');

    // Get initial session
    const getInitialSession = async () => {
      if (!mounted) return;
      console.log('Getting initial session...');
      
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        console.log('Got session result:', session?.user?.id, error);
        
        if (error || !session) {
          console.log('No session or error, setting initial state');
          if (mounted) {
            setUser(null);
            setIsAdmin(false);
            setInitialized(true);
            setLoading(false);
          }
        } else if (mounted) {
          console.log('Handling initial session');
          await handleSession(session);
        }
      } catch (error) {
        console.error('Error in getInitialSession:', error);
        if (mounted) {
          setUser(null);
          setIsAdmin(false);
          setInitialized(true);
          setLoading(false);
        }
      }
    };

    getInitialSession();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state change:', event, session?.user?.id);
        if (!mounted) return;
        await handleSession(session);
      }
    );

    return () => {
      console.log('Auth effect cleanup');
      mounted = false;
      subscription.unsubscribe();
    };
  }, []);

  async function signInWithAzure() {
    try {
      setLoading(true);
      console.log('Starting Azure sign-in...');
      
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'azure',
        options: {
          skipBrowserRedirect: false,
          scopes: 'email profile openid',
          redirectTo: `${window.location.origin}/auth/oauth`,
          queryParams: {
            response_type: 'code',
            tenant: '4237cdb1-558e-40d3-9a41-29598678012f',
            prompt: 'select_account',
            domain_hint: 'mohrpartners.com',
            nonce: Math.random().toString(36).substring(2)
          }
        }
      });

      if (error) {
        console.error('Azure sign-in error:', error);
        throw error;
      }

      console.log('Sign-in initiated:', data);
      // The browser will be redirected automatically
    } catch (err) {
      console.error('Azure sign-in error:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function signOut() {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      setIsAdmin(false);
      setRequiresTOTP(false);
    } catch (err) {
      console.error('Sign out error:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function verifyTOTP(code: string, factorId: string): Promise<boolean> {
    try {
      setLoading(true);
      const { data: challengeData, error: challengeError } = await supabase.auth.mfa.challenge({
        factorId
      });

      if (challengeError || !challengeData) {
        console.error('Failed to challenge TOTP:', challengeError);
        return false;
      }

      const { data: verifyData, error: verifyError } = await supabase.auth.mfa.verify({
        factorId,
        challengeId: challengeData.id,
        code
      });

      if (verifyError) {
        console.error('Failed to verify TOTP:', verifyError);
        return false;
      }

      setRequiresTOTP(false);
      return true;
    } catch (error) {
      console.error('TOTP verification error:', error);
      return false;
    } finally {
      setLoading(false);
    }
  }

  const value = {
    user,
    isAdmin,
    loading,
    initialized,
    requiresTOTP,
    signInWithAzure,
    signOut,
    verifyTOTP
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
