import { supabase } from '../../supabase';
import { ProcessingError } from '../../llm/processors/errors';
import { reorderVersions } from './reorder';
import type { NewLeaseAbstraction } from '../../../types/database';
import type { ProcessingResult } from '../../llm/types';

interface CreateVersionParams {
  type: 'original' | 'amendment';
  effectiveDate: Date | null;
  documentPath: string;
  changes?: string;
  data: ProcessingResult;
}

interface VersionResult {
  error: string | null;
}

export async function createAbstractionVersion(
  abstractionId: string,
  data: CreateVersionParams
): Promise<VersionResult> {
  try {
    if (!abstractionId) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'Abstraction ID is required'
      }]);
    }

    // Get current session to ensure we're authenticated
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'You must be logged in to create versions'
      }]);
    }

    // First check if user has view_all permissions
    const { data: userProfile, error: profileError } = await supabase
      .from('user_profiles')
      .select('permissions')
      .eq('id', session.user.id)
      .single();

    if (profileError) {
      throw ProcessingError.database('Failed to fetch user permissions', profileError);
    }

    const hasViewAllPermission = userProfile?.permissions?.abstractions?.view_all === true;

    // Get all existing versions including original
    const { data: versions, error: versionsError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .or(`id.eq.${abstractionId},parent_id.eq.${abstractionId}`)
      .order('effective_date', { ascending: true });

    if (versionsError) {
      throw ProcessingError.database('Failed to fetch existing versions', versionsError);
    }

    if (!versions?.length) {
      throw ProcessingError.database(`No versions found for abstraction ID: ${abstractionId}`);
    }

    // Find the original lease
    const originalLease = versions.find(v => v.type === 'original');
    if (!originalLease) {
      throw ProcessingError.database(`Original lease not found for abstraction ID: ${abstractionId}`);
    }

    // Verify user has access to this abstraction if they don't have view_all permission
    if (!hasViewAllPermission && originalLease.user_id !== session.user.id) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'You do not have access to this abstraction'
      }]);
    }

    // Get the most recent version to inherit data from
    const currentVersion = versions.find(v => v.is_current_version) || originalLease;
    const leaseData = data.data.data;

    // Format dates for database
    const effectiveDate = data.effectiveDate ? new Date(data.effectiveDate) : null;
    const commencementDate = leaseData.commencementDate ? new Date(leaseData.commencementDate) : null;
    const originalCommencementDate = leaseData.originalCommencementDate ? new Date(leaseData.originalCommencementDate) : null;
    const expirationDate = leaseData.expirationDate ? new Date(leaseData.expirationDate) : null;
    const rentStartDate = leaseData.rentStartDate ? new Date(leaseData.rentStartDate) : null;

    // Create new version by merging current version data with amendment changes
    const newVersion: NewLeaseAbstraction = {
      parent_id: abstractionId,
      type: data.type,
      version: versions.length + 1, // Will be reordered later
      status: 'draft',
      is_current_version: false, // Will be set to true during reordering if it's the latest
      effective_date: effectiveDate,
      document_path: data.documentPath,
      changes: data.changes,
      confidence: {
        ...currentVersion.confidence,
        ...data.data.confidence
      },
      missing_fields: leaseData.missingFields || currentVersion.missing_fields,
      premises: leaseData.premises || currentVersion.premises,
      county: leaseData.county || currentVersion.county,
      tenant_legal_name: leaseData.tenantLegalName || currentVersion.tenant_legal_name,
      commencement_date: commencementDate,
      original_commencement_date: originalCommencementDate,
      expiration_date: expirationDate,
      rent_start_date: rentStartDate,
      rentable_area: leaseData.rentableArea ?? currentVersion.rentable_area,
      pro_rata: leaseData.proRata ?? currentVersion.pro_rata,
      building_area: leaseData.buildingArea ?? currentVersion.building_area,
      base_rent: leaseData.baseRent ?? currentVersion.base_rent,
      annual_rent: leaseData.annualRent ?? currentVersion.annual_rent,
      security_deposit: leaseData.securityDeposit ?? currentVersion.security_deposit,
      landlord: {
        ...currentVersion.landlord,
        ...leaseData.landlord
      },
      tenant: {
        ...currentVersion.tenant,
        ...leaseData.tenant
      },
      clauses: {
        ...currentVersion.clauses,
        ...leaseData.clauses
      },
      additional_clauses: {
        ...currentVersion.additional_clauses,
        ...leaseData.additionalClauses
      },
      user_id: session.user.id
    };

    // Create the new version
    const { error: createError } = await supabase
      .from('lease_abstractions')
      .insert(newVersion);

    if (createError) {
      console.error('Create version error details:', createError);
      throw ProcessingError.database('Failed to create version', createError);
    }

    try {
      // Update version numbers based on effective dates
      await reorderVersions(abstractionId);
      return { error: null };
    } catch (reorderError) {
      console.error('Failed to reorder versions:', reorderError);
      if (reorderError instanceof ProcessingError) {
        throw reorderError;
      }
      throw ProcessingError.database('Failed to reorder versions after creation', reorderError as Error);
    }

  } catch (error) {
    console.error('Create version error:', error);
    if (error instanceof ProcessingError) {
      return { error: error.message };
    }
    return { 
      error: ProcessingError.database(
        'Failed to create version',
        error as Error
      ).message
    };
  }
}
