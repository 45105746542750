import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormField } from '@/components/ui/FormField';
import { toTitleCase } from '@/lib/utils';
import { MissingToggle } from '@/components/ui/MissingToggle';
import type { FieldStatus } from '../../types/lease';

interface GeneralInformationProps {
  premises?: string;
  county?: string;
  tenantLegalName?: string;
  fieldStatus?: Record<string, FieldStatus>;
  onChange: (field: string, value: string) => void;
  onFieldStatusChange: (field: string, status: FieldStatus) => void;
}

export function GeneralInformation({
  premises = '',
  county = '',
  tenantLegalName = '',
  fieldStatus = {},
  onChange,
  onFieldStatusChange,
}: GeneralInformationProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>General Information</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <FormField
          id="premises"
          label={toTitleCase('premises')}
          value={premises}
          onChange={(value) => onChange('premises', value)}
          placeholder="Enter premises address"
          fieldStatus={fieldStatus['premises'] || 'not_missing'}
          onFieldStatusChange={(status) => onFieldStatusChange('premises', status)}
        />

        <FormField
          id="county"
          label={toTitleCase('county')}
          value={county}
          onChange={(value) => onChange('county', value)}
          placeholder="Enter county"
          fieldStatus={fieldStatus['county'] || 'not_missing'}
          onFieldStatusChange={(status) => onFieldStatusChange('county', status)}
        />

        <FormField
          id="tenantLegalName"
          label={toTitleCase('tenant Legal Name')}
          value={tenantLegalName}
          onChange={(value) => onChange('tenantLegalName', value)}
          placeholder="Enter tenant legal name"
          fieldStatus={fieldStatus['tenantLegalName'] || 'not_missing'}
          onFieldStatusChange={(status) => onFieldStatusChange('tenantLegalName', status)}
        />
      </CardContent>
    </Card>
  );
}