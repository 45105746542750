import { useState } from 'react';
import { format } from 'date-fns';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetDescription,
} from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { History, FileText } from 'lucide-react';
import type { LeaseAbstraction } from '@/types/lease';

interface VersionHistoryProps {
  versions: LeaseAbstraction[];
  currentVersionId: string | null;
  onVersionSelect: (versionId: string) => void;
}

export function VersionHistory({
  versions,
  currentVersionId,
  onVersionSelect
}: VersionHistoryProps) {
  const [isOpen, setIsOpen] = useState(false);

  const getVersionLabel = (version: LeaseAbstraction) => {
    if (version.type === 'original') {
      return 'Original Lease';
    }
    return `Amendment ${version.version - 1}`;
  };

  const getVersionBadge = (version: LeaseAbstraction) => {
    if (version.type === 'original') {
      return <Badge variant="secondary">Original</Badge>;
    }
    return <Badge variant="outline">Amendment</Badge>;
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="outline">
          <History className="mr-2 h-4 w-4" />
          Version History ({versions.length})
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[400px] sm:w-[540px]">
        <SheetHeader>
          <SheetTitle>Version History</SheetTitle>
          <SheetDescription>
            View and compare different versions of the lease abstraction
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="h-[calc(100vh-8rem)] mt-4">
          <div className="space-y-4">
            {versions.map((version) => (
              <div
                key={version.id}
                className={`p-4 rounded-lg border transition-colors ${
                  version.id === currentVersionId ? 'bg-muted' : ''
                }`}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <h3 className="font-medium">{getVersionLabel(version)}</h3>
                      {getVersionBadge(version)}
                      {version.id === currentVersionId && (
                        <Badge variant="default">Current</Badge>
                      )}
                    </div>
                    {version.effectiveDate && (
                      <p className="text-sm text-muted-foreground">
                        Effective: {format(new Date(version.effectiveDate), 'PPP')}
                      </p>
                    )}
                  </div>
                  <Button
                    variant={version.id === currentVersionId ? 'secondary' : 'outline'}
                    size="sm"
                    onClick={() => {
                      onVersionSelect(version.id);
                      setIsOpen(false);
                    }}
                  >
                    {version.id === currentVersionId ? 'Current' : 'View'}
                  </Button>
                </div>

                {version.documentPath && (
                  <div className="flex items-center gap-2 text-sm text-muted-foreground mb-2">
                    <FileText className="h-4 w-4" />
                    <span className="truncate">
                      {version.documentPath.split('/').pop()}
                    </span>
                  </div>
                )}

                {version.changes && (
                  <div className="mt-2 text-sm">
                    <p className="font-medium mb-1">Changes:</p>
                    <p className="text-muted-foreground whitespace-pre-line">
                      {version.changes}
                    </p>
                  </div>
                )}

                <p className="text-xs text-muted-foreground mt-2">
                  Created {format(new Date(version.createdAt), 'PPp')}
                </p>
              </div>
            ))}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}