import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';
import { AlertCircle, CheckCircle, XCircle } from 'lucide-react';
import type { FieldStatus } from '../../types/lease';

interface MissingToggleProps {
  status: FieldStatus;
  onChange: (status: FieldStatus) => void;
}

export function MissingToggle({ status, onChange }: MissingToggleProps) {
  const getIcon = () => {
    switch (status) {
      case 'missing':
        return <AlertCircle className="h-4 w-4 text-yellow-500" />;
      case 'not_missing':
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case 'not_accurate':
        return <XCircle className="h-4 w-4 text-red-500" />;
      default:
        return <AlertCircle className="h-4 w-4 text-yellow-500" />;
    }
  };

  const getLabel = () => {
    switch (status) {
      case 'missing':
        return 'Missing';
      case 'not_missing':
        return 'Found';
      case 'not_accurate':
        return 'Not Accurate';
      default:
        return 'Missing';
    }
  };

  // Ensure we always have a valid status
  const currentStatus = status || 'missing';

  return (
    <Select value={currentStatus} onValueChange={onChange}>
      <SelectTrigger className="w-[140px] h-8">
        <div className="flex items-center gap-2">
          {getIcon()}
          <SelectValue defaultValue="missing">
            {getLabel()}
          </SelectValue>
        </div>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="missing">
          <div className="flex items-center gap-2">
            <AlertCircle className="h-4 w-4 text-yellow-500" />
            Missing
          </div>
        </SelectItem>
        <SelectItem value="not_missing">
          <div className="flex items-center gap-2">
            <CheckCircle className="h-4 w-4 text-green-500" />
            Found
          </div>
        </SelectItem>
        <SelectItem value="not_accurate">
          <div className="flex items-center gap-2">
            <XCircle className="h-4 w-4 text-red-500" />
            Not Accurate
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
