import { supabase } from '../../../lib/supabase';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { validateFile } from '../utils/file-detection';

interface UploadResult {
  path: string;
  error?: string;
}

export async function uploadFile(file: File, userId: string): Promise<UploadResult> {
  try {
    // Validate inputs
    if (!file) {
      throw ProcessingError.validation([{
        field: 'file',
        message: 'File is required'
      }]);
    }
    if (!userId) {
      throw ProcessingError.validation([{
        field: 'userId',
        message: 'User ID is required'
      }]);
    }

    // Validate file type and size
    validateFile(file);

    // Generate unique file path
    const timestamp = Date.now();
    const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9._-]/g, '_');
    const filePath = `${userId}/${timestamp}_${sanitizedFileName}`;
    
    // Upload file to storage
    const { error: uploadError } = await supabase.storage
      .from('lease-documents')
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (uploadError) {
      // Handle specific storage errors
      if (uploadError.message.includes('duplicate')) {
        throw ProcessingError.validation([{
          field: 'file',
          message: 'A file with this name already exists'
        }]);
      }
      if (uploadError.message.includes('size')) {
        throw ProcessingError.validation([{
          field: 'file',
          message: 'File size exceeds the maximum allowed limit'
        }]);
      }
      throw ProcessingError.document(
        'Failed to upload file to storage',
        uploadError
      );
    }

    return { path: filePath };
  } catch (error) {
    console.error('Upload error:', error);
    
    if (error instanceof ProcessingError) {
      return { 
        path: '', 
        error: error.message 
      };
    }

    // Handle unexpected errors
    return { 
      path: '', 
      error: ProcessingError.document(
        'Failed to upload file',
        error as Error
      ).message
    };
  }
}
