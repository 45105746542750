/**
 * Error handler utility to ensure consistent error handling and logging
 */

// Helper function to format error details
function formatError(error: unknown): string {
  if (error instanceof Error) {
    let message = error.message;
    if (error.stack) {
      message += `\nStack trace:\n${error.stack}`;
    }
    if ('cause' in error && error.cause) {
      message += `\nCaused by: ${formatError(error.cause)}`;
    }
    return message;
  }
  return String(error);
}

// Helper function to format timestamp
function getTimestamp(): string {
  return new Date().toLocaleTimeString();
}

/**
 * Log an error to the terminal with full details
 */
export function logError(context: string, error: unknown): void {
  const timestamp = getTimestamp();
  const errorDetails = formatError(error);
  console.error(`[${timestamp}] Error in ${context}:`, errorDetails);

  // If it's an error with additional properties, log them
  if (error instanceof Error) {
    const additionalProps = Object.entries(error)
      .filter(([key]) => !['name', 'message', 'stack'].includes(key));
    
    if (additionalProps.length > 0) {
      console.error(`[${timestamp}] Additional error properties:`, 
        Object.fromEntries(additionalProps)
      );
    }
  }
}

/**
 * Log a warning to the terminal
 */
export function logWarning(context: string, message: string): void {
  const timestamp = getTimestamp();
  console.warn(`[${timestamp}] Warning in ${context}:`, message);
}

/**
 * Log an info message to the terminal
 */
export function logInfo(context: string, message: string): void {
  const timestamp = getTimestamp();
  console.log(`[${timestamp}] ${context}:`, message);
}

/**
 * Log a success message to the terminal
 */
export function logSuccess(context: string, message: string): void {
  const timestamp = getTimestamp();
  console.log(`[${timestamp}] ${context}:`, message);
}

/**
 * Handle an error by logging it and optionally executing a callback
 */
export function handleError(
  context: string,
  error: unknown,
  callback?: (error: Error) => void
): void {
  logError(context, error);
  if (callback && error instanceof Error) {
    callback(error);
  }
}

/**
 * Create an error handler function for a specific context
 */
export function createErrorHandler(context: string) {
  return {
    error: (error: unknown, callback?: (error: Error) => void) => 
      handleError(context, error, callback),
    warning: (message: string) => logWarning(context, message),
    info: (message: string) => logInfo(context, message),
    success: (message: string) => logSuccess(context, message)
  };
}

/**
 * Wrap an async function with error handling
 */
export async function withErrorHandling<T>(
  context: string,
  fn: () => Promise<T>,
  callback?: (error: Error) => void
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    handleError(context, error, callback);
    throw error;
  }
}

/**
 * Create a wrapped version of a function with error handling
 */
export function wrapWithErrorHandling<T extends (...args: any[]) => Promise<any>>(
  context: string,
  fn: T,
  callback?: (error: Error) => void
): T {
  return (async (...args: Parameters<T>) => {
    try {
      return await fn(...args);
    } catch (error) {
      handleError(context, error, callback);
      throw error;
    }
  }) as T;
}
