import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { supabase } from './supabase';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function toTitleCase(str: string): string {
  const spacedStr = str.replace(/([A-Z])/g, ' $1');
  return spacedStr
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .trim();
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function withTimeout<T>(
  promise: Promise<T>,
  timeoutMs: number,
  errorMessage: string
): Promise<T> {
  const timeout = new Promise<never>((_, reject) => {
    setTimeout(() => reject(new Error(errorMessage)), timeoutMs);
  });

  return Promise.race([promise, timeout]);
}

function convertToCamelCase(obj: any): any {
  // Handle null values
  if (obj === null) return null;
  
  // Handle primitives and dates
  if (typeof obj !== 'object' || obj instanceof Date) return obj;
  
  // Handle arrays
  if (Array.isArray(obj)) return obj.map(convertToCamelCase);
  
  const camelCaseObj: any = {};
  
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      
      // Special handling for date fields
      if (['commencement_date', 'original_commencement_date', 'expiration_date', 
           'rent_start_date', 'created_at', 'updated_at'].includes(key)) {
        // Preserve the Date object if it exists
        camelCaseObj[camelKey] = obj[key] instanceof Date ? obj[key] : obj[key];
      } else {
        // Normal conversion for non-date fields
        camelCaseObj[camelKey] = convertToCamelCase(obj[key]);
      }
    }
  }
  
  return camelCaseObj;
}

// Simple camelCase converter that preserves Date objects
function simpleCamelCase(data: any): any {
  if (data === null || data === undefined) return data;
  if (data instanceof Date) return data;
  if (typeof data !== 'object') return data;
  if (Array.isArray(data)) return data.map(simpleCamelCase);

  const result: any = {};
  
  Object.entries(data).forEach(([key, value]) => {
    // Convert key to camelCase
    const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    
    // Recursively convert nested objects
    result[camelKey] = simpleCamelCase(value);
  });
  
  return result;
}

export async function loadAbstraction(id: string) {
  try {
    const { data, error } = await supabase
      .from('abstractions')
      .select()
      .eq('id', id)
      .maybeSingle();

    if (error) throw error;
    if (!data) return null;

    // First handle the dates
    const withDates = {
      ...data,
      commencementDate: data.commencement_date ? new Date(data.commencement_date) : null,
      originalCommencementDate: data.original_commencement_date ? new Date(data.original_commencement_date) : null,
      expirationDate: data.expiration_date ? new Date(data.expiration_date) : null,
      rentStartDate: data.rent_start_date ? new Date(data.rent_start_date) : null,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      
      // Remove original date fields
      commencement_date: undefined,
      original_commencement_date: undefined,
      expiration_date: undefined,
      rent_start_date: undefined,
      created_at: undefined,
      updated_at: undefined
    };

    // Then convert everything to camelCase
    const formattedData = simpleCamelCase(withDates);

    // Debug log
    console.log('Final formatted data:', {
      dates: {
        commencementDate: formattedData.commencementDate,
        originalCommencementDate: formattedData.originalCommencementDate,
        expirationDate: formattedData.expirationDate,
        rentStartDate: formattedData.rentStartDate
      },
      dateTypes: {
        commencementDate: formattedData.commencementDate instanceof Date,
        originalCommencementDate: formattedData.originalCommencementDate instanceof Date,
        expirationDate: formattedData.expirationDate instanceof Date,
        rentStartDate: formattedData.rentStartDate instanceof Date
      }
    });

    return formattedData;
  } catch (error) {
    console.error('Load abstraction error:', error);
    return null;
  }
}
