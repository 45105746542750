import type { LeaseAbstraction } from '@/types/lease';
import { formatDateForDB } from '@/lib/dates';

interface TrainingExample {
  messages: {
    role: 'system' | 'user' | 'assistant';
    content: string | object;
  }[];
}

export function generateTrainingJSONL(abstractions: Partial<LeaseAbstraction>[]): string {
  return abstractions
    .map(abstraction => {
      const example: TrainingExample = {
        messages: [
          {
            role: 'system',
            content: 'You are a lease abstraction expert. Analyze the lease document and extract key information in a structured format.'
          },
          {
            role: 'user',
            content: abstraction.documentContent || 'Document content not available'
          },
          {
            role: 'assistant',
            content: {
              premises: abstraction.premises,
              county: abstraction.county,
              tenantLegalName: abstraction.tenantLegalName,
              commencementDate: formatDateForDB(abstraction.commencementDate),
              originalCommencementDate: formatDateForDB(abstraction.originalCommencementDate),
              expirationDate: formatDateForDB(abstraction.expirationDate),
              rentStartDate: formatDateForDB(abstraction.rentStartDate),
              rentableArea: abstraction.rentableArea,
              proRata: abstraction.proRata,
              buildingArea: abstraction.buildingArea,
              baseRent: abstraction.baseRent,
              annualRent: abstraction.annualRent,
              securityDeposit: abstraction.securityDeposit,
              landlord: abstraction.landlord,
              tenant: abstraction.tenant,
              clauses: abstraction.clauses,
              additionalClauses: abstraction.additionalClauses
            }
          }
        ]
      };
      return JSON.stringify(example);
    })
    .join('\n');
}

export function downloadJSONL(content: string, filename: string) {
  const blob = new Blob([content], { type: 'application/jsonl' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function exportTrainingData(abstractions: LeaseAbstraction[]) {
  // Split into training (80%) and validation (20%) sets
  const splitIndex = Math.floor(abstractions.length * 0.8);
  const trainingDocs = abstractions.slice(0, splitIndex);
  const validationDocs = abstractions.slice(splitIndex);

  // Generate and download training file
  const trainingJSONL = generateTrainingJSONL(trainingDocs);
  downloadJSONL(trainingJSONL, 'training.jsonl');

  // Generate and download validation file
  const validationJSONL = generateTrainingJSONL(validationDocs);
  downloadJSONL(validationJSONL, 'validation.jsonl');

  return {
    trainingCount: trainingDocs.length,
    validationCount: validationDocs.length
  };
}