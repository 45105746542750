import { formatDateForDB } from '../../dates';

const DATE_FIELDS = [
  'commencement_date',
  'original_commencement_date',
  'expiration_date',
  'rent_start_date',
  'effective_date',
  'created_at',
  'updated_at'
];

export function convertToCamelCase(obj: any): any {
  if (obj === null || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map(convertToCamelCase);

  const camelObj: any = {};
  
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      
      // Special handling for date fields
      if (DATE_FIELDS.includes(key)) {
        camelObj[camelKey] = obj[key] ? new Date(obj[key]) : null;
      } else {
        camelObj[camelKey] = convertToCamelCase(obj[key]);
      }
    }
  }
  
  return camelObj;
}

export function convertToSnakeCase(obj: any): any {
  if (obj === null || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map(convertToSnakeCase);
  if (obj instanceof Date) return formatDateForDB(obj);
  
  const snakeObj: any = {};
  
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      
      // Special handling for date fields
      const isDateField = DATE_FIELDS.includes(snakeKey);
      if (isDateField && obj[key]) {
        snakeObj[snakeKey] = formatDateForDB(obj[key]);
      } else {
        snakeObj[snakeKey] = convertToSnakeCase(obj[key]);
      }
    }
  }
  
  return snakeObj;
}
