import { ProcessingError } from '../../../lib/llm/processors/errors';
import { createAbstractionVersion, getAbstractionVersions } from '../../../lib/db/versions';
import { uploadFile } from './upload-service';
import { trackDocument, checkDocumentExists, getDocumentAbstraction } from '../../../lib/db/documents/tracking';
import { extractTextFromDocument } from '../../../lib/llm/processors/text-extraction';
import { processDocumentWithDualLLM } from '../../../lib/llm/processors/process-dual-llm';
import type { LoggerFunction } from '../types';

interface ProcessAmendmentParams {
  file: File;
  userId: string;
  abstractionId: string;
  addLog: LoggerFunction;
}

export async function processAmendment({
  file,
  userId,
  abstractionId,
  addLog
}: ProcessAmendmentParams): Promise<{ existingAbstractionId?: string }> {
  try {
    // Check if amendment already exists
    const { exists, documentId: existingDocId, error: checkError } = await checkDocumentExists(file, userId);
    if (checkError) {
      throw ProcessingError.database(`Failed to check document: ${checkError}`);
    }

    // If document exists, check if it's already associated with this abstraction
    if (exists && existingDocId) {
      const { abstractionId: existingAbstractionId, error: getError } = await getDocumentAbstraction(existingDocId);
      if (getError) {
        throw ProcessingError.database(`Failed to get document abstraction: ${getError}`);
      }

      // If already associated with this abstraction, return early
      if (existingAbstractionId === abstractionId) {
        addLog('This amendment has already been processed for this lease', 'info');
        return { existingAbstractionId };
      }

      // If associated with a different abstraction, notify user
      addLog('This amendment is already associated with another lease', 'info');
      addLog('You will be redirected to that lease abstraction in a moment...', 'info');
      return { existingAbstractionId };
    }

    // Get existing versions to check ordering
    const versions = await getAbstractionVersions(abstractionId);

    // Upload amendment
    addLog('Uploading amendment...');
    const { path: filePath, error: uploadError } = await uploadFile(file, userId);
    if (uploadError) {
      throw ProcessingError.document(`Failed to upload amendment: ${uploadError}`);
    }

    // Track the document
    addLog('Tracking document...');
    const { documentId, error: trackError } = await trackDocument(file, userId, abstractionId);
    if (trackError) {
      throw ProcessingError.database(`Failed to track document: ${trackError}`);
    }

    // Extract text from document
    addLog(`Processing ${file.name}...`);
    addLog(`Extracting text from ${file.name}...`);
    let text;
    try {
      text = await extractTextFromDocument(file);
      if (!text || !text.trim()) {
        throw ProcessingError.document('No text content extracted from document');
      }
      addLog('Text extraction completed successfully');
    } catch (extractError) {
      if (extractError instanceof ProcessingError) {
        throw extractError;
      }
      throw ProcessingError.document(
        `Text extraction failed: ${extractError instanceof Error ? extractError.message : 'Unknown error'}`,
        extractError as Error
      );
    }

    // Process amendment with LLM
    addLog('Starting dual LLM processing...');
    let amendmentData;
    try {
      amendmentData = await processDocumentWithDualLLM(text, addLog, {
        isAmendment: true,
        abstractionId,
        confidenceThreshold: 0.7,
        userId
      });
      
      if (!amendmentData) {
        throw ProcessingError.llm('No data returned from LLM processing');
      }
      if (!amendmentData.data) {
        throw ProcessingError.llm('No lease data extracted from document');
      }
      addLog('LLM processing completed successfully');
    } catch (llmError) {
      if (llmError instanceof ProcessingError) {
        throw llmError;
      }
      throw ProcessingError.llm(
        `LLM processing failed: ${llmError instanceof Error ? llmError.message : 'Unknown error'}`,
        llmError as Error
      );
    }

    // Validate amendment date ordering
    if (versions && versions.length > 0) {
      const amendmentDate = new Date(amendmentData.effectiveDate);
      const lastVersion = versions[versions.length - 1];
      
      if (lastVersion.effectiveDate && amendmentDate < new Date(lastVersion.effectiveDate)) {
        throw ProcessingError.validation([{
          field: 'effectiveDate',
          message: `Amendment effective date (${amendmentDate.toLocaleDateString()}) is earlier than the last version's date (${new Date(lastVersion.effectiveDate).toLocaleDateString()}). Please ensure amendments are uploaded in chronological order.`
        }]);
      }
    }

    // Format changes for version creation
    const formattedChanges = amendmentData.changes 
      ? (typeof amendmentData.changes === 'string' 
          ? amendmentData.changes 
          : Array.isArray(amendmentData.changes)
            ? amendmentData.changes.map(c => c.description).join('\n')
            : undefined)
      : undefined;

    // Create amendment version
    addLog('Creating amendment version...');
    const { error: versionError } = await createAbstractionVersion(abstractionId, {
      type: 'amendment',
      effectiveDate: amendmentData.effectiveDate,
      documentPath: filePath,
      changes: formattedChanges,
      data: amendmentData.data
    });

    if (versionError) {
      throw ProcessingError.database(`Failed to create amendment version: ${versionError}`);
    }

    addLog('Amendment processed successfully', 'success');
    return {};
  } catch (error) {
    const errorMessage = error instanceof ProcessingError 
      ? error.message 
      : error instanceof Error 
        ? error.message 
        : 'Unknown error';
    
    addLog(`Amendment processing failed: ${errorMessage}`, 'error');
    throw error;
  }
} 