import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Alert, AlertDescription } from './ui/alert';

export default function AdminRoute() {
  const { user, isAdmin } = useAuth();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!isAdmin) {
    return (
      <>
        <div className="p-4">
          <Alert variant="destructive">
            <AlertDescription>
              You do not have permission to access this page
            </AlertDescription>
          </Alert>
        </div>
        <Navigate to="/" replace />
      </>
    );
  }

  return <Outlet />;
}
