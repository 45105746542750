import { supabase } from '../supabase';

export async function getModelVersions() {
  try {
    const { data, error } = await supabase
      .from('model_versions')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;

    return { data, error: null };
  } catch (error) {
    console.error('Get model versions error:', error);
    return { 
      data: null, 
      error: error instanceof Error ? error.message : 'Failed to get model versions'
    };
  }
}

export async function createModelVersion(data: {
  version: string;
  status: 'training' | 'active' | 'archived';
  metrics?: Record<string, any>;
  training_data_count: number;
}) {
  try {
    const { error } = await supabase
      .from('model_versions')
      .insert({
        ...data,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });

    if (error) throw error;

    return { error: null };
  } catch (error) {
    console.error('Create model version error:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to create model version'
    };
  }
}

export async function updateModelVersion(
  id: string,
  data: {
    status?: 'training' | 'active' | 'archived';
    metrics?: Record<string, any>;
  }
) {
  try {
    const { error } = await supabase
      .from('model_versions')
      .update({
        ...data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;

    return { error: null };
  } catch (error) {
    console.error('Update model version error:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to update model version'
    };
  }
}