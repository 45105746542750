import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import UserManagement from '../components/admin/UserManagement';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/ui/card';
import { Users, Settings, Shield, Database, Loader2, FileText } from 'lucide-react';
import { Alert, AlertDescription } from '../components/ui/alert';
import { ProcessLogger } from '../lib/process-logger';
import { ProcessLogsModal } from '../components/admin/ProcessLogsModal';
import { supabase } from '../lib/supabase';
import { Button } from '../components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/ui/table';

interface ProcessGroup {
  processGroupId: string;
  userId: string;
  userEmail?: string;
  abstractionId?: string;
  createdAt: string;
  documentCount: number;
  hasErrors: boolean;
}

type SupabaseLog = {
  id: string;
  process_group_id: string;
  user_id: string;
  abstraction_id: string | null;
  created_at: string;
  type: 'info' | 'error' | 'success';
  user_email?: string;
};

export default function Admin() {
  const navigate = useNavigate();
  const { user, isAdmin, loading } = useAuth();
  const [activeTab, setActiveTab] = useState('users');
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedLogs, setSelectedLogs] = useState<any[]>([]);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [processGroups, setProcessGroups] = useState<ProcessGroup[]>([]);

  useEffect(() => {
    // Only start checking after auth is loaded
    if (!loading) {
      if (!user || !isAdmin) {
        navigate('/');
      } else {
        setPageLoading(false);
      }
    }
  }, [user, isAdmin, loading, navigate]);

  useEffect(() => {
    if (activeTab === 'logs') {
      fetchProcessGroups();
    }
  }, [activeTab]);

  async function fetchProcessGroups() {
    try {
      // First get the logs
      const { data: logs, error } = await supabase
        .from('process_logs')
        .select(`
          id,
          process_group_id,
          user_id,
          abstraction_id,
          created_at,
          type
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Then get the user emails
      const userIds = [...new Set(logs?.map(log => log.user_id) || [])];
      const { data: userProfiles } = await supabase
        .from('user_profiles')
        .select('id, email')
        .in('id', userIds);

      // Create a map of user IDs to emails
      const userEmailMap = new Map(userProfiles?.map(profile => [profile.id, profile.email]));

      // Combine logs with user emails
      const logsWithEmails = logs?.map(log => ({
        ...log,
        user_email: userEmailMap.get(log.user_id)
      })) as SupabaseLog[];

      // Group logs by process_group_id
      const groupedLogs = logsWithEmails.reduce((acc: { [key: string]: ProcessGroup }, log) => {
        if (!acc[log.process_group_id]) {
          acc[log.process_group_id] = {
            processGroupId: log.process_group_id,
            userId: log.user_id,
            userEmail: log.user_email,
            abstractionId: log.abstraction_id || undefined,
            createdAt: log.created_at,
            documentCount: 0,
            hasErrors: false
          };
        }
        acc[log.process_group_id].documentCount++;
        if (log.type === 'error') {
          acc[log.process_group_id].hasErrors = true;
        }
        return acc;
      }, {});

      setProcessGroups(Object.values(groupedLogs));
    } catch (error) {
      console.error('Error fetching process groups:', error);
    }
  }

  async function viewProcessLogs(processGroupId: string) {
    try {
      const logger = new ProcessLogger(user?.id || '');
      const logs = await logger.getProcessGroupLogs();
      setSelectedLogs(logs);
      setIsLogsModalOpen(true);
    } catch (error) {
      console.error('Error fetching process logs:', error);
    }
  }

  // Show loading spinner while auth is loading
  if (loading || pageLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  // This shouldn't normally be reached due to navigation,
  // but keep it as a fallback
  if (!isAdmin) {
    return (
      <div className="p-4">
        <Alert variant="destructive">
          <AlertDescription>
            You do not have permission to access this page
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Admin Dashboard</h1>
          <p className="text-muted-foreground">
            Manage users, roles, and system settings
          </p>
        </div>
      </div>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
        <TabsList>
          <TabsTrigger value="users" className="flex items-center gap-2">
            <Users className="h-4 w-4" />
            Users & Roles
          </TabsTrigger>
          <TabsTrigger value="logs" className="flex items-center gap-2">
            <FileText className="h-4 w-4" />
            Process Logs
          </TabsTrigger>
          <TabsTrigger value="permissions" className="flex items-center gap-2">
            <Shield className="h-4 w-4" />
            Permissions
          </TabsTrigger>
          <TabsTrigger value="system" className="flex items-center gap-2">
            <Settings className="h-4 w-4" />
            System Settings
          </TabsTrigger>
          <TabsTrigger value="database" className="flex items-center gap-2">
            <Database className="h-4 w-4" />
            Database
          </TabsTrigger>  
        </TabsList>

        <TabsContent value="users">
          <UserManagement />
        </TabsContent>

        <TabsContent value="permissions">
          <Card>
            <CardHeader>
              <CardTitle>Access Control</CardTitle>
              <CardDescription>
                Configure role-based permissions and access rules
              </CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground">Permission management coming soon...</p>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="system">
          <Card>
            <CardHeader>
              <CardTitle>System Configuration</CardTitle>
              <CardDescription>
                Manage system-wide settings and configurations
              </CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground">System settings coming soon...</p>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="database">
          <Card>
            <CardHeader>
              <CardTitle>Database Management</CardTitle>
              <CardDescription>
                Monitor and manage database operations
              </CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground">Database management coming soon...</p>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="logs">
          <Card>
            <CardHeader>
              <CardTitle>Process Logs</CardTitle>
              <CardDescription>
                View processing logs for lease abstractions
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Date</TableHead>
                      <TableHead>User</TableHead>
                      <TableHead>Documents</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {processGroups.map((group) => (
                      <TableRow key={group.processGroupId}>
                        <TableCell>
                          {new Date(group.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell>{group.userEmail || 'Unknown'}</TableCell>
                        <TableCell>{group.documentCount}</TableCell>
                        <TableCell>
                          <span
                            className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                              group.hasErrors
                                ? 'bg-red-100 text-red-700'
                                : 'bg-green-100 text-green-700'
                            }`}
                          >
                            {group.hasErrors ? 'Has Errors' : 'Success'}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => viewProcessLogs(group.processGroupId)}
                          >
                            View Logs
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <ProcessLogsModal
        open={isLogsModalOpen}
        onOpenChange={setIsLogsModalOpen}
        logs={selectedLogs}
      />
    </div>
  );
}
