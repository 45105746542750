import type { LeaseAbstraction } from '../../../types/lease';

interface Change {
  field: string;
  oldValue: any;
  newValue: any;
  description: string;
}

interface ClauseMap {
  [key: string]: string;
}

interface ContactInfo {
  name: string;
  address: string;
  phone: string;
  email: string;
}

function compareValues(oldValue: any, newValue: any): boolean {
  if (oldValue === newValue) return false;
  if (!oldValue && !newValue) return false;
  if (typeof oldValue !== typeof newValue) return true;
  
  if (oldValue instanceof Date && newValue instanceof Date) {
    return oldValue.getTime() !== newValue.getTime();
  }
  
  if (typeof oldValue === 'object') {
    if (Array.isArray(oldValue) !== Array.isArray(newValue)) return true;
    if (Array.isArray(oldValue)) {
      return JSON.stringify(oldValue) !== JSON.stringify(newValue);
    }
    const oldKeys = Object.keys(oldValue || {});
    const newKeys = Object.keys(newValue || {});
    if (oldKeys.length !== newKeys.length) return true;
    return oldKeys.some(key => compareValues(oldValue[key], newValue[key]));
  }
  
  return oldValue !== newValue;
}

function formatValue(value: any): string {
  if (value === null || value === undefined) return 'not specified';
  if (value instanceof Date) return value.toLocaleDateString();
  
  if (typeof value === 'number') {
    // Format currency values
    if (['baseRent', 'annualRent', 'securityDeposit'].some(field => field.toLowerCase().includes(String(value)))) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }
    // Format percentage values
    if (['proRata'].some(field => field.toLowerCase().includes(String(value)))) {
      return `${value}%`;
    }
    // Format area values
    if (['rentableArea', 'buildingArea'].some(field => field.toLowerCase().includes(String(value)))) {
      return `${value.toLocaleString()} sq ft`;
    }
    return value.toLocaleString();
  }
  
  if (typeof value === 'object') {
    if (Object.keys(value).length === 0) return 'not specified';
    return JSON.stringify(value);
  }
  
  return String(value);
}

function generateDescription(field: string, oldValue: any, newValue: any): string {
  const formattedOld = formatValue(oldValue);
  const formattedNew = formatValue(newValue);
  
  // Format field name for display
  const displayField = field
    .replace(/([A-Z])/g, ' $1') // Add spaces before capital letters
    .toLowerCase()
    .replace(/\b\w/g, c => c.toUpperCase()); // Capitalize first letter of each word
  
  // Handle specific field types
  if (field.toLowerCase().includes('date')) {
    return `${displayField} changed from ${formattedOld} to ${formattedNew}`;
  }
  
  if (field.toLowerCase().includes('rent')) {
    return `${displayField} modified from ${formattedOld} to ${formattedNew}`;
  }
  
  if (field.toLowerCase().includes('area')) {
    return `${displayField} adjusted from ${formattedOld} to ${formattedNew}`;
  }
  
  if (field.toLowerCase().includes('prorata')) {
    return `Pro Rata Share updated from ${formattedOld} to ${formattedNew}`;
  }
  
  if (typeof oldValue === 'object' && typeof newValue === 'object') {
    const changedFields = Object.keys(newValue).filter(key => 
      oldValue && oldValue[key] !== newValue[key]
    );
    if (changedFields.length > 0) {
      return `Updated ${displayField} information (changed fields: ${changedFields.join(', ')})`;
    }
    return `Updated ${displayField} information`;
  }
  
  return `${displayField} changed from ${formattedOld} to ${formattedNew}`;
}

function compareContactInfo(oldContact: ContactInfo | undefined, newContact: ContactInfo | undefined): string[] {
  if (!oldContact || !newContact) return [];
  
  const changedFields: string[] = [];
  if (oldContact.name !== newContact.name) changedFields.push('name');
  if (oldContact.address !== newContact.address) changedFields.push('address');
  if (oldContact.phone !== newContact.phone) changedFields.push('phone');
  if (oldContact.email !== newContact.email) changedFields.push('email');
  
  return changedFields;
}

export function detectChanges(amendmentData: Partial<LeaseAbstraction>, previousVersion: Partial<LeaseAbstraction>): Change[] {
  const changes: Change[] = [];
  
  // Core fields to check
  const fieldsToCheck = [
    'premises',
    'county',
    'tenantLegalName',
    'commencementDate',
    'originalCommencementDate',
    'expirationDate',
    'rentStartDate',
    'rentableArea',
    'proRata',
    'buildingArea',
    'baseRent',
    'annualRent',
    'securityDeposit'
  ];

  // Check core fields
  for (const field of fieldsToCheck) {
    const oldValue = previousVersion[field as keyof LeaseAbstraction];
    const newValue = amendmentData[field as keyof LeaseAbstraction];
    
    if (compareValues(oldValue, newValue)) {
      changes.push({
        field,
        oldValue,
        newValue,
        description: generateDescription(field, oldValue, newValue)
      });
    }
  }

  // Check contact information with type-safe comparison
  ['landlord', 'tenant'].forEach(party => {
    const oldContact = previousVersion[party as keyof LeaseAbstraction] as ContactInfo | undefined;
    const newContact = amendmentData[party as keyof LeaseAbstraction] as ContactInfo | undefined;
    
    if (compareValues(oldContact, newContact)) {
      const changedFields = compareContactInfo(oldContact, newContact);
      
      changes.push({
        field: party,
        oldValue: oldContact,
        newValue: newContact,
        description: changedFields.length > 0
          ? `Updated ${party} information (modified: ${changedFields.join(', ')})`
          : `Updated ${party} information`
      });
    }
  });

  // Check clauses with improved descriptions
  const oldClauses = (previousVersion.clauses || {}) as ClauseMap;
  const newClauses = (amendmentData.clauses || {}) as ClauseMap;
  
  Object.keys({ ...oldClauses, ...newClauses }).forEach(clause => {
    if (compareValues(oldClauses[clause], newClauses[clause])) {
      const displayClause = clause
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace(/\b\w/g, c => c.toUpperCase());
      
      changes.push({
        field: `clauses.${clause}`,
        oldValue: oldClauses[clause],
        newValue: newClauses[clause],
        description: `Modified ${displayClause} clause`
      });
    }
  });

  // Check additional clauses with improved descriptions
  const oldAdditional = (previousVersion.additionalClauses || {}) as ClauseMap;
  const newAdditional = (amendmentData.additionalClauses || {}) as ClauseMap;
  
  Object.keys({ ...oldAdditional, ...newAdditional }).forEach(clause => {
    if (compareValues(oldAdditional[clause], newAdditional[clause])) {
      const displayClause = clause
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace(/\b\w/g, c => c.toUpperCase());
      
      changes.push({
        field: `additionalClauses.${clause}`,
        oldValue: oldAdditional[clause],
        newValue: newAdditional[clause],
        description: `Modified ${displayClause} clause`
      });
    }
  });

  return changes;
}
