import { useCallback } from 'react';
import { toast } from 'sonner';
import { getAbstractionWithVersions } from '../../lib/db/abstractions/with-versions';
import type { LeaseAbstraction } from '../../types/lease';

interface AbstractionLoadProps {
  id: string;
  setAbstraction: (abstraction: LeaseAbstraction | null) => void;
  setOriginalAbstraction: (abstraction: Partial<LeaseAbstraction> | null) => void;
  setVersions: (versions: LeaseAbstraction[]) => void;
  setCurrentVersionId: (id: string | null) => void;
  setLoading: (loading: boolean) => void;
}

export function useAbstractionLoad({
  id,
  setAbstraction,
  setOriginalAbstraction,
  setVersions,
  setCurrentVersionId,
  setLoading
}: AbstractionLoadProps) {
  const loadAbstraction = useCallback(async () => {
    if (!id) return;
    
    try {
      const { data, error } = await getAbstractionWithVersions(id);
      
      if (error) {
        throw new Error(error);
      }
      
      if (data) {
        // Store original AI extraction when first loading
        if (data.status === 'draft') {
          setOriginalAbstraction(data);
        }
        
        setAbstraction(data);
        setVersions(data.versions || []);

        // Find the current version (either marked as current or latest)
        const currentVersion = data.versions.find(v => v.isCurrentVersion) || 
                             data.versions[data.versions.length - 1];
        
        // Set the current version ID
        if (currentVersion) {
          setCurrentVersionId(currentVersion.id);
        } else {
          // Fallback to the original version if no current version found
          setCurrentVersionId(data.id);
        }
      }
    } catch (error) {
      console.error('Error loading abstraction:', error);
      toast.error('Failed to load abstraction');
    } finally {
      setLoading(false);
    }
  }, [id, setAbstraction, setOriginalAbstraction, setVersions, setCurrentVersionId, setLoading]);

  return { loadAbstraction };
}
