import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { FormField } from '../../components/ui/FormField';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';
import { toTitleCase } from '../../lib/utils';
import type { FieldStatus } from '../../types/lease';

interface Contact {
  name: string;
  address: string;
  phone: string;
  email: string;
}

interface ContactInformationProps {
  landlord: Contact;
  tenant: Contact;
  fieldStatus?: Record<string, FieldStatus>;
  onChange: (type: 'landlord' | 'tenant', field: keyof Contact, value: string) => void;
  onFieldStatusChange: (field: string, status: FieldStatus) => void;
}

export function ContactInformation({
  landlord,
  tenant,
  fieldStatus = {},
  onChange,
  onFieldStatusChange,
}: ContactInformationProps) {
  const ContactForm = ({ type, data }: { type: 'landlord' | 'tenant'; data: Contact }) => (
    <div className="space-y-4">
      {Object.entries(data).map(([field, value]) => {
        const fieldPath = `${type}.${field}`;
        const isPhoneField = field === 'phone';
        const isEmailField = field === 'email';
        
        // Determine initial field status
        let initialStatus: FieldStatus = fieldStatus[fieldPath] || 'not_missing';
        if (isPhoneField && (!value || value === 'Enter phone')) {
          initialStatus = 'missing';
        }
        if (isEmailField && (!value || value === 'Enter email')) {
          initialStatus = 'missing';
        }

        // Ensure value is never null
        const safeValue = value || '';

        return (
          <FormField
            key={field}
            id={`${type}-${field}`}
            label={toTitleCase(field)}
            value={safeValue}
            onChange={(newValue) => onChange(type, field as keyof Contact, newValue)}
            type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
            placeholder={`Enter ${field.toLowerCase()}`}
            fieldStatus={initialStatus}
            onFieldStatusChange={(status) => onFieldStatusChange(fieldPath, status)}
          />
        );
      })}
    </div>
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Contact Information</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="landlord">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="landlord">Landlord</TabsTrigger>
            <TabsTrigger value="tenant">Tenant</TabsTrigger>
          </TabsList>
          <TabsContent value="landlord" className="mt-4">
            <ContactForm type="landlord" data={landlord} />
          </TabsContent>
          <TabsContent value="tenant" className="mt-4">
            <ContactForm type="tenant" data={tenant} />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}
