import { uploadFile } from './upload-service';
import { processMainLease, processAmendment } from './lease-service';
import { checkDocumentExists } from '../../../lib/db/documents/tracking';
import { groupRelatedFiles } from '../utils/file-grouping';
import { validateFileTypes } from '../utils/file-detection';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { validateDocument } from '../../../lib/llm/processors/document-validator';
import { extractTextFromDocument } from '../../../lib/llm/processors/text-extraction';
import { createErrorHandler, withErrorHandling } from '../../../lib/utils/error-handler';
import { ProcessLogger } from '../../../lib/process-logger';
import type { LoggerFunction } from '../types';

interface ProcessFilesResult {
  abstractionId?: string;
  error?: string;
}

interface ValidationContext {
  mainLease: {
    date?: string;
    landlord?: string;
    tenant?: string;
    premises?: string;
  };
  amendments: Array<{
    date?: string;
    landlord?: string;
    tenant?: string;
    premises?: string;
  }>;
}

// Create error handler for this service
const errorHandler = createErrorHandler('FileProcessingService');

/**
 * Validates a group of documents and identifies the main lease and amendments
 */
async function validateDocumentGroup(
  files: File[],
  groupId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger
): Promise<{ mainLeaseIndex: number; context: ValidationContext }> {
  return withErrorHandling('validateDocumentGroup', async () => {
    const context: ValidationContext = {
      mainLease: {},
      amendments: []
    };

    let mainLeaseIndex = -1;
    const validations = [];

    // First validate all documents to identify the main lease
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const logMessage = `Validating document ${i + 1}/${files.length}: ${file.name}...`;
      addLog(logMessage);
      await processLogger.log(logMessage);
      
      const text = await extractTextFromDocument(file);
      if (!text?.trim()) {
        const errorMsg = `No text content extracted from document: ${file.name}`;
        await processLogger.log(errorMsg, 'error');
        throw ProcessingError.document(errorMsg);
      }

      try {
        const validation = await validateDocument(text, file.name, groupId, addLog);
        validations.push(validation);

        if (validation.documentType === 'lease') {
          if (mainLeaseIndex !== -1) {
            const errorMsg = 'Multiple main lease documents detected. Please upload only one main lease.';
            await processLogger.log(errorMsg, 'error');
            throw ProcessingError.document(errorMsg);
          }
          mainLeaseIndex = i;
          context.mainLease = {
            date: validation.effectiveDate,
            landlord: validation.landlord,
            tenant: validation.tenant,
            premises: validation.premises
          };
        }

        const successMsg = `Document ${i + 1} validation passed`;
        addLog(successMsg, 'success');
        await processLogger.log(successMsg, 'success');
      } catch (error) {
        errorHandler.error(error);
        throw error;
      }
    }

    if (mainLeaseIndex === -1) {
      const errorMsg = 'No main lease document found in the uploaded files. Please include a main lease document.';
      await processLogger.log(errorMsg, 'error');
      throw ProcessingError.document(errorMsg);
    }

    // Store amendment validations (excluding the main lease)
    validations.forEach((validation, i) => {
      if (i !== mainLeaseIndex) {
        context.amendments.push({
          date: validation.effectiveDate,
          landlord: validation.landlord,
          tenant: validation.tenant,
          premises: validation.premises
        });
      }
    });

    const successMsg = 'All documents validated successfully';
    addLog(successMsg, 'success');
    await processLogger.log(successMsg, 'success');

    return { mainLeaseIndex, context };
  });
}

export async function processFiles(
  files: File[],
  userId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger
): Promise<ProcessFilesResult> {
  return withErrorHandling('processFiles', async () => {
    try {
      // Validate inputs
      if (!files.length) {
        throw ProcessingError.validation([{
          field: 'files',
          message: 'No files selected'
        }]);
      }

      if (!userId) {
        throw ProcessingError.validation([{
          field: 'userId',
          message: 'User ID is required'
        }]);
      }

      // Validate file types
      const invalidFiles = validateFileTypes(files);
      if (invalidFiles.length > 0) {
        const errorMsg = `Invalid files:\n${invalidFiles.map(error => 
          `${error.file.name}: ${error.reason}`
        ).join('\n')}`;
        await processLogger.log(errorMsg, 'error');
        throw ProcessingError.validation([{
          field: 'files',
          message: errorMsg
        }]);
      }

      // Group related files (lease and its amendments)
      let fileGroups;
      try {
        fileGroups = groupRelatedFiles(files);
        const successMsg = 'Files grouped successfully';
        addLog(successMsg);
        await processLogger.log(successMsg, 'success');
      } catch (error) {
        errorHandler.error(error);
        if (error instanceof ProcessingError) {
          throw error;
        }
        throw ProcessingError.document(
          'Failed to group related files',
          error as Error
        );
      }

      let successfulAbstractions = 0;
      let lastAbstractionId: string | undefined;

      // Process validated groups
      for (const [groupIndex, group] of fileGroups.entries()) {
        const groupId = `group_${groupIndex}_${Date.now()}`;
        let validationResult;
        try {
          // Validate all documents in the group and identify the main lease
          validationResult = await validateDocumentGroup(group, groupId, addLog, processLogger);
        } catch (error) {
          throw error;
        }

        const groupMsg = `Processing lease group ${groupIndex + 1}...`;
        addLog(groupMsg);
        await processLogger.log(groupMsg);
        
        try {
          // Reorder files to ensure main lease is first
          const mainLease = group[validationResult.mainLeaseIndex];
          const amendments = group.filter((_, i) => i !== validationResult.mainLeaseIndex);
          
          // Sort amendments by date if available
          const sortedAmendments = amendments.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            const aMatch = aName.match(/(\d{4}[-_]\d{2}[-_]\d{2})/);
            const bMatch = bName.match(/(\d{4}[-_]\d{2}[-_]\d{2})/);
            if (aMatch && bMatch) {
              return aMatch[1].localeCompare(bMatch[1]);
            }
            return 0;
          });

          // Process main lease
          const mainLeaseMsg = `Processing main lease: ${mainLease.name}...`;
          addLog(mainLeaseMsg);
          await processLogger.log(mainLeaseMsg);

          // Check if main lease already exists
          const checkMsg = 'Checking for existing document...';
          addLog(checkMsg);
          await processLogger.log(checkMsg);
          
          const { exists, abstractionId: existingId, error: checkError } = 
            await checkDocumentExists(mainLease, userId);
          
          if (checkError) {
            errorHandler.error(checkError);
            throw ProcessingError.database(`Failed to check document: ${checkError}`);
          }

          let currentAbstractionId: string;

          if (exists && existingId) {
            const existsMsg = 'Lease already exists, redirecting to existing abstraction...';
            addLog(existsMsg, 'info');
            await processLogger.log(existsMsg, 'info');
            return { abstractionId: existingId };
          } else {
            // Upload and process main lease
            const uploadMsg = 'Uploading main lease...';
            addLog(uploadMsg);
            await processLogger.log(uploadMsg);
            
            const { path: mainLeasePath, error: uploadError } = await uploadFile(mainLease, userId);
            if (uploadError) {
              errorHandler.error(uploadError);
              throw ProcessingError.document(`Failed to upload lease: ${uploadError}`);
            }
            const uploadSuccessMsg = 'Main lease uploaded successfully';
            addLog(uploadSuccessMsg);
            await processLogger.log(uploadSuccessMsg, 'success');

            const processMsg = 'Processing lease content...';
            addLog(processMsg);
            await processLogger.log(processMsg);
            
            const abstraction = await processMainLease(mainLease, mainLeasePath, userId, addLog, processLogger);
            currentAbstractionId = abstraction.id;
            lastAbstractionId = abstraction.id;
            
            const processSuccessMsg = 'Main lease processed successfully';
            addLog(processSuccessMsg, 'success');
            await processLogger.log(processSuccessMsg, 'success');
          }

          // Process amendments in order
          if (sortedAmendments.length > 0) {
            const amendmentMsg = `Processing ${sortedAmendments.length} amendments...`;
            addLog(amendmentMsg);
            await processLogger.log(amendmentMsg);
            
            for (let i = 0; i < sortedAmendments.length; i++) {
              const amendment = sortedAmendments[i];
              const amendmentProcessMsg = `Processing amendment ${i + 1} of ${sortedAmendments.length}: ${amendment.name}...`;
              addLog(amendmentProcessMsg);
              await processLogger.log(amendmentProcessMsg);
              
              try {
                // Upload amendment
                const uploadMsg = `Uploading amendment ${i + 1}...`;
                addLog(uploadMsg);
                await processLogger.log(uploadMsg);
                
                const { path: amendmentPath, error: uploadError } = await uploadFile(amendment, userId);
                if (uploadError) {
                  throw ProcessingError.document(`Failed to upload amendment: ${uploadError}`);
                }
                const uploadSuccessMsg = `Amendment ${i + 1} uploaded successfully`;
                addLog(uploadSuccessMsg);
                await processLogger.log(uploadSuccessMsg, 'success');

                // Process amendment
                await processAmendment(
                  amendment,
                  amendmentPath,
                  currentAbstractionId,
                  userId,
                  addLog,
                  processLogger
                );

                const successMsg = `Amendment ${i + 1} processed successfully`;
                addLog(successMsg, 'success');
                await processLogger.log(successMsg, 'success');
              } catch (amendmentError) {
                errorHandler.error(amendmentError);
                throw amendmentError;
              }
            }
          }

          successfulAbstractions++;
          const groupSuccessMsg = `Lease group ${groupIndex + 1} processed successfully`;
          addLog(groupSuccessMsg, 'success');
          await processLogger.log(groupSuccessMsg, 'success');
        } catch (error) {
          errorHandler.error(error);
          
          if (error instanceof ProcessingError) {
            throw error;
          }
          throw ProcessingError.document(
            `Failed to process lease group ${groupIndex + 1}`,
            error as Error
          );
        }
      }

      if (successfulAbstractions === 0) {
        const errorMsg = 'No abstractions were created successfully';
        await processLogger.log(errorMsg, 'error');
        throw ProcessingError.document(errorMsg);
      }

      return { abstractionId: lastAbstractionId };
    } catch (error) {
      errorHandler.error(error);
      
      const errorMsg = error instanceof ProcessingError 
        ? error.message 
        : error instanceof Error 
          ? error.message 
          : 'Unknown error';
          
      await processLogger.log(errorMsg, 'error');
      
      if (error instanceof ProcessingError) {
        return { error: error.message };
      }
      return { 
        error: ProcessingError.document(
          'File processing failed',
          error as Error
        ).message
      };
    }
  });
}
