import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create a custom storage implementation that logs operations
const customStorage = {
  ...localStorage,
  setItem: (key: string, value: string) => {
    console.log('Setting storage item:', key);
    localStorage.setItem(key, value);
  },
  getItem: (key: string) => {
    const value = localStorage.getItem(key);
    console.log('Getting storage item:', key, value ? '[exists]' : '[not found]');
    return value;
  },
  removeItem: (key: string) => {
    console.log('Removing storage item:', key);
    localStorage.removeItem(key);
  }
};

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    storage: customStorage,
    storageKey: 'sb-auth-token',
    debug: true
  }
});
