import { supabase } from './supabase';
import type { LeaseAbstraction } from '@/types/lease';

export async function logAbstraction(
  originalAbstraction: Partial<LeaseAbstraction>,
  reviewedAbstraction: Partial<LeaseAbstraction>,
  metrics: any,
  userId: string
): Promise<{ error: string | null }> {
  try {
    const logEntry = {
      lease_id: reviewedAbstraction.id || originalAbstraction.id,
      user_id: userId,
      original_abstraction: originalAbstraction,
      reviewed_abstraction: reviewedAbstraction,
      metrics,
      created_at: new Date().toISOString()
    };

    const { error } = await supabase
      .from('abstraction_logs')
      .insert(logEntry);

    if (error) throw error;

    return { error: null };
  } catch (error) {
    console.error('Failed to log abstraction:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to log abstraction'
    };
  }
}

export async function logAbstractionForTraining(
  originalAbstraction: Partial<LeaseAbstraction>,
  reviewedAbstraction: Partial<LeaseAbstraction>,
  metrics: any,
  userId: string
): Promise<{ error: string | null }> {
  try {
    const logEntry = {
      lease_id: reviewedAbstraction.id || originalAbstraction.id,
      user_id: userId,
      original_abstraction: originalAbstraction,
      reviewed_abstraction: reviewedAbstraction,
      metrics,
      created_at: new Date().toISOString(),
      training: true
    };

    const { error } = await supabase
      .from('abstraction_logs')
      .insert(logEntry);

    if (error) throw error;

    return { error: null };
  } catch (error) {
    console.error('Failed to log training abstraction:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to log training abstraction'
    };
  }
}

export async function getAbstractionLogs(leaseId: string) {
  try {
    const { data, error } = await supabase
      .from('abstraction_logs')
      .select('*')
      .eq('lease_id', leaseId)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return { data, error: null };
  } catch (error) {
    console.error('Failed to get abstraction logs:', error);
    return {
      data: null,
      error: error instanceof Error ? error.message : 'Failed to get abstraction logs'
    };
  }
}

export async function getTrainingLogs() {
  try {
    const { data, error } = await supabase
      .from('abstraction_logs')
      .select('*')
      .eq('training', true)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return { data, error: null };
  } catch (error) {
    console.error('Failed to get training logs:', error);
    return {
      data: null,
      error: error instanceof Error ? error.message : 'Failed to get training logs'
    };
  }
}