import { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { isAmendmentFile } from '../utils/file-detection';
import { processAmendment } from '../services/amendment-processing';
import type { LogEntry } from '../types';

interface ProcessingLog {
  message: string;
  type?: 'info' | 'error' | 'success';
  timestamp?: number;
}

interface AmendmentProcessingHook {
  logs: ProcessingLog[];
  error: string;
  processAmendment: (file: File) => Promise<{ existingAbstractionId?: string }>;
  clearLogs: () => void;
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void;
}

export function useAmendmentProcessing(abstractionId: string): AmendmentProcessingHook {
  const [logs, setLogs] = useState<ProcessingLog[]>([]);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const addLog = (message: string, type: 'info' | 'error' | 'success' = 'info') => {
    setLogs(prev => [...prev, { message, type, timestamp: Date.now() }]);
  };

  const clearLogs = () => {
    setLogs([]);
    setError('');
  };

  const processAmendmentFile = async (file: File) => {
    try {
      setError('');

      // Validate file is an amendment
      if (!isAmendmentFile(file.name)) {
        throw new ProcessingError('validation', [{
          field: 'file',
          message: 'File does not appear to be an amendment. Please ensure the filename contains "amendment", "amend", "modification", or "addendum".'
        }]);
      }

      if (!user?.id) {
        throw new ProcessingError('validation', [{
          field: 'user',
          message: 'User must be logged in to process amendments'
        }]);
      }

      // Process the amendment
      return await processAmendment({
        file,
        userId: user.id,
        abstractionId,
        addLog
      });
    } catch (error) {
      const errorMessage = error instanceof ProcessingError 
        ? error.details?.[0]?.message || error.message
        : error instanceof Error 
          ? error.message 
          : 'Unknown error';
      
      setError(errorMessage);
      addLog(errorMessage, 'error');
      throw error;
    }
  };

  return {
    logs,
    error,
    processAmendment: processAmendmentFile,
    clearLogs,
    addLog
  };
} 