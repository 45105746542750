import { useCallback } from 'react';
import { toast } from 'sonner';
import { saveAbstraction } from '../../lib/db';
import { getAbstractionWithVersions } from '../../lib/db/abstractions/with-versions';
import { logAbstractionForTraining } from '../../lib/abstraction-logger';
import { calculateAbstractionMetrics } from '../../lib/training/metrics';
import type { LeaseAbstraction } from '../../types/lease';
import type { LeaseAbstractionWithVersions } from '../../lib/llm/types';

interface AbstractionSaveProps {
  setAbstraction: React.Dispatch<React.SetStateAction<LeaseAbstraction | null>>;
  setVersions: (versions: LeaseAbstraction[]) => void;
  setSaving: (saving: boolean) => void;
  userId: string;
}

export function useAbstractionSave({
  setAbstraction,
  setVersions,
  setSaving,
  userId
}: AbstractionSaveProps) {
  const save = useCallback(async (data: LeaseAbstraction | LeaseAbstractionWithVersions) => {
    try {
      setSaving(true);
      // Extract only the LeaseAbstraction fields for saving
      const {
        id,
        type,
        version,
        status,
        landlord,
        tenant,
        clauses,
        additionalClauses,
        missingFields,
        confidence,
        fieldStatus,
        userId: dataUserId,
        parentId,
        isCurrentVersion,
        effectiveDate,
        documentPath,
        documentChecksum,
        documentContent,
        premises,
        county,
        tenantLegalName,
        commencementDate,
        originalCommencementDate,
        expirationDate,
        rentStartDate,
        rentableArea,
        proRata,
        buildingArea,
        baseRent,
        annualRent,
        securityDeposit,
        changes
      } = data;

      const saveData: LeaseAbstraction = {
        id,
        type,
        version,
        status,
        landlord,
        tenant,
        clauses,
        additionalClauses,
        missingFields,
        confidence,
        fieldStatus,
        createdAt: new Date(),
        updatedAt: new Date(),
        userId: dataUserId,
        parentId,
        isCurrentVersion,
        effectiveDate,
        documentPath,
        documentChecksum,
        documentContent,
        premises,
        county,
        tenantLegalName,
        commencementDate,
        originalCommencementDate,
        expirationDate,
        rentStartDate,
        rentableArea,
        proRata,
        buildingArea,
        baseRent,
        annualRent,
        securityDeposit,
        changes
      };

      const { error: saveError } = await saveAbstraction({
        ...saveData,
        userId,
        updatedAt: new Date()
      });

      if (saveError) {
        throw new Error(saveError);
      }

      // Refresh versions after save
      const { data: updatedData, error: loadError } = await getAbstractionWithVersions(data.id);
      if (loadError) {
        throw new Error(loadError);
      }

      if (updatedData) {
        setVersions(updatedData.versions || []);
      }
      
      toast.success('Abstraction saved successfully');
    } catch (error) {
      console.error('Error saving abstraction:', error);
      toast.error('Failed to save abstraction');
    } finally {
      setSaving(false);
    }
  }, [setAbstraction, setVersions, setSaving, userId]);

  const updateStatus = useCallback(async (
    abstraction: LeaseAbstraction | LeaseAbstractionWithVersions,
    originalAbstraction: Partial<LeaseAbstraction> | null,
    status: 'draft' | 'completed'
  ) => {
    try {
      setSaving(true);
      
      // If completing the abstraction, log it for training
      if (status === 'completed' && originalAbstraction) {
        // Calculate metrics
        const metrics = calculateAbstractionMetrics(
          originalAbstraction,
          abstraction,
          abstraction.missingFields
        );

        // Log for training
        await logAbstractionForTraining(
          originalAbstraction,
          abstraction,
          metrics,
          userId
        );
      }

      // Update abstraction status
      // Extract only the LeaseAbstraction fields for saving
      const {
        id,
        type,
        version,
        landlord,
        tenant,
        clauses,
        additionalClauses,
        missingFields,
        confidence,
        fieldStatus,
        userId: dataUserId,
        parentId,
        isCurrentVersion,
        effectiveDate,
        documentPath,
        documentChecksum,
        documentContent,
        premises,
        county,
        tenantLegalName,
        commencementDate,
        originalCommencementDate,
        expirationDate,
        rentStartDate,
        rentableArea,
        proRata,
        buildingArea,
        baseRent,
        annualRent,
        securityDeposit,
        changes
      } = abstraction;

      const saveData: LeaseAbstraction = {
        id,
        type,
        version,
        status,
        landlord,
        tenant,
        clauses,
        additionalClauses,
        missingFields,
        confidence,
        fieldStatus,
        createdAt: new Date(),
        updatedAt: new Date(),
        userId: dataUserId,
        parentId,
        isCurrentVersion,
        effectiveDate,
        documentPath,
        documentChecksum,
        documentContent,
        premises,
        county,
        tenantLegalName,
        commencementDate,
        originalCommencementDate,
        expirationDate,
        rentStartDate,
        rentableArea,
        proRata,
        buildingArea,
        baseRent,
        annualRent,
        securityDeposit,
        changes
      };

      const { error: saveError } = await saveAbstraction({
        ...saveData,
        status,
        userId,
        updatedAt: new Date(),
      });

      if (saveError) {
        throw new Error(saveError);
      }

      setAbstraction(prev => prev ? { ...prev, status } : null);
      toast.success('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
      throw error;
    } finally {
      setSaving(false);
    }
  }, [setAbstraction, setSaving, userId]);

  return { save, updateStatus };
}
