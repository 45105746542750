import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/button';
import { Logo } from './Logo';
import {
  ScrollText,
  Settings,
  LogOut,
  Plus,
  LayoutDashboard,
  Brain,
  Shield,
} from 'lucide-react';

export function Navigation() {
  const { user, signOut, requiresTOTP } = useAuth();

  // Don't show navigation if user isn't authenticated or needs 2FA
  if (!user || requiresTOTP) {
    return null;
  }

  return (
    <header className="border-b bg-background">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <Logo />
          
          <div className="flex items-center gap-4">
            <Link to="/upload">
              <Button className="flex items-center gap-2">
                <Plus className="h-4 w-4" />
                Create New
              </Button>
            </Link>
            
            <Link to="/">
              <Button variant="ghost" className="flex items-center gap-2">
                <LayoutDashboard className="h-4 w-4" />
                Dashboard
              </Button>
            </Link>
            
            <Link to="/abstractions">
              <Button variant="ghost" className="flex items-center gap-2">
                <ScrollText className="h-4 w-4" />
                Abstractions
              </Button>
            </Link>

            <Link to="/training">
              <Button variant="ghost" className="flex items-center gap-2">
                <Brain className="h-4 w-4" />
                Training
              </Button>
            </Link>

            {/* Temporarily available for all users */}
            <Link to="/admin">
              <Button variant="ghost" className="flex items-center gap-2">
                <Shield className="h-4 w-4" />
                Admin
              </Button>
            </Link>

            <Link to="/settings">
              <Button variant="ghost" className="flex items-center gap-2">
                <Settings className="h-4 w-4" />
                Settings
              </Button>
            </Link>

            <Button
              variant="ghost"
              onClick={() => signOut()}
              className="flex items-center gap-2"
            >
              <LogOut className="h-4 w-4" />
              Logout
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
}
