import { useCallback } from 'react';
import { toast } from 'sonner';
import { getAbstractionWithVersions } from '../../lib/db/abstractions/with-versions';
import type { LeaseAbstraction } from '../../types/lease';

interface VersionManagementProps {
  setAbstraction: (abstraction: LeaseAbstraction | null) => void;
  setCurrentVersionId: (id: string | null) => void;
  setSaving: (saving: boolean) => void;
}

export function useVersionManagement({
  setAbstraction,
  setCurrentVersionId,
  setSaving
}: VersionManagementProps) {
  const switchVersion = useCallback(async (abstractionId: string, versionId: string) => {
    if (!abstractionId || !versionId) {
      toast.error('Invalid version selection');
      return;
    }

    try {
      setSaving(true);

      // Load the abstraction data with the selected version
      // This will build the cumulative state up to this version
      const { data: updatedData, error: loadError } = await getAbstractionWithVersions(versionId);
      if (loadError) {
        throw new Error(loadError);
      }

      if (!updatedData) {
        throw new Error('Failed to load updated abstraction data');
      }

      // Update state with new data
      setAbstraction(updatedData);
      setCurrentVersionId(versionId);
      toast.success('Version switched successfully');
    } catch (error) {
      console.error('Error switching version:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to switch version');
      
      // Refresh data even on error to ensure UI is in sync
      try {
        const { data: refreshedData } = await getAbstractionWithVersions(abstractionId);
        if (refreshedData) {
          // Find the current version (either marked as current or latest)
          const currentVersion = refreshedData.versions.find(v => v.isCurrentVersion) || 
                               refreshedData.versions[refreshedData.versions.length - 1];
          
          setAbstraction(refreshedData);
          setCurrentVersionId(currentVersion?.id || refreshedData.id);
        }
      } catch (refreshError) {
        console.error('Error refreshing data:', refreshError);
      }
    } finally {
      setSaving(false);
    }
  }, [setAbstraction, setCurrentVersionId, setSaving]);

  return { switchVersion };
}
