import { supabase } from '../../supabase';
import { ProcessingError } from '../../llm/processors/errors';
import { mapToLeaseAbstraction } from './mappers';
import type { LeaseAbstraction } from '../../../types/lease';

export async function getAbstractionVersions(abstractionId: string): Promise<LeaseAbstraction[]> {
  try {
    if (!abstractionId) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'Abstraction ID is required'
      }]);
    }

    // Get current session to ensure we're authenticated
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'You must be logged in to fetch versions'
      }]);
    }

    // Get all versions including the original lease
    const { data: allVersions, error } = await supabase
      .from('lease_abstractions')
      .select('*')
      .or('id.eq.' + abstractionId + ',parent_id.eq.' + abstractionId)
      .order('effective_date', { ascending: true });

    if (error) {
      throw ProcessingError.database('Failed to fetch versions', error);
    }

    if (!allVersions?.length) {
      throw ProcessingError.database(`No versions found for abstraction ID: ${abstractionId}`);
    }

    // Verify user has access to these versions
    const hasAccess = allVersions.every(v => v.user_id === session.user.id);
    if (!hasAccess) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'You do not have access to all versions'
      }]);
    }

    // Sort versions by effective date, falling back to commencement date for the original lease
    const sortedVersions = allVersions
      .map(mapToLeaseAbstraction)
      .sort((a: LeaseAbstraction, b: LeaseAbstraction) => {
        const dateA = a.effectiveDate ? a.effectiveDate.getTime() :
                     a.commencementDate ? a.commencementDate.getTime() : 0;
        const dateB = b.effectiveDate ? b.effectiveDate.getTime() :
                     b.commencementDate ? b.commencementDate.getTime() : 0;
        return dateA - dateB;
      });

    // Ensure version numbers match the sorted order
    return sortedVersions.map((version, index) => ({
      ...version,
      version: index + 1
    }));
  } catch (error) {
    console.error('Get versions error:', error);
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.database('Failed to get versions', error as Error);
  }
}

export async function getCurrentVersion(abstractionId: string): Promise<LeaseAbstraction> {
  try {
    if (!abstractionId) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'Abstraction ID is required'
      }]);
    }

    // Get current session to ensure we're authenticated
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'You must be logged in to fetch versions'
      }]);
    }

    // Get the current version
    const { data: version, error } = await supabase
      .from('lease_abstractions')
      .select('*')
      .or('id.eq.' + abstractionId + ',parent_id.eq.' + abstractionId)
      .eq('is_current_version', true)
      .single();

    if (error) {
      throw ProcessingError.database('Failed to fetch current version', error);
    }

    if (!version) {
      throw ProcessingError.database(`No current version found for abstraction ID: ${abstractionId}`);
    }

    // Verify user has access to this version
    if (version.user_id !== session.user.id) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'You do not have access to this version'
      }]);
    }

    return mapToLeaseAbstraction(version);
  } catch (error) {
    console.error('Get current version error:', error);
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.database('Failed to get current version', error as Error);
  }
}
