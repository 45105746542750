import { supabase } from './supabase';
import { formatDateForDB } from './dates';
import type { LeaseAbstraction } from '../types/lease';
export { getAbstractionWithVersions } from './db/abstractions/with-versions';

function convertToCamelCase(obj: any): any {
  if (obj === null || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map(convertToCamelCase);

  const camelObj: any = {};
  
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      
      // Special handling for date fields
      if (['commencement_date', 'original_commencement_date', 'expiration_date', 
           'rent_start_date', 'effective_date', 'created_at', 'updated_at'].includes(key)) {
        camelObj[camelKey] = obj[key] ? new Date(obj[key]) : null;
      } else {
        camelObj[camelKey] = convertToCamelCase(obj[key]);
      }
    }
  }
  
  return camelObj;
}

function convertToSnakeCase(obj: any): any {
  if (obj === null || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map(convertToSnakeCase);
  
  const snakeObj: any = {};
  
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      snakeObj[snakeKey] = convertToSnakeCase(obj[key]);
    }
  }
  
  return snakeObj;
}

export async function loadAbstraction(id: string): Promise<LeaseAbstraction | null> {
  try {
    const { data: abstractions, error } = await supabase
      .from('lease_abstractions')
      .select('*')
      .or('id.eq.' + id + ',parent_id.eq.' + id)
      .order('version', { ascending: true });

    if (error) {
      console.error('Database query error:', error);
      throw error;
    }
    
    if (!abstractions?.length) {
      console.error('No abstractions found for ID:', id);
      return null;
    }

    // Find the original lease
    const original = abstractions.find(d => d.type === 'original');

    // Find the current version
    const currentVersion = abstractions.find(d => d.is_current_version) || original;

    if (!currentVersion) {
      console.error('No current version found for ID:', id);
      return null;
    }

    return convertToCamelCase(currentVersion);
  } catch (error) {
    console.error('Load abstraction error:', error);
    return null;
  }
}

export async function saveAbstraction(data: LeaseAbstraction) {
  try {
    const { id, ...updateData } = data;

    // Format all date fields, handling undefined values
    const formattedData = {
      ...convertToSnakeCase(updateData),
      commencement_date: formatDateForDB(data.commencementDate ?? null),
      original_commencement_date: formatDateForDB(data.originalCommencementDate ?? null),
      expiration_date: formatDateForDB(data.expirationDate ?? null),
      rent_start_date: formatDateForDB(data.rentStartDate ?? null),
      effective_date: formatDateForDB(data.effectiveDate ?? null),
      created_at: formatDateForDB(data.createdAt ?? null),
      updated_at: formatDateForDB(data.updatedAt ?? new Date())
    };

    const { error: updateError } = await supabase
      .from('lease_abstractions')
      .update(formattedData)
      .eq('id', id);

    if (updateError) throw updateError;

    return { error: null };
  } catch (error) {
    console.error('Save abstraction error:', error);
    return { 
      error: error instanceof Error ? error.message : 'Failed to save abstraction'
    };
  }
}

export async function createAbstraction(data: Partial<LeaseAbstraction>) {
  try {
    const now = new Date();
    const formattedData = {
      ...convertToSnakeCase(data),
      type: 'original',
      version: 1,
      is_current_version: true,
      commencement_date: formatDateForDB(data.commencementDate ?? null),
      original_commencement_date: formatDateForDB(data.originalCommencementDate ?? null),
      expiration_date: formatDateForDB(data.expirationDate ?? null),
      rent_start_date: formatDateForDB(data.rentStartDate ?? null),
      effective_date: formatDateForDB(data.effectiveDate ?? null),
      landlord: data.landlord || {},
      tenant: data.tenant || {},
      clauses: data.clauses || {},
      additional_clauses: data.additionalClauses || {},
      missing_fields: data.missingFields || [],
      confidence: data.confidence || {},
      status: 'draft',
      created_at: formatDateForDB(now),
      updated_at: formatDateForDB(now)
    };

    const { data: newAbstraction, error } = await supabase
      .from('lease_abstractions')
      .insert(formattedData)
      .select()
      .single();

    if (error) throw error;

    return { data: convertToCamelCase(newAbstraction), error: null };
  } catch (error) {
    console.error('Create abstraction error:', error);
    return { 
      data: null, 
      error: error instanceof Error ? error.message : 'Failed to create abstraction'
    };
  }
}
