export const LEASE_ANALYSIS_PROMPT = `You are a lease abstraction expert. Analyze the lease document and extract key information in JSON format matching exactly this structure:
{
  "premises": "string",
  "county": "string",
  "tenantLegalName": "string",
  "commencementDate": "YYYY-MM-DD",
  "expirationDate": "YYYY-MM-DD",
  "rentableArea": number,
  "proRata": number,
  "buildingArea": number,
  "baseRent": {
    "value": number,
    "confidence": number,
    "source": "string"
  },
  "annualRent": {
    "value": number,
    "confidence": number,
    "source": "string"
  },
  "securityDeposit": {
    "value": number,
    "confidence": number,
    "source": "string"
  },
  "landlord": {
    "name": "string",
    "address": "string",
    "phone": "string",
    "email": "string"
  },
  "tenant": {
    "name": "string",
    "address": "string",
    "phone": "string",
    "email": "string"
  },
  "clauses": {
    "alterations": "string",
    "assignmentSublease": "string",
    "auditRights": "string",
    "baseRentDetails": "string",
    "camOpex": "string",
    "default": "string"
  },
  "additionalClauses": {
    "estoppelSnda": "string",
    "financialReporting": "string",
    "forceMajeure": "string",
    "goDark": "string",
    "holdover": "string",
    "insurance": "string",
    "lateFee": "string",
    "legalNotices": "string",
    "parking": "string",
    "realEstateTaxes": "string",
    "relocationOption": "string",
    "renewalOption": "string",
    "repairsMaintenance": "string",
    "rightOfFirstOffer": "string",
    "rightOfFirstRefusal": "string",
    "securityDepositConditions": "string",
    "signage": "string",
    "surrender": "string",
    "terminationOption": "string",
    "tiAllowance": "string",
    "use": "string",
    "utilities": "string"
  },
  "missingFields": ["string"]
}

CRITICAL INSTRUCTIONS FOR CLAUSE EXTRACTION:

1. For each clause, provide a detailed summary of the actual terms and conditions, not just section references.
   Example:
   WRONG: "Section 38: USE OF PREMISES"
   RIGHT: "Tenant shall use the premises solely for general office and warehouse purposes. No retail sales permitted."

2. Include key details from each clause:
   - Specific rights and obligations
   - Important conditions or restrictions
   - Relevant time periods or deadlines
   - Financial terms or penalties
   - Any exceptions or special conditions

3. For financial fields (baseRent, annualRent, securityDeposit):
   - Return null if not found
   - Confidence: 1.0=explicit, 0.8=calculated, 0.5=inferred, 0.0=not found
   - Include source information

4. For all other fields:
   - Return null if not found
   - Provide complete information when found
   - Include all relevant details`;

export const AMENDMENT_ANALYSIS_PROMPT = `You are a lease amendment analysis expert. Extract only modified fields in this JSON structure, with null for unmodified fields:

{
  "premises": "string or null",
  "county": "string or null",
  "tenantLegalName": "string or null",
  "commencementDate": "YYYY-MM-DD or null",
  "expirationDate": "YYYY-MM-DD or null",
  "rentableArea": "number or null",
  "proRata": "number or null",
  "buildingArea": "number or null",
  "baseRent": {
    "value": "number or null",
    "confidence": number,
    "source": "string"
  },
  "annualRent": {
    "value": "number or null",
    "confidence": number,
    "source": "string"
  },
  "securityDeposit": {
    "value": "number or null",
    "confidence": number,
    "source": "string"
  },
  "landlord": {
    "name": "string or null",
    "address": "string or null",
    "phone": "string or null",
    "email": "string or null"
  },
  "tenant": {
    "name": "string or null",
    "address": "string or null",
    "phone": "string or null",
    "email": "string or null"
  },
  "clauses": {
    "alterations": "string or null",
    "assignmentSublease": "string or null",
    "auditRights": "string or null",
    "baseRentDetails": "string or null",
    "camOpex": "string or null",
    "default": "string or null"
  },
  "additionalClauses": {
    "estoppelSnda": "string or null",
    "financialReporting": "string or null",
    "forceMajeure": "string or null",
    "goDark": "string or null",
    "holdover": "string or null",
    "insurance": "string or null",
    "lateFee": "string or null",
    "legalNotices": "string or null",
    "parking": "string or null",
    "realEstateTaxes": "string or null",
    "relocationOption": "string or null",
    "renewalOption": "string or null",
    "repairsMaintenance": "string or null",
    "rightOfFirstOffer": "string or null",
    "rightOfFirstRefusal": "string or null",
    "securityDepositConditions": "string or null",
    "signage": "string or null",
    "surrender": "string or null",
    "terminationOption": "string or null",
    "tiAllowance": "string or null",
    "use": "string or null",
    "utilities": "string or null"
  },
  "missingFields": ["string"]
}

CRITICAL INSTRUCTIONS FOR AMENDMENTS:

1. Only include fields that are actually modified by the amendment
2. For modified clauses, provide complete summaries of the new terms
3. Include both the fact of modification and the new content
4. Use null for unmodified fields
5. Use YYYY-MM-DD for dates`;

export const BACKUP_ANALYSIS_PROMPT = `As a backup processor, be extra thorough in your analysis. ${LEASE_ANALYSIS_PROMPT}`;

export const DOCUMENT_VALIDATION_PROMPT = `Analyze this commercial lease document and return exactly this JSON structure:
{
  "badData": boolean,          // true if not a commercial lease
  "leaseOnly": boolean,        // true if single lease only
  "amendmentOnly": boolean,    // true if amendments only
  "leaseAndAmendment": boolean,// true if one lease + one amendment
  "leaseAndAmendments": boolean,// true if one lease + multiple amendments
  "documentType": "lease" | "amendment", // Identify document type
  "landlord": string,         // full legal name, including any DBAs
  "tenant": string,           // full legal name, including any DBAs
  "premises": string,         // full address and any alternate descriptions
  "effectiveDate": string,    // YYYY-MM-DD
  "reason": string,           // focus on document type and key changes
  "relationshipDetails": {
    "relationship": "amendment" | "renewal" | "extension" | null,
    "modifiedFields": string[]
  }
}

CRITICAL INSTRUCTIONS:

1. Document Type Identification:
   Main Lease indicators:
   - Contains complete lease terms (use, rent, term, etc.)
   - No references to modifying existing agreements
   - Establishes initial relationship between parties
   - Contains full set of standard lease clauses
   
   Amendment indicators:
   - References original lease or prior documents
   - Focuses on specific changes/modifications
   - Uses terms like "amend", "modify", "supplement"
   - Shorter length, focused on specific changes

2. For landlord/tenant names:
   - Include ALL variations (e.g., "ABC Corp" and "ABC Corporation")
   - Include any DBAs or trade names
   - Include parent/subsidiary relationships

3. For premises:
   - Include full street address
   - Include any alternate descriptions (e.g., "Suite C" and "Unit C")
   - Include building/complex names if mentioned

4. For reason field:
   - Focus on document type (lease/amendment/renewal)
   - Note key changes (term extension, rent changes)
   - Keep it brief and factual
   - Do not include administrative details

5. For relationshipDetails:
   - Identify type of modification if amendment
   - List specific fields being modified
   - Note any references to original lease dates`;

export const GROUP_CONTEXT_PROMPT = `Previous documents:
{{previousDocuments}}

Current document:
{{currentDocument}}

IMPORTANT MATCHING RULES:
1. Names match if:
   - They are exact matches
   - One is a DBA/trade name of the other
   - They are clearly the same entity with slight variations
   - Parent/subsidiary relationship exists

2. Premises match if:
   - Same address with different suite/unit formatting
   - Same location described differently
   - Same building/complex with different unit numbers

3. Consider context:
   - Amendments often reference the original lease date
   - Look for explicit statements about modifying/extending previous agreements
   - Check for references to the original lease`;
