import OpenAI from 'openai';
import { ProcessingError } from './errors';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';

// Configure PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js' as string;

const openai = new OpenAI({
  baseURL: import.meta.env.VITE_OPENAI_URL,
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  // Removed organization ID since OpenRouter doesn't support this header
  dangerouslyAllowBrowser: true
});

export interface ExtractedDates {
  commencementDate: string | null;
  originalCommencementDate: string | null;
  expirationDate: string | null;
  rentStartDate: string | null;
  confidence: {
    commencementDate: number;
    originalCommencementDate: number;
    expirationDate: number;
    rentStartDate: number;
  };
}

interface ImageExtractionResult {
  rentableArea?: number;
  buildingArea?: number;
  confidence: {
    rentableArea: number;
    buildingArea: number;
  };
}

// Define types for vision API
interface VisionContent {
  type: 'text' | 'image_url';
  text?: string;
  image_url?: {
    url: string;
  };
}

interface VisionMessage {
  role: 'user' | 'system' | 'assistant';
  content: VisionContent[];
}

const IMAGE_EXTRACTION_PROMPT = `
You are a specialized system for extracting numerical information from building floor plans and diagrams.
Focus on finding:
1. Rentable Area / Office Area
2. Total Building Area / Warehouse Area

Guidelines:
1. Look for explicit area measurements in square feet
2. Check for area tables or legends
3. Sum up individual room areas if necessary
4. For each measurement, provide a confidence score (0-1)
5. Pay attention to labels like "TOTAL OFFICE AREA", "RENTABLE AREA", "SQ FT", etc.
6. Consider both text and numerical measurements in the diagram
7. If areas are split (e.g., office vs warehouse), provide both

Return the results in this exact JSON format:
{
  "rentableArea": number | null,
  "buildingArea": number | null,
  "confidence": {
    "rentableArea": number,
    "buildingArea": number
  }
}`;

// TODO: Implement PDF image extraction using pdf.js
async function extractImageFromPDF(pdfBytes: Uint8Array): Promise<Blob | null> {
  try {
    // Load the PDF document
    const loadingTask = pdfjsLib.getDocument({ data: pdfBytes });
    const pdf = await loadingTask.promise;
    
    // For now, just attempt to get the first page
    // Future implementation will handle multiple pages
    if (pdf.numPages > 0) {
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.0 });
      
      // Create a canvas to render the page
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('Failed to get canvas context');
      }
      
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      
      // Render the page to canvas
      await page.render({
        canvasContext: context,
        viewport: viewport
      }).promise;
      
      // Convert canvas to blob
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      });
    }
    return null;
  } catch (error) {
    console.error('Failed to extract image from PDF:', error);
    return null;
  }
}

async function isFloorPlan(image: Blob): Promise<boolean> {
  try {
    const base64Image = await blobToBase64(image);
    const messages: VisionMessage[] = [
      {
        role: "user",
        content: [
          {
            type: "text",
            text: "Is this image a floor plan or building diagram? Answer only yes or no."
          },
          {
            type: "image_url",
            image_url: { url: base64Image }
          }
        ]
      }
    ];

    const response = await openai.chat.completions.create({
      model: "openai/gpt-4-vision-preview",
      messages: messages as any, 
      max_tokens: 10
    });

    const answer = response.choices[0]?.message?.content?.toLowerCase() || '';
    return answer.includes('yes');
  } catch (error) {
    console.error('Failed to check if image is floor plan:', error);
    return false;
  }
}

async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        // Remove data URL prefix
        const base64 = reader.result.split(',')[1];
        resolve('data:image/png;base64,' + base64);
      } else {
        reject(new Error('Failed to convert blob to base64'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function extractFromImage(
  image: Blob,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ImageExtractionResult> {
  try {
    addLog('Checking if image is a floor plan...');
    const isValidFloorPlan = await isFloorPlan(image);
    if (!isValidFloorPlan) {
      addLog('Image is not a floor plan, skipping...', 'info');
      return {
        confidence: {
          rentableArea: 0,
          buildingArea: 0
        }
      };
    }

    addLog('Processing floor plan with vision model...');
    const base64Image = await blobToBase64(image);
    const messages: VisionMessage[] = [
      {
        role: "system",
        content: [{ type: "text", text: IMAGE_EXTRACTION_PROMPT }]
      },
      {
        role: "user",
        content: [
          {
            type: "text",
            text: "Extract area measurements from this floor plan."
          },
          {
            type: "image_url",
            image_url: { url: base64Image }
          }
        ]
      }
    ];

    const response = await openai.chat.completions.create({
      model: "openai/gpt-4-vision-preview",
      messages: messages as any, // Type assertion needed until OpenAI types are updated
      response_format: { type: "json_object" },
      max_tokens: 1000
    });

    if (!response.choices[0]?.message?.content) {
      throw new Error('No response from vision model');
    }

    const result = JSON.parse(response.choices[0].message.content);

    // Log found measurements
    if (result.rentableArea) {
      addLog(`Found Rentable Area: ${result.rentableArea} sq ft (Confidence: ${result.confidence.rentableArea.toFixed(2)})`, 'success');
    }
    if (result.buildingArea) {
      addLog(`Found Building Area: ${result.buildingArea} sq ft (Confidence: ${result.confidence.buildingArea.toFixed(2)})`, 'success');
    }

    return result;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog('Error processing floor plan: ' + errorMessage, 'error');
    throw ProcessingError.llm('Failed to process floor plan', error as Error);
  }
}

export async function processDocumentImages(
  file: File,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ImageExtractionResult | null> {
  try {
    // Convert File to ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();
    const pdfBytes = new Uint8Array(arrayBuffer);

    // Extract images from PDF
    // For now, just try first page as proof of concept
    const image = await extractImageFromPDF(pdfBytes);
    if (!image) {
      addLog('No floor plans found in document', 'info');
      return null;
    }

    return await extractFromImage(image, addLog);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog('Error processing document images: ' + errorMessage, 'error');
    return null;
  }
}
