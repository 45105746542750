import { ProcessingError } from '../llm/processors/errors';

/**
 * Wraps a promise with a timeout
 * @param promise The promise to wrap with a timeout
 * @param ms The timeout in milliseconds
 * @param message The error message to use if the timeout occurs
 * @returns The result of the promise if it completes before the timeout
 * @throws ProcessingError if the timeout occurs
 */
export async function withTimeout<T>(
  promise: Promise<T>,
  ms: number,
  message: string
): Promise<T> {
  let timeoutId: NodeJS.Timeout;
  
  const timeoutPromise = new Promise<never>((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(ProcessingError.retryable(message || 'Operation timed out'));
    }, ms);
  });

  try {
    const result = await Promise.race([promise, timeoutPromise]);
    clearTimeout(timeoutId!);
    return result;
  } catch (error) {
    clearTimeout(timeoutId!);
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.retryable(
      message || 'Operation timed out',
      error as Error
    );
  }
}

/**
 * Creates a promise that resolves after a specified delay
 * @param ms The delay in milliseconds
 * @returns A promise that resolves after the specified delay
 */
export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
