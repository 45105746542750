import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Loader2 } from 'lucide-react';

export default function UserDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const { data: profile, error: profileError } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('id', id)
          .single();

        if (profileError) throw profileError;
        setUser(profile);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Failed to fetch user details');
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchUserDetails();
    }
  }, [id]);

  const handleResetTOTP = async () => {
    try {
      const { error } = await supabase.rpc('reset_user_totp', { user_id: id });
      if (error) throw error;
      // Refresh user details
      window.location.reload();
    } catch (error) {
      console.error('Error resetting TOTP:', error);
      setError('Failed to reset TOTP');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!user) {
    return (
      <Alert variant="destructive">
        <AlertDescription>User not found</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader>
          <h2 className="text-2xl font-bold">User Details</h2>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div>
              <label className="font-medium">Email:</label>
              <p>{user.email}</p>
            </div>
            <div>
              <label className="font-medium">Role:</label>
              <p>{user.role}</p>
            </div>
            <div>
              <label className="font-medium">Created At:</label>
              <p>{new Date(user.created_at).toLocaleString()}</p>
            </div>
            <div className="space-x-4">
              <Button onClick={handleResetTOTP}>Reset TOTP</Button>
              <Button variant="outline" onClick={() => navigate('/admin')}>
                Back to Users
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
