import { supabase } from '../../supabase';
import { calculateFileChecksum } from '../../utils/checksum';
import { ProcessingError } from '../../llm/processors/errors';
import type { DocumentTrackingTable, NewDocumentTracking } from '../../../types/database';

interface DocumentCheckResult {
  exists: boolean;
  abstractionId?: string;
  documentId?: string;
  error?: string;
}

interface DocumentTrackResult {
  documentId: string;
  error?: string;
}

interface DocumentTrackingRecord {
  abstraction_id: string;
  document_id: string;
}

interface GetDocumentAbstractionResult {
  abstractionId?: string;
  error?: string;
}

export async function checkDocumentExists(file: File, userId: string): Promise<DocumentCheckResult> {
  try {
    if (!file) {
      throw ProcessingError.validation([{
        field: 'file',
        message: 'File is required'
      }]);
    }

    if (!userId) {
      throw ProcessingError.validation([{
        field: 'userId',
        message: 'User ID is required'
      }]);
    }

    // Verify user ID matches authenticated user
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'No active session'
      }]);
    }

    if (session.user.id !== userId) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'User ID mismatch'
      }]);
    }

    let checksum;
    try {
      checksum = await calculateFileChecksum(file);
      if (!checksum) {
        throw new Error('Checksum calculation returned empty result');
      }
    } catch (checksumError) {
      console.error('Checksum calculation error:', checksumError);
      throw ProcessingError.document(
        'Failed to calculate document checksum',
        checksumError as Error
      );
    }

    const { data, error: dbError } = await supabase
      .from('document_tracking')
      .select('abstraction_id, document_id')
      .eq('user_id', userId)
      .eq('checksum', checksum)
      .maybeSingle();

    if (dbError) {
      throw ProcessingError.database('Failed to check document existence', dbError);
    }

    const record = data as DocumentTrackingRecord | null;

    return {
      exists: !!record,
      abstractionId: record?.abstraction_id,
      documentId: record?.document_id
    };
  } catch (error) {
    console.error('Check document exists error:', error);
    if (error instanceof ProcessingError) {
      return {
        exists: false,
        error: error.message
      };
    }
    return {
      exists: false,
      error: ProcessingError.database(
        'Failed to check document',
        error as Error
      ).message
    };
  }
}

export async function trackDocument(
  file: File,
  userId: string,
  abstractionId?: string
): Promise<DocumentTrackResult> {
  try {
    if (!file) {
      throw ProcessingError.validation([{
        field: 'file',
        message: 'File is required'
      }]);
    }

    if (!userId) {
      throw ProcessingError.validation([{
        field: 'userId',
        message: 'User ID is required'
      }]);
    }

    // Verify user ID matches authenticated user
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'No active session'
      }]);
    }

    if (session.user.id !== userId) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'User ID mismatch'
      }]);
    }

    let checksum;
    try {
      checksum = await calculateFileChecksum(file);
      if (!checksum) {
        throw new Error('Checksum calculation returned empty result');
      }
    } catch (checksumError) {
      console.error('Checksum calculation error:', checksumError);
      throw ProcessingError.document(
        'Failed to calculate document checksum',
        checksumError as Error
      );
    }

    // Generate document ID
    const documentId = await calculateFileChecksum(file);

    // Create tracking record with upsert
    const trackingData: NewDocumentTracking = {
      user_id: userId,
      document_id: documentId,
      checksum,
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
      abstraction_id: abstractionId || null
    };

    const { error: insertError } = await supabase
      .from('document_tracking')
      .upsert([trackingData], {
        onConflict: 'user_id,checksum',
        ignoreDuplicates: false
      });

    if (insertError) {
      throw ProcessingError.database('Failed to track document', insertError);
    }

    return { documentId };
  } catch (error) {
    console.error('Track document error:', error);
    if (error instanceof ProcessingError) {
      return { 
        documentId: '',
        error: error.message 
      };
    }
    return {
      documentId: '',
      error: ProcessingError.database(
        'Failed to track document',
        error as Error
      ).message
    };
  }
}

export async function getDocumentAbstraction(documentId: string): Promise<GetDocumentAbstractionResult> {
  try {
    if (!documentId) {
      throw ProcessingError.validation([{
        field: 'documentId',
        message: 'Document ID is required'
      }]);
    }

    const { data, error: dbError } = await supabase
      .from('document_tracking')
      .select('abstraction_id')
      .eq('document_id', documentId)
      .maybeSingle();

    if (dbError) {
      throw ProcessingError.database('Failed to get document abstraction', dbError);
    }

    return {
      abstractionId: data?.abstraction_id
    };
  } catch (error) {
    console.error('Get document abstraction error:', error);
    if (error instanceof ProcessingError) {
      return {
        error: error.message
      };
    }
    return {
      error: ProcessingError.database(
        'Failed to get document abstraction',
        error as Error
      ).message
    };
  }
}
