import { ProcessingError } from '../llm/processors/errors';

/**
 * Checks if Web Crypto API is available
 */
function isWebCryptoAvailable(): boolean {
  return typeof window !== 'undefined' && 
         window.crypto && 
         window.crypto.subtle && 
         typeof window.crypto.subtle.digest === 'function';
}

/**
 * Fallback checksum calculation using a simple hash function
 * Note: This is less secure than SHA-256 but works as a fallback
 */
function calculateSimpleHash(buffer: ArrayBuffer): string {
  const array = new Uint8Array(buffer);
  let hash = 0;
  
  for (let i = 0; i < array.length; i++) {
    const byte = array[i];
    hash = ((hash << 5) - hash) + byte;
    hash = hash & hash; // Convert to 32-bit integer
  }
  
  return Math.abs(hash).toString(16).padStart(8, '0');
}

/**
 * Calculates a checksum for a file using SHA-256 or fallback method
 * @param file File to calculate checksum for
 * @returns Promise resolving to checksum string
 * @throws ProcessingError if file reading or checksum calculation fails
 */
export async function calculateFileChecksum(file: File): Promise<string> {
  try {
    if (!file) {
      throw ProcessingError.validation([{
        field: 'file',
        message: 'File is required for checksum calculation'
      }]);
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = async (event) => {
        try {
          if (!event.target?.result) {
            throw ProcessingError.document('Failed to read file content');
          }

          const buffer = event.target.result as ArrayBuffer;
          
          if (isWebCryptoAvailable()) {
            try {
              const hashBuffer = await window.crypto.subtle.digest('SHA-256', buffer);
              const hashArray = Array.from(new Uint8Array(hashBuffer));
              const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
              resolve(hashHex);
            } catch (cryptoError) {
              console.warn('Web Crypto API failed, using fallback:', cryptoError);
              resolve(calculateSimpleHash(buffer));
            }
          } else {
            console.warn('Web Crypto API not available, using fallback');
            resolve(calculateSimpleHash(buffer));
          }
        } catch (error) {
          reject(
            ProcessingError.document(
              'Failed to calculate checksum',
              error as Error
            )
          );
        }
      };

      reader.onerror = () => {
        reject(
          ProcessingError.document(
            'Failed to read file',
            reader.error || new Error('Unknown file reading error')
          )
        );
      };

      reader.readAsArrayBuffer(file);
    });
  } catch (error) {
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.document(
      'Failed to process file for checksum',
      error as Error
    );
  }
}

/**
 * Generates a unique document ID based on checksum and user ID
 * @param checksum File checksum
 * @param userId User ID
 * @returns Promise resolving to document ID string
 * @throws ProcessingError if inputs are invalid or ID generation fails
 */
export async function generateDocumentId(checksum: string, userId: string): Promise<string> {
  try {
    // Validate inputs
    if (!checksum) {
      throw ProcessingError.validation([{
        field: 'checksum',
        message: 'Checksum is required'
      }]);
    }
    if (!userId) {
      throw ProcessingError.validation([{
        field: 'userId',
        message: 'User ID is required'
      }]);
    }

    // Generate unique ID by combining checksum and user ID
    const combinedString = `${checksum}-${userId}`;
    
    if (isWebCryptoAvailable()) {
      try {
        const encoder = new TextEncoder();
        const data = encoder.encode(combinedString);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex.substring(0, 32);
      } catch (cryptoError) {
        console.warn('Web Crypto API failed for ID generation, using fallback:', cryptoError);
        return calculateSimpleHash(new TextEncoder().encode(combinedString));
      }
    } else {
      console.warn('Web Crypto API not available for ID generation, using fallback');
      return calculateSimpleHash(new TextEncoder().encode(combinedString));
    }
  } catch (error) {
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.document(
      'Failed to generate document ID',
      error as Error
    );
  }
}
