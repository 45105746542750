import { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Loader2 } from 'lucide-react';
import { useRandomBackground } from '../hooks/useRandomBackground';
import { Logo } from '../components/Logo';
import { Button } from '../components/ui/button';

export default function Login() {
  const { user, signInWithAzure, loading: authLoading, requiresTOTP, initialized } = useAuth();
  const background = useRandomBackground();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Handle auth flow
  const initiateAuth = useCallback(async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      setError(null);
      console.log('Initiating Azure sign-in...');
      await signInWithAzure();
      // The browser will be redirected to Azure AD
    } catch (err) {
      console.error('Failed to initiate Azure login:', err);
      setError('Failed to start authentication. Please try again.');
      setIsProcessing(false);
    }
  }, [isProcessing, signInWithAzure]);

  // Wait for auth to initialize before showing anything
  if (!initialized && !error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  // Redirect if already logged in and TOTP is not required
  if (user && !requiresTOTP) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen w-full flex">
      {/* Form Side */}
      <div className="w-1/2 flex flex-col items-center justify-center p-8 bg-background">
        <div className="w-full max-w-md space-y-8">
          <div className="flex justify-center">
            <Logo />
          </div>
          
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl text-center">Sign in with Mohr ID</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center justify-center space-y-4">
              {error && (
                <Alert variant="destructive" className="mb-4">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              
              {(isProcessing || authLoading) ? (
                <>
                  <Loader2 className="h-8 w-8 animate-spin text-primary" />
                  <p className="text-center text-muted-foreground">
                    Authenticating...
                  </p>
                </>
              ) : (
                <>
                  <Button 
                    onClick={initiateAuth} 
                    className="w-full"
                    disabled={isProcessing || authLoading}
                  >
                    Sign in
                  </Button>
                  <p className="text-sm text-center text-muted-foreground">
                    You'll be redirected to sign in with your organization's credentials
                  </p>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Image Side */}
      <div 
        className="w-1/2 bg-cover bg-center relative"
        style={{ 
          backgroundImage: `url(${background.url})`,
          backgroundPosition: background.position 
        }}
      >
        <div className="absolute bottom-4 right-4 text-white text-sm opacity-75">
          {background.credit}
        </div>
      </div>
    </div>
  );
}
