import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { FormField } from '../ui/FormField';
import { toTitleCase } from '../../lib/utils';
import type { FieldStatus } from '../../types/lease';

interface FinancialSummaryProps {
  baseRent?: number | null;
  annualRent?: number | null;
  securityDeposit?: number | null;
  fieldStatus?: Record<string, FieldStatus>;
  onChange: (field: string, value: number | null) => void;
  onFieldStatusChange: (field: string, status: FieldStatus) => void;
}

export function FinancialSummary({
  baseRent,
  annualRent,
  securityDeposit,
  fieldStatus = {},
  onChange,
  onFieldStatusChange,
}: FinancialSummaryProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Financial Summary</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {[
          { field: 'baseRent', label: 'Base Rent', value: baseRent },
          { field: 'annualRent', label: 'Annual Rent', value: annualRent },
          { field: 'securityDeposit', label: 'Security Deposit', value: securityDeposit }
        ].map(({ field, label, value }) => (
          <FormField
            key={field}
            id={field}
            label={toTitleCase(label)}
            value={value?.toString() || ''}
            onChange={(newValue) => onChange(field, newValue ? Number(newValue) : null)}
            type="number"
            prefix="$"
            placeholder="0.00"
            fieldStatus={fieldStatus[field] || 'not_missing'}
            onFieldStatusChange={(status) => onFieldStatusChange(field, status)}
          />
        ))}
      </CardContent>
    </Card>
  );
}
