import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { ScrollArea } from '../ui/scroll-area';
import { Badge } from '../ui/badge';

interface ProcessLog {
  id: string;
  message: string;
  type: 'info' | 'error' | 'success';
  created_at: string;
  document_name?: string;
  document_type?: 'lease' | 'amendment';
}

interface ProcessLogsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  logs: ProcessLog[];
  title?: string;
}

export function ProcessLogsModal({
  open,
  onOpenChange,
  logs,
  title = 'Process Logs'
}: ProcessLogsModalProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[60vh] w-full pr-4">
          <div className="space-y-4">
            {logs.map((log) => (
              <div
                key={log.id}
                className="border rounded-lg p-3 space-y-2"
              >
                <div className="flex items-center justify-between">
                  <Badge
                    variant={
                      log.type === 'error'
                        ? 'destructive'
                        : log.type === 'success'
                        ? 'default'
                        : 'secondary'
                    }
                  >
                    {log.type}
                  </Badge>
                  <span className="text-sm text-muted-foreground">
                    {new Date(log.created_at).toLocaleString()}
                  </span>
                </div>
                {log.document_name && (
                  <div className="text-sm text-muted-foreground">
                    Document: {log.document_name}
                    {log.document_type && ` (${log.document_type})`}
                  </div>
                )}
                <div className="text-sm whitespace-pre-wrap">{log.message}</div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
