import { useState, useEffect } from 'react';

const backgrounds = [
  {
    url: '/images/auth/building-1.jpg',
    credit: 'Photo by Scott Webb on Unsplash',
    position: 'center'
  },
  {
    url: '/images/auth/building-2.jpg',
    credit: 'Photo by Jaanus Jagomägi on Unsplash',
    position: 'center'
  },
  {
    url: '/images/auth/building-3.jpg',
    credit: 'Photo by Nastuh Abootalebi on Unsplash',
    position: 'center'
  },
  {
    url: '/images/auth/building-4.jpg',
    credit: 'Photo by Nastuh Abootalebi on Unsplash',
    position: 'center'
  }
];

export function useRandomBackground() {
  const [background, setBackground] = useState(backgrounds[0]);

  useEffect(() => {
    // Get a random background but ensure it's different from the current one
    const currentIndex = backgrounds.indexOf(background);
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * backgrounds.length);
    } while (randomIndex === currentIndex && backgrounds.length > 1);
    
    setBackground(backgrounds[randomIndex]);
  }, []);

  return background;
}
