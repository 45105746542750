import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { useState } from 'react';
import { toast } from 'sonner';

interface StatusToggleProps {
  status: 'draft' | 'completed';
  onChange: (status: 'draft' | 'completed') => Promise<void>;
}

export function StatusToggle({ status, onChange }: StatusToggleProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = async (checked: boolean) => {
    try {
      setIsLoading(true);
      const newStatus = checked ? 'completed' : 'draft';
      await onChange(newStatus);
      toast.success(`Status updated to ${newStatus}`);
    } catch (error) {
      toast.error('Failed to update status');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Switch
          id="status-toggle"
          checked={status === 'completed'}
          onCheckedChange={handleStatusChange}
          disabled={isLoading}
        />
        <Label htmlFor="status-toggle" className="text-sm font-medium">
          {status === 'completed' ? 'Accepted' : 'Draft'}
        </Label>
      </div>
    </div>
  );
}