import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Progress } from '@/components/ui/progress';
import { getModelVersions } from '@/lib/training/model-versions';
import { startFineTuning } from '@/lib/training/fine-tune';
import { exportTrainingData } from '@/lib/training/export';
import type { ModelVersion } from '@/lib/training/types';
import { toast } from 'sonner';
import { Activity, Download, Star } from 'lucide-react';
import { supabase } from '@/lib/supabase';

export function ModelVersions() {
  const [versions, setVersions] = useState<ModelVersion[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVersions();
  }, []);

  async function fetchVersions() {
    try {
      const { data, error } = await getModelVersions();
      if (error) throw error;
      if (data) setVersions(data);
    } catch (error) {
      console.error('Failed to fetch model versions:', error);
      toast.error('Failed to load model versions');
    } finally {
      setLoading(false);
    }
  }

  async function handleStartFineTuning() {
    try {
      const { data, error } = await startFineTuning();
      if (error) throw error;
      
      toast.success(
        `Fine-tuning started with ${data.trainingCount} training and ${data.validationCount} validation documents`
      );
      
      fetchVersions();
    } catch (error) {
      console.error('Fine-tuning error:', error);
      toast.error('Failed to start fine-tuning');
    }
  }

  async function handleExportTrainingData() {
    try {
      // Get all reviewed abstractions
      const { data: abstractions, error } = await supabase
        .from('training_documents')
        .select('reviewed_abstraction')
        .eq('status', 'reviewed')
        .order('created_at', { ascending: false });

      if (error) throw error;
      if (!abstractions?.length) {
        toast.error('No reviewed abstractions found');
        return;
      }

      // Export the data
      const result = exportTrainingData(abstractions.map(a => a.reviewed_abstraction));
      
      toast.success(
        `Exported ${result.trainingCount} training and ${result.validationCount} validation examples`
      );
    } catch (error) {
      console.error('Export error:', error);
      toast.error('Failed to export training data');
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Model Versions</CardTitle>
        <div className="flex gap-2">
          <Button onClick={handleStartFineTuning}>
            <Activity className="mr-2 h-4 w-4" />
            Start Fine-tuning
          </Button>
          <Button variant="outline" onClick={handleExportTrainingData}>
            <Download className="mr-2 h-4 w-4" />
            Export Training Data
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {versions.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-8 text-center text-muted-foreground">
            <Star className="h-12 w-12 mb-2" />
            <p>No model versions yet</p>
            <p className="text-sm">Start fine-tuning to create your first model version</p>
          </div>
        ) : (
          <div className="space-y-4">
            {versions.map((version) => (
              <Card key={version.id}>
                <CardContent className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="font-medium">Version {version.version}</h3>
                      <p className="text-sm text-muted-foreground">
                        Created {new Date(version.created_at).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <div>
                        <Badge
                          variant={version.status === 'active' ? 'default' : 'secondary'}
                        >
                          {version.status}
                        </Badge>
                      </div>
                      <div className="text-right">
                        <div className="flex items-center gap-2">
                          <span className="text-sm font-medium">
                            {(version.metrics.accuracy * 100).toFixed(1)}%
                          </span>
                          <Progress
                            value={version.metrics.accuracy * 100}
                            className="w-24"
                          />
                        </div>
                        <p className="text-xs text-muted-foreground">
                          {version.training_data_count} training examples
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
}