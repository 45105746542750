import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Button } from '../ui/button';
import { FileUploadZone } from '../upload/FileUploadZone';
import { UploadTerminal } from '../upload/UploadTerminal';
import { Switch } from '../ui/switch';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import { ScrollArea } from '../ui/scroll-area';
import { AlertCircle, FileText } from 'lucide-react';
import { useAmendmentProcessing } from '../upload/hooks/useAmendmentProcessing';
import { ProcessingError } from '../../lib/llm/processors/errors';
import type { LeaseAbstraction } from '../../types/lease';

interface AmendmentUploadProps {
  abstraction: LeaseAbstraction;
  onUploadComplete?: () => void;
}

export function AmendmentUpload({ abstraction, onUploadComplete }: AmendmentUploadProps) {
  const [open, setOpen] = useState(false);
  const [showLogs, setShowLogs] = useState(true);
  const {
    logs: processingLogs,
    error: processingError,
    processAmendment,
    clearLogs,
    addLog
  } = useAmendmentProcessing(abstraction.id);

  const handleFileSelect = async (files: File[]) => {
    try {
      const file = files[0];
      if (!file) return;

      // Check if file is an amendment before processing
      if (!file.name.toLowerCase().includes('amend') && 
          !file.name.toLowerCase().includes('modification') && 
          !file.name.toLowerCase().includes('addendum')) {
        const message = 'Please upload an amendment file. The file name should contain "amendment", "amend", "modification", or "addendum".';
        addLog(message, 'error');
        return;
      }

      const { existingAbstractionId } = await processAmendment(file);
      
      if (existingAbstractionId) {
        // If it exists in another abstraction, wait 3 seconds before redirecting
        if (existingAbstractionId !== abstraction.id) {
          setTimeout(() => {
            window.location.href = `/abstractions/${existingAbstractionId}`;
          }, 3000);
          return;
        }
        // If it exists in this abstraction, keep dialog open to show message
        return;
      }
      
      // Only close dialog if we processed a new amendment
      setOpen(false);
      clearLogs();
      onUploadComplete?.();
    } catch (error) {
      if (error instanceof ProcessingError) {
        const message = error.details?.[0]?.message || error.message;
        addLog(message, 'error');
      } else {
        addLog(error instanceof Error ? error.message : 'Unknown error', 'error');
      }
      console.error('Amendment upload error:', error);
    }
  };

  // Transform logs to include required timestamp
  const logs = processingLogs.map(log => ({
    ...log,
    timestamp: new Date(log.timestamp || Date.now()),
    type: log.type || 'info'
  }));

  // Clear logs when dialog is opened
  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      clearLogs();
    }
    setOpen(isOpen);
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <FileText className="mr-2 h-4 w-4" />
          Add Amendment
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Upload Amendment</DialogTitle>
          <DialogDescription>
            Upload an amendment document for this lease. The file name should contain "amendment", "amend", "modification", or "addendum".
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="max-h-[calc(100vh-200px)] px-1">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Switch
                  id="show-logs"
                  checked={showLogs}
                  onCheckedChange={setShowLogs}
                />
                <Label htmlFor="show-logs">Show Process Log</Label>
              </div>
            </div>

            {processingError && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{processingError}</AlertDescription>
              </Alert>
            )}

            <FileUploadZone 
              onFileSelect={handleFileSelect}
              multiple={false}
              accept={{
                'application/pdf': ['.pdf'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
                'image/tiff': ['.tif', '.tiff']
              }}
              className="h-[200px]"
            />
            
            {showLogs && logs.length > 0 && (
              <div className="mt-4">
                <UploadTerminal logs={logs} />
              </div>
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
